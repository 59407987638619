import { TableCell, TableRow } from "@mui/material"
import type { ReactElement, ReactNode } from "react"
import { twMerge } from "tailwind-merge"

interface AerosDataNotFoundRowProps {
  children: ReactNode
  colSpan?: number
  className?: string
}

export const AerosDataNotFoundRow = ({ children, colSpan, className }: AerosDataNotFoundRowProps): ReactElement => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align='center' className={twMerge("py-8", className)}>
        {children}
      </TableCell>
    </TableRow>
  )
}
