import { Box, Typography } from "@mui/material"
import type { Row } from "@tanstack/react-table"
import type { ReactElement } from "react"

import { KILOGRAMS_TO_POUNDS, POUNDS_TO_KILOGRAMS, POUND_UOM_ID } from "@/constants/unitOfMeasurements.ts"
import type { OrderLine } from "@/graphql/codegen/graphql"
import { formatNumber } from "@/utils/formatNumber.ts"

export const ProductOrderPriceUomColumn = ({ row }: { row: Row<OrderLine> }): ReactElement => {
  const { uomId, pricePerUom, quantityInUom } = row.original
  const isPound = uomId === POUND_UOM_ID
  const selectedUom = isPound ? "lb" : "kg"
  const convertedUom = isPound ? "kg" : "lb"
  const conversionFactor = isPound ? KILOGRAMS_TO_POUNDS : POUNDS_TO_KILOGRAMS
  const currentPricing = pricePerUom ? `$${formatNumber(pricePerUom)} / ${selectedUom}` : "Not calculated"
  const convertedPrice = pricePerUom ? `$${formatNumber(pricePerUom * conversionFactor)} / ${convertedUom}` : ""
  const convertedQuantity = formatNumber(quantityInUom * conversionFactor)

  return (
    <Box>
      <Box className='flex gap-2'>
        <Typography className='font-bold'>{currentPricing}</Typography>
        {convertedPrice && (
          <Typography variant='caption' className='pt-1'>
            {convertedPrice}
          </Typography>
        )}
      </Box>
      <Box className='flex gap-2'>
        <Typography className='font-bold'>{`${quantityInUom} ${selectedUom}`}</Typography>
        <Typography>{`(${convertedQuantity} ${convertedUom})`}</Typography>
      </Box>
    </Box>
  )
}
