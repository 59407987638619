import { Box, Collapse, IconButton, Paper, Typography } from "@mui/material"
import {
  AddOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  WarningOutlined,
} from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { useCallback, useMemo, useState } from "react"

import PageComments from "@/components/Comment/PageComments"
import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import type { CommentListSuccess } from "@/graphql/codegen/graphql"
import { CommentParentTableType } from "@/graphql/codegen/graphql"
import { useGetComments } from "@/screens/Companies/hooks/useGetComments"
import AddContactComment from "@/screens/Contacts/edit/components/AddContactComment"
import { useGetProduct } from "@/screens/Products/hooks/useGetProduct"

export const ProductCommentsContainer = (): ReactElement => {
  const [expanded, setExpanded] = useState(true)
  const { productId: queryProductId } = useParams({
    from: "/product/$productId/edit",
  })
  // FIXME: we must not fetch all product details here
  const { productDetails } = useGetProduct(Number(queryProductId))
  const { productManager } = productDetails ?? {}
  const { commentDetails, updateComment, reexecuteCommentList } = useGetComments(
    queryProductId,
    CommentParentTableType.Product
  )
  const commentList = (commentDetails?.comment?.list as CommentListSuccess)?.comments || []
  const [showAddComment, setShowAddComment] = useState(false)

  const productManagerName = useMemo(
    () =>
      productManager?.firstName && productManager?.lastName
        ? `${productManager.firstName} ${productManager.lastName}`
        : undefined,
    [productManager]
  )

  const onUpdateComment = useCallback(
    async (commentId: string, value: string) => {
      await updateComment({
        input: {
          commentId,
          value,
        },
      })
      reexecuteCommentList()
    },
    [reexecuteCommentList, updateComment]
  )

  return (
    <Paper className='border border-gray-300 p-6' elevation={0}>
      <Box className='flex items-center justify-between'>
        <Typography variant='h6' className={expanded ? "mb-6" : ""}>
          Comments
        </Typography>
        <IconButton onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label='show more'>
          {expanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        {commentList.length === 0 ? (
          <InformationMissingAlert
            icon={<WarningOutlined color='primary' />}
            title='Add Comments'
            action={
              <Button
                color='inherit'
                size='small'
                appearance='outlined'
                className='text-primary-500'
                startIcon={<AddOutlined />}
                onClick={() => setShowAddComment(true)}
              >
                Comments
              </Button>
            }
            description={""}
          />
        ) : (
          <PageComments comments={commentList} onAddComment={setShowAddComment} onUpdateComment={onUpdateComment} />
        )}
      </Collapse>
      <AddContactComment
        open={showAddComment}
        name={productManagerName}
        contactId={queryProductId}
        type={CommentParentTableType.Product}
        onClose={() => {
          setShowAddComment(false)
        }}
        onSubmit={() => {
          setShowAddComment(false)
          reexecuteCommentList()
        }}
      />
    </Paper>
  )
}
