import type { TableContainerProps } from "@mui/material"
import { Paper, TableContainer } from "@mui/material"
import { forwardRef } from "react"
import { twMerge } from "tailwind-merge"

interface AerosTableContainerProps extends TableContainerProps {
  maxHeight?: string | number
  maxWidth?: string | number
}

/**
 * Outer container component that provides the card-like appearance with borders and rounded corners.
 * Handles scrolling behavior for table content.
 *
 * @example
 * ```tsx
 * <AerosTableContainer>
 *   <AerosTable>
 *     // ... table content
 *   </AerosTable>
 * </AerosTableContainer>
 * ```
 *
 * @param props - Extends MUI TableContainerProps
 * @param props.className - Additional Tailwind classes for container styling
 */
export const AerosTableContainer = forwardRef<HTMLDivElement, AerosTableContainerProps>(
  ({ className, maxHeight = "calc(100vh - 200px)", maxWidth = "100%", ...props }, ref): JSX.Element => {
    return (
      <TableContainer
        component={Paper}
        className={twMerge([
          "flex flex-col overflow-auto rounded-md border border-gray-300 shadow-none",
          "relative",
          maxHeight && `max-h-[${maxHeight}]`,
          maxWidth && `max-w-[${maxWidth}]`,
          className,
        ])}
        ref={ref}
        elevation={0}
        {...props}
      />
    )
  }
)

AerosTableContainer.displayName = "AerosTableContainer"
