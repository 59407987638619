import { redirect } from "@tanstack/react-router"
import { create } from "zustand"

import type {
  Product,
  ProductUpdateFailure,
  ProductUpdateMutation,
  ProductUpdateMutationVariables,
  ProductUpdateSuccess,
} from "@/graphql/codegen/graphql"
import { ProductStatusReason, ProductUpdateDocument } from "@/graphql/codegen/graphql"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import type { ProductActionsStore } from "@/screens/Companies/products/types/schema"
import { ProductActions } from "@/screens/Companies/products/types/schema"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

const _updateProduct = async (product: Product) => {
  const enqueueNotification = useNotificationsStore.getState().enqueueNotification
  const { data, error } = await queryClient.query<ProductUpdateMutation, ProductUpdateMutationVariables>(
    ProductUpdateDocument,
    {
      input: {
        productId: product.productId,
        vendorSku: product.vendorSku,
        vendorDescription: product.vendorDescription,
        companyProductDescription: product.companyProductDescription,
        productManagerId: product.productManagerId,
        // shipFromLocationId: product.shipFromLocationId, // TODO: Add this; BE needs to add it to the mutation
        classificationId: product.classificationId,
        productFormInfo: product.productFormInfo,
        uomId: product.uomId,
      },
    }
  )

  if (error || data?.product.update.__typename === "ProductUpdateFailure") {
    enqueueNotification({
      type: "error",
      message: error?.message || (data?.product.update as ProductUpdateFailure).error.message,
      silent: false,
    })
  }

  if (data) {
    return data.product.update as ProductUpdateSuccess
  }
}

const deactivateProduct = async (product: Product) => {
  const enqueueNotification = useNotificationsStore.getState().enqueueNotification
  const { data, error } = await queryClient.query<ProductUpdateMutation, ProductUpdateMutationVariables>(
    ProductUpdateDocument,
    {
      input: {
        productId: product.productId,
        activityStatusReason: ProductStatusReason.Discontinued,
      },
    }
  )

  if (error || data?.product.update.__typename === "ProductUpdateFailure") {
    enqueueNotification({
      type: "error",
      message: error?.message || (data?.product.update as ProductUpdateFailure).error.message,
    })
  }

  if (data) {
    enqueueNotification({
      type: "success",
      message: "Product deactivated successfully",
    })
  }
}

const activateProduct = async (product: Product) => {
  const enqueueNotification = useNotificationsStore.getState().enqueueNotification
  const { data, error } = await queryClient.mutation<ProductUpdateMutation, ProductUpdateMutationVariables>(
    ProductUpdateDocument,
    {
      input: {
        productId: product.productId,
        isActive: true,
      },
    }
  )

  if (error || data?.product.update.__typename === "ProductUpdateFailure") {
    enqueueNotification({
      type: "error",
      message: error?.message || (data?.product.update as ProductUpdateFailure).error.message,
    })
  }

  if (data) {
    enqueueNotification({
      type: "success",
      message: "Product activated successfully",
    })
  }
}

export const useProductActionsStore = create<ProductActionsStore>((set, get) => ({
  productAtAction: null,
  openedDialog: null,
  setOpenedDialog: (dialog) => set({ openedDialog: dialog }),
  setProductAtAction: (product) => set({ productAtAction: product }),
  loadProductPricing: async (_productId) => {
    // const { data, error } = await queryClient.query<ProductPricingQuery, ProductPricingQueryVariables>(ProductPricingDocument, {
    //   productId,
    // })
  },
  onAction: (action) => {
    const product = get().productAtAction
    if (!product) {
      console.error("No product selected for action")
      return
    }

    if (action === ProductActions.EDIT_PRODUCT_DETAILS) {
      redirect({ to: `/companies/products/${product.productId}/edit` })
    }

    if (action === ProductActions.DEACTIVATE_PRODUCT) {
      deactivateProduct(product)
    }

    if (action === ProductActions.ACTIVATE_PRODUCT) {
      activateProduct(product)
    }
  },
}))
