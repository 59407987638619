import type { Client, CombinedError } from "urql"
import { create } from "zustand"

import type { Order, OrderSortField, SortingOrder } from "@/graphql/codegen/graphql.ts"
import { PurchaseOrderListDocument } from "@/graphql/codegen/graphql.ts"

type PurchaseOrderSearchFilters = {
  query?: string
  category?: string
  field?: OrderSortField
  order?: SortingOrder
  start?: string
  end?: string
  [key: string]: unknown
}

type PaginationState = {
  offset: number
  limit: number
  hasMore: boolean
}

export type PurchaseOrderSearchState = {
  data: Order[]
  fetching: boolean
  error: CombinedError | Error | undefined
  pagination: PaginationState
  lastExecutedFilters: PurchaseOrderSearchFilters
  setFetching: (fetching: boolean) => void
  setError: (error: CombinedError | Error | undefined) => void
  resetSearch: () => void
  setData: (data: Order[]) => void
  appendData: (data: Order[]) => void
  executeSearch: (client: Client, filters: PurchaseOrderSearchFilters) => Promise<void>
  loadMore: (client: Client, filters: PurchaseOrderSearchFilters) => Promise<void>
}

const deduplicateData = (newData: Order[], existingData: Order[]): Order[] => {
  const uniqueIds = new Set(existingData.map((order) => order.orderId))
  return [...existingData, ...newData.filter((order) => !uniqueIds.has(order.orderId))]
}

const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: undefined,
  pagination: {
    offset: 0,
    limit: 20,
    hasMore: true,
  },
  lastExecutedFilters: {},
}

export const usePurchaseOrdersSearchStore = create<PurchaseOrderSearchState>((set, get) => ({
  ...INITIAL_STATE,

  setFetching: (fetching) => set({ fetching }),
  setError: (error) => set({ error }),
  resetSearch: () => set(INITIAL_STATE),
  setData: (data) =>
    set((state) => ({
      data,
      error: undefined,
      pagination: {
        ...state.pagination,
        offset: 0,
        hasMore: data.length === state.pagination.limit,
      },
    })),
  appendData: (data) =>
    set((state) => ({
      data: deduplicateData(data, state.data),
      error: undefined,
      pagination: {
        ...state.pagination,
        offset: state.pagination.offset + state.pagination.limit,
        hasMore: data.length === state.pagination.limit,
      },
    })),

  executeSearch: async (client: Client, filters: PurchaseOrderSearchFilters) => {
    const state = get()
    if (state.fetching) return

    const { query, ...restFilters } = filters || {}
    const validQuery = typeof query === "string" && query.length >= 3 ? query : undefined
    const currentFilters = { ...restFilters, ...(validQuery ? { query: validQuery } : {}) }

    if (JSON.stringify(currentFilters) === JSON.stringify(state.lastExecutedFilters)) return

    const shouldExecuteSearch = validQuery || Object.keys(restFilters).length > 0
    if (!shouldExecuteSearch) {
      set({
        data: [],
        pagination: { ...state.pagination, offset: 0, hasMore: false },
        lastExecutedFilters: {},
      })
      return
    }

    set({ fetching: true })

    try {
      const { category, field, order, start, end, ...otherFilters } = currentFilters
      const queryInput = {
        filter: {
          ...otherFilters,
          ...(start && {
            sentDateRange: {
              start,
              end,
            },
          }),
        },
        offset: 0,
        limit: state.pagination.limit,
        sort: { field, order },
      }

      const result = await client.query(PurchaseOrderListDocument, { input: queryInput }).toPromise()

      if (result.data?.order?.list.__typename === "OrderListSuccess") {
        const orders = result.data.order.list.orders as Order[]
        set({
          data: orders,
          pagination: {
            ...state.pagination,
            offset: 0,
            hasMore: orders.length === state.pagination.limit,
          },
          lastExecutedFilters: currentFilters,
        })
      }

      if (result.error) set({ error: result.error })
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },

  loadMore: async (client: Client, filters: PurchaseOrderSearchFilters) => {
    const state = get()
    if (state.fetching || !state.pagination.hasMore) return

    const { query, ...restFilters } = filters || {}
    const validQuery = typeof query === "string" && query.length >= 3 ? query : undefined
    const currentFilters = { ...restFilters, ...(validQuery ? { query: validQuery } : {}) }

    if (JSON.stringify(currentFilters) !== JSON.stringify(state.lastExecutedFilters)) {
      await state.executeSearch(client, filters)
      return
    }

    set({ fetching: true })
    const newOffset = state.pagination.offset + state.pagination.limit

    try {
      const { category, field, order, start, end, ...otherFilters } = currentFilters
      const queryInput = {
        filter: {
          ...otherFilters,
          ...(start && {
            sentDateRange: {
              start,
              end,
            },
          }),
        },
        offset: newOffset,
        limit: state.pagination.limit,
        sort: { field, order },
      }

      const result = await client.query(PurchaseOrderListDocument, { input: queryInput }).toPromise()

      if (result.data?.order?.list.__typename === "OrderListSuccess") {
        const orders = result.data.order.list.orders as Order[]
        set((prevState) => ({
          data: deduplicateData(orders, prevState.data),
          pagination: {
            ...prevState.pagination,
            offset: newOffset,
            hasMore: orders.length === prevState.pagination.limit,
          },
        }))
      }

      if (result.error) set({ error: result.error })
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },
}))
