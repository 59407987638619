import { z } from "zod"

const emptyToNullableString = (value: any) => (value === "" ? null : value)
const nullableString = z.preprocess(emptyToNullableString, z.string().nullable())

const emptyToNullableNumber = (value: any) => {
  const numberValue = Number(value)
  return isNaN(numberValue) || value === "" ? null : numberValue
}
const nullableNumber = z.preprocess(
  emptyToNullableNumber,
  z
    .number()
    .nonnegative({ message: "Value must be positive" })
    .nullable()
    .refine(
      (value) => {
        if (value === null) return true
        return !isNaN(value) && value >= 0 && value !== 0
      },
      { message: "Must be a valid positive number greater than 0" }
    )
)
const nullableInteger = z.preprocess(
  emptyToNullableNumber,
  z
    .number()
    .nonnegative({ message: "Value must be positive" })
    .int({ message: "Value must be a whole number" })
    .nullable()
    .refine((value) => value === null || Number.isInteger(value), { message: "Must be a whole number" })
)

export const AddPackagingInfoSchema = z.object({
  freightItem: nullableString,
  freightClass: nullableString,
  packagingType: nullableString,
  packageWeight: nullableNumber.refine((value) => value === null || value > 0, {
    message: "Package weight must be greater than 0",
  }),
  packageWeightUom: nullableString,
  packageLength: nullableNumber,
  packageWidth: nullableNumber,
  packageHeight: nullableNumber,
  packageDimensionsUom: nullableString,
  palletWeight: nullableNumber,
  palletWeightUom: nullableString,
  palletSizeLength: nullableNumber,
  palletSizeWidth: nullableNumber,
  palletSizeHeight: nullableNumber,
  palletSizeUom: nullableString,
  packagesPerPallet: nullableInteger,
})
