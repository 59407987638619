import { Box, Tab, Tabs } from "@mui/material"
import { DownloadOutlined, MailOutlined, PrintOutlined } from "@mui-symbols-material/w300"
import { Link, Outlet, createFileRoute, useMatches } from "@tanstack/react-router"
import { useMemo } from "react"

import { Button } from "@/components/common/Button"
import { PageHeader } from "@/components/PageHeader/PageHeader"
import { SectionContainer } from "@/components/SectionContainer/SectionContainer"

const tabs = [
  { label: "Supplier Report", value: "/procurement/supplier-report" },
  { label: "Purchase Orders", value: "/procurement/purchase-orders" },
]

export const Route = createFileRoute("/procurement/_layout")({
  component: ProcurementLayout,
})

function ProcurementLayout() {
  const matches = useMatches()
  const currentPath = matches[matches.length - 1].pathname

  const currentTab = useMemo(() => {
    return tabs.find((tab) => currentPath.startsWith(tab.value))?.value ?? tabs[0].value
  }, [currentPath])

  return (
    <SectionContainer className='m-6' paddingVariant='none'>
      {/* TODO: POST MVP; Metrics Bar */}
      <SectionContainer paddingVariant='none'>
        <PageHeader title='Procurement'>
          <Box className='flex gap-2'>
            <Button appearance='text' startIcon={<MailOutlined />} size='small' disabled>
              Email
            </Button>
            <Button appearance='text' startIcon={<PrintOutlined />} size='small' disabled>
              Print
            </Button>
            <Button appearance='text' startIcon={<DownloadOutlined />} size='small' disabled>
              Export
            </Button>
          </Box>
        </PageHeader>
        <SectionContainer className='pt-0'>
          <Tabs value={currentTab} className='mb-6'>
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} component={Link} to={tab.value} />
            ))}
          </Tabs>
          <Box className='flex min-h-0 grow flex-col'>
            <Outlet />
          </Box>
        </SectionContainer>
      </SectionContainer>
    </SectionContainer>
  )
}
