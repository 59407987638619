import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { CloseFilledOutlined } from "@mui-symbols-material/w300"
import React, { useCallback } from "react"
import { useMutation } from "urql"

import { Button } from "../Button"

import { CommentHistoryList } from "./CommentHistoryList"

import type {
  Comment,
  CommentCreateFailure,
  CommentParentTableType,
  CommentUpdateFailure,
} from "@/graphql/codegen/graphql"
import { CreateCommentMutationDocument, UpdateCommentMutationDocument } from "@/graphql/codegen/graphql"
import { useAuthStore } from "@/stores/authStore"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface CommentHistoryDialogProps {
  open: boolean
  title: string
  comments: Comment[]
  commentParentTableType: CommentParentTableType
  referenceId: string
  onSuccess: () => void
  onClose: () => void
}

export const CommentHistoryDialog: React.FC<CommentHistoryDialogProps> = ({
  open,
  title,
  comments,
  commentParentTableType,
  referenceId,
  onClose,
  onSuccess,
}) => {
  const notify = useNotificationsStore((state) => state.enqueueNotification)
  const user = useAuthStore((state) => state.user)
  const [{ fetching: isCreating }, createComment] = useMutation(CreateCommentMutationDocument)
  const [{ fetching: isUpdating }, updateComment] = useMutation(UpdateCommentMutationDocument)

  const handleAddComment = useCallback(
    async (value: string) => {
      if (!value.trim()) {
        notify({
          type: "info",
          content: <p>Comment can't be empty</p>,
        })
        return
      }

      const result = await createComment({
        input: { value: value.trim(), createdBy: user?.id || "", referenceId, type: commentParentTableType },
      })

      if (
        result.data?.comment.create.__typename === "CommentCreateSuccess" &&
        result.data?.comment.create?.createdComment
      ) {
        notify({
          type: "success",
          content: <p>Successfully added a comment</p>,
        })
      } else if (result.data?.comment.create.__typename === "CommentCreateFailure") {
        const errorMessage = (result.data.comment.create as CommentCreateFailure).error.message
        notify({
          type: "error",
          content: <p>{errorMessage}</p>,
        })
      }
      onSuccess()
    },
    [user?.id, createComment, referenceId, commentParentTableType, onSuccess, notify]
  )

  const handleUpdateComment = useCallback(
    async (commentId: string, value: string) => {
      if (!value.trim()) {
        notify({
          type: "info",
          content: <p>Comment can't be empty</p>,
        })
        return
      }
      const result = await updateComment({ input: { commentId, value: value.trim() } })

      if (result.data?.comment.update.__typename === "CommentUpdateSuccess") {
        notify({
          type: "success",
          content: <p>Successfully updated the comment</p>,
        })
      } else if (result.data?.comment.update.__typename === "CommentUpdateFailure") {
        const errorMessage = (result.data.comment.update as CommentUpdateFailure).error.message
        notify({
          type: "error",
          content: <p>{errorMessage}</p>,
        })
      }
      onSuccess()
    },
    [updateComment, onSuccess, notify]
  )

  const handleArchiveComment = useCallback(() => {
    // Implement the archive logic here
    // setLocalComments((prev) => prev.filter((comment) => comment.commentId !== commentId))
    // setHasChanges(true)
  }, [])

  const handleClose = useCallback(() => {
    onSuccess()
    onClose()
  }, [onSuccess, onClose])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      PaperProps={{
        style: { height: "80vh", display: "flex", flexDirection: "column" },
      }}
    >
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {title && <Typography variant='h5'>{title}</Typography>}
          <Button size='small' appearance='text' onClick={handleClose}>
            <CloseFilledOutlined className='text-blue-gray' />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <CommentHistoryList
          comments={comments}
          onAddComment={handleAddComment}
          onUpdateComment={handleUpdateComment}
          onArchiveComment={handleArchiveComment}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isCreating || isUpdating}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
