import { Box } from "@mui/material"
import { AddOutlined } from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import { type FunctionComponent, useCallback, useState } from "react"
import { FormProvider } from "react-hook-form"

import AddProductModal from "./AddProductModal"

import { Button } from "@/components/common/Button"
import type { Product } from "@/graphql/codegen/graphql"
import { CompanyStatus } from "@/graphql/codegen/graphql"
import { useAddProduct } from "@/screens/Companies/products/hooks/useAddProduct"
import { ProductFilterDrawer } from "@/screens/Companies/products/ProductFilterDrawer"
import { ProductListTable } from "@/screens/Companies/products/ProductListTable"
import { ProductSearchBar } from "@/screens/Companies/products/ProductSearchBar"
import { useProductSearchStore } from "@/screens/Companies/products/stores/useProductsSearchStore"
import { useCompanyStore } from "@/screens/Companies/stores/useCompanyStore"
import { usePurchaseOrderDialog } from "@/screens/Procurement/hooks/usePurchaseOrderDialog"
import { PurchaseOrderDialog } from "@/screens/Procurement/PurchaseOrder/components/PurchaseOrderDialog"

const ProductContainer: FunctionComponent = () => {
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const { companyId } = useParams({ from: "/companies/$companyId/edit" })
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([])

  const company = useCompanyStore((state) => state.company)

  const { open, setOpen, methods, handleSubmit } = useAddProduct({ companyId })

  const toggleFilterDrawer = useCallback(() => setFilterDrawerOpen((prev) => !prev), [])

  const handleProductSelection = (products: Product[]) => {
    setSelectedProducts(products)
  }

  const data = useProductSearchStore(({ data }) => data)
  const fetchingProducts = useProductSearchStore(({ fetching }) => fetching)
  const error = useProductSearchStore(({ error }) => error)
  const executeSearch = useProductSearchStore(({ executeSearch }) => executeSearch)
  const loadMore = useProductSearchStore(({ loadMore }) => loadMore)

  const {
    open: purchaseOrderDialogOpen,
    fetching,
    handleOpen,
    handleClose,
    handleSubmit: handlePurchaseOrderSubmit,
  } = usePurchaseOrderDialog()

  return (
    <Box className='flex h-full flex-col gap-4'>
      {company?.status === CompanyStatus.Active && data.length > 0 ? (
        <Box className='flex justify-between'>
          <ProductSearchBar onShowFilter={toggleFilterDrawer} />
          <Box className='flex items-center gap-2'>
            {/* add PO button */}
            <Button
              appearance='outlined'
              variant='primary'
              startIcon={<AddOutlined />}
              onClick={() => handleOpen()}
              disabled={selectedProducts.length === 0}
            >
              Start PO
            </Button>
            <Button appearance='outlined' variant='primary' startIcon={<AddOutlined />} onClick={() => setOpen(true)}>
              New Product
            </Button>
          </Box>
        </Box>
      ) : (
        <Box className='flex justify-end'>
          <Button appearance='outlined' variant='primary' startIcon={<AddOutlined />} onClick={() => setOpen(true)}>
            New Product
          </Button>
        </Box>
      )}
      <Box className='grow overflow-hidden'>
        {company && (
          <ProductListTable
            company={company}
            methods={methods}
            open={open}
            data={data}
            fetching={fetchingProducts}
            error={error}
            executeSearch={executeSearch}
            loadMore={loadMore}
            setOpen={setOpen}
            onProductSelection={handleProductSelection}
          />
        )}
      </Box>
      <FormProvider {...methods}>
        <AddProductModal
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          onSubmit={handleSubmit}
        />
      </FormProvider>
      <ProductFilterDrawer showFilterDrawer={isFilterDrawerOpen} toggleDrawer={toggleFilterDrawer} />
      {company && (
        <PurchaseOrderDialog
          open={purchaseOrderDialogOpen}
          onClose={() => handleClose()}
          fetching={fetching}
          company={company}
          products={selectedProducts}
          onSubmit={handlePurchaseOrderSubmit}
        />
      )}
    </Box>
  )
}

export default ProductContainer
