import { type AnyRoute, type RouteLoaderFn, redirect } from "@tanstack/react-router"
import { checkAuthStatus } from "shared/services/auth.service"

import { useAuthStore } from "@/stores/authStore"

export const PUBLIC_ROUTES = ["/auth/callback"] as const

export const isPublicRoute = (pathname: string): boolean => PUBLIC_ROUTES.some((route) => pathname.startsWith(route))

export const protectedLoader = async (): Promise<ReturnType<RouteLoaderFn<AnyRoute>> | null> => {
  const store = useAuthStore.getState()

  if (store.status === "idle") {
    await checkAuthStatus()
  }

  if (store.status !== "authenticated") {
    // store.signIn()
    return null
  }

  return null
}

export const publicLoader = async (): Promise<ReturnType<RouteLoaderFn<AnyRoute>> | null> => {
  const store = useAuthStore.getState()

  if (store.status === "idle") {
    await checkAuthStatus()
  }

  if (store.status === "authenticated") {
    return redirect({
      to: "/",
      search: {},
    })
  }

  return null
}

// Type-safe wrapper for protected routes
export const withProtected =
  (loader?: RouteLoaderFn<AnyRoute>) =>
  async (args: Parameters<RouteLoaderFn<AnyRoute>>[0]): Promise<ReturnType<RouteLoaderFn<AnyRoute>> | null> => {
    await protectedLoader()
    return loader ? loader(args) : null
  }

// Type-safe wrapper for public routes
export const withPublic =
  (loader?: RouteLoaderFn<AnyRoute>) =>
  async (args: Parameters<RouteLoaderFn<AnyRoute>>[0]): Promise<ReturnType<RouteLoaderFn<AnyRoute>> | null> => {
    await publicLoader()
    return loader ? loader(args) : null
  }
