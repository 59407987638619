import type {
  ApplicableToEnum,
  IncludeWithEnum,
  RelatedProductInstructionInput,
  TransportationModeEnum,
} from "@/graphql/codegen/graphql"

type CombinationInput = {
  applicableTo: ApplicableToEnum[]
  transportationMode: TransportationModeEnum[]
  includedWith: IncludeWithEnum[]
  productInstructionId: string[]
  groupId: string
}

/**
 * Generates unique combinations of instruction parameters using Set for deduplication
 * and array mapping for better performance.
 * Time Complexity: O(a * t * i * p) where a, t, i, p are lengths of input arrays
 * Space Complexity: O(n) where n is number of unique combinations
 */
export const generateCombinations = (input: CombinationInput): RelatedProductInstructionInput[] => {
  const { applicableTo, transportationMode, includedWith, productInstructionId, groupId } = input

  // Early return if any array is empty
  if (!applicableTo.length || !transportationMode.length || !includedWith.length || !productInstructionId.length) {
    return []
  }

  // Use Set for O(1) lookup of existing combinations
  const uniqueCombinations = new Set<string>()
  const result: RelatedProductInstructionInput[] = []

  // Pre-calculate array lengths for optimization
  const appLength = applicableTo.length
  const transLength = transportationMode.length
  const incLength = includedWith.length
  const prodLength = productInstructionId.length

  // Calculate total possible combinations for initial capacity
  const totalPossibleCombinations = appLength * transLength * incLength * prodLength

  // Pre-allocate result array if possible
  result.length = totalPossibleCombinations

  let resultIndex = 0

  // Use for...of for better performance with arrays
  for (const app of applicableTo) {
    for (const trans of transportationMode) {
      for (const inc of includedWith) {
        for (const prodId of productInstructionId) {
          // Create a unique key for this combination
          const key = `${app}:${trans}:${inc}:${prodId}`

          // Only add if combination is unique
          if (!uniqueCombinations.has(key)) {
            uniqueCombinations.add(key)

            // Create the combination object
            result[resultIndex] = {
              groupId,
              applicableTo: app,
              transportationMode: trans,
              includedWith: inc,
              productInstructionId: prodId,
            }

            resultIndex++
          }
        }
      }
    }
  }

  // Trim any unused pre-allocated space
  return result.slice(0, resultIndex)
}
