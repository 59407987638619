import Box from "@mui/material/Box"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import { FilterAltOutlined, SearchOutlined } from "@mui-symbols-material/w300"
import { useNavigate, useSearch } from "@tanstack/react-router"
import type { ChangeEvent, FC } from "react"

import { Button } from "@/components/common/Button"
import { getRefinedSearchParams } from "@/screens/Companies/utils"

interface SupplierReportSearchProps {
  onShowFilter: () => void
}

const searchHint = "Search by Supplier Name or Product Description"

export const SupplierReportSearchBar: FC<SupplierReportSearchProps> = ({ onShowFilter }) => {
  const navigate = useNavigate()
  const currentSearch = useSearch({ from: "/procurement/_layout/supplier-report" })

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const queryValue = e.target.value

    const updatedSearchParams = getRefinedSearchParams({
      ...currentSearch,
      query: queryValue,
    })

    void navigate({
      to: "/procurement/supplier-report",
      ...updatedSearchParams,
    })
  }

  return (
    <>
      <Box className='flex space-x-4'>
        <Box className='flex w-full max-w-lg items-center overflow-hidden rounded-lg border border-gray-400'>
          <TextField
            data-testid='search-input'
            defaultValue={currentSearch?.query}
            variant='outlined'
            placeholder={searchHint}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchOutlined className='text-gray-600' />
                  </InputAdornment>
                ),
                classes: {
                  notchedOutline: "border-0",
                  root: "flex items-center justify-center gap-2 rounded border-none px-4 font-normal text-black hover:outline-none",
                  input:
                    "border-none outline-none hover:border-none hover:outline-none focus:border-none focus:outline-none focus:ring-0 px-0 font-thin text-ellipsis",
                },
                onChange: handleInputChange,
              },
            }}
            className='flex-1'
          />
        </Box>
        <Button
          appearance='outlined'
          value='Filter'
          onClick={onShowFilter}
          startIcon={<FilterAltOutlined />}
          rootElementName='button'
        />
      </Box>
    </>
  )
}
