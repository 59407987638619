import { create } from "zustand"

import { type Notification, NotificationSchema } from "@/schemas/notification.ts"
import type { NotificationStore } from "@/types/notification.ts"

const MAX_NOTIFICATIONS = 100

export const useNotificationsStore = create<NotificationStore>((set, get) => ({
  notifications: [],

  enqueueNotification: (notification: Partial<Notification>) =>
    set((state) => {
      const parsedNotification = {
        ...NotificationSchema.parse(notification),
        read: false,
        silent: false,
      }

      return {
        notifications: [...state.notifications.slice(-MAX_NOTIFICATIONS + 1), parsedNotification],
      }
    }),

  dequeueNotification: () =>
    set((state) => ({
      notifications: state.notifications.filter(
        (notification, index) => index !== 0 || notification.silent // Keep silent notifications
      ),
    })),

  markAsRead: (id: number) =>
    set((state) => ({
      notifications: state.notifications.map((notification) =>
        notification.id === id ? { ...notification, read: true } : notification
      ),
    })),

  getUnreadCount: () => {
    const notifications = get().notifications
    // Don't count the most recent notification as it's likely being viewed
    const skipLatest = notifications.length > 0 ? 1 : 0
    return notifications.slice(0, -skipLatest).filter((notification) => !notification.read).length
  },
}))
