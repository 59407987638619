import type { TreeNode } from "@/types/tree.ts"

/**
 * Recursively searches through a tree structure to find a node with a given ID.
 *
 * This function accepts an array of nodes, each of which can potentially have
 * a list of child nodes, and attempts to locate a node whose `id` property
 * matches the specified `targetId`. If found, it returns that node; otherwise,
 * it returns `undefined`.
 *
 * @template T
 * @param {TreeNode<T>[]} nodes - An array of tree nodes to search.
 * @param {string} targetId - The ID of the node to find.
 * @returns {TreeNode<T> | undefined} - The node with the matching ID if found, otherwise `undefined`.
 */

export const findCompanyType = (nodes: TreeNode<string>[], targetId: string): TreeNode<string> | undefined => {
  for (const node of nodes) {
    if (node.id === targetId) return node
    if (node.children) {
      const found = findCompanyType(node.children, targetId)
      if (found) return found
    }
  }
  return undefined
}
