import Box from "@mui/material/Box"
import type { FC } from "react"

import { SupplierReportrSearchFilterChips } from "./Filters/SupplierReportSearchFilterChips"
import { SupplierReportSearchBar } from "./SupplierReportSearchBar"

interface SupplierReportSearchProps {
  onShowFilter: () => void
}

export const SupplierReportSearch: FC<SupplierReportSearchProps> = ({ onShowFilter }) => {
  return (
    <Box className='flex flex-col gap-4'>
      <SupplierReportSearchBar onShowFilter={onShowFilter} />
      <Box data-testid='vendors-search-filter-chips' className='flex flex-wrap items-center gap-4 pb-6'>
        <SupplierReportrSearchFilterChips />
      </Box>
    </Box>
  )
}
