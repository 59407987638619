import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import type { UseFormReturn } from "react-hook-form"
import { useForm } from "react-hook-form"

import type { ProductUpdateFailure } from "@/graphql/codegen/graphql"
import { ProductFormInfoItem } from "@/graphql/codegen/graphql"
import type { AddProductModalType } from "@/screens/Products/components/AddProductSchema"
import { AddProductModalSchema } from "@/screens/Products/components/AddProductSchema"
import type { LocationAssociation } from "@/screens/Products/components/ProductShipLocationSelect.tsx"
import { useGetProduct } from "@/screens/Products/hooks/useGetProduct.tsx"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface UseEditProductProps {
  productId: number
}

interface UseEditProductReturn {
  methods: UseFormReturn<AddProductModalType>
  handleSubmit: (data: any) => Promise<void>
}

export const useEditProduct = ({ productId }: UseEditProductProps): UseEditProductReturn => {
  const { enqueueNotification } = useNotificationsStore()

  const { productDetails, productDetailsFetching, updateProduct } = useGetProduct(productId)

  const methods = useForm({
    defaultValues: {
      companyId: productDetails?.company?.companyId,
      vendorSku: productDetails?.vendorSku,
      vendorDescription: productDetails?.vendorDescription,
      companyProductDescription: productDetails?.companyProductDescription,
      productManager: {
        id: productDetails?.productManagerId,
        name: `${productDetails?.productManager?.firstName || ""} ${productDetails?.productManager?.lastName || ""}`,
      },
      classification: { id: productDetails?.classification?.id, name: productDetails?.classification?.description },
      productFormInfo: productDetails?.productFormInfo,
      locationsAssociations: productDetails?.locationsAssociations,
      uomId: productDetails?.uomId,
    },
    resolver: zodResolver(AddProductModalSchema),
    mode: "all",
  })

  const { setError } = methods

  useEffect(() => {
    if (productDetails) {
      methods.reset({
        companyId: productDetails.company?.companyId,
        vendorSku: productDetails.vendorSku,
        vendorDescription: productDetails.vendorDescription,
        companyProductDescription: productDetails.companyProductDescription,
        productManager: {
          id: productDetails.productManagerId,
          name: `${productDetails.productManager?.firstName || ""} ${productDetails.productManager?.lastName || ""}`,
        },
        classification: { id: productDetails.classification?.id, name: productDetails.classification?.description },
        productFormInfo: Object.keys(ProductFormInfoItem).find(
          (key) => ProductFormInfoItem[key as keyof typeof ProductFormInfoItem] === productDetails.productFormInfo
        ) as ProductFormInfoItem | undefined,
        locationsAssociations: productDetails.locationsAssociations.map((location) => {
          return {
            address1: location.shipping?.address1,
            city: location.shipping?.city,
            regionIsoCode: location.shipping?.regionIsoCode,
            locationAssociationId: location.locationAssociationId,
          }
        }),
        uomId: productDetails.uomId ?? "",
      })
    }
  }, [productDetailsFetching, productDetails, methods])

  const handleSubmit = async (data: AddProductModalType) => {
    const { productManager, locationsAssociations, classification, uomId, ...rest } = data
    const { error, data: result } = await updateProduct({
      input: {
        ...rest,
        uomId: uomId || null,
        classificationId: classification.id,
        productId: Number(productId),
        productFormInfo: ProductFormInfoItem[data.productFormInfo as keyof typeof ProductFormInfoItem],
        locationsAssociations: (locationsAssociations as LocationAssociation[]).map(({ locationAssociationId }) => {
          return { locationAssociationId }
        }),
        productManagerId: productManager.id,
      },
    })
    if (error || (result?.product?.update as ProductUpdateFailure)?.error) {
      setError("root", {
        type: "server",
        message: error?.message || (result?.product?.update as ProductUpdateFailure)?.error?.message,
      })

      enqueueNotification({
        type: "error",
        content: <p>Product updating failed</p>,
      })
    } else {
      enqueueNotification({
        type: "success",
        content: <p>Product successfully updated</p>,
      })
    }
    methods.reset()
  }

  return { methods, handleSubmit }
}
