import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import { EditOutlined, UploadFileOutlined } from "@mui-symbols-material/w300"

interface PurchaseOrderDropdownMenuProps {
  anchorEl: HTMLElement | null
  onClose: () => void
  open: boolean
}

const PurchaseOrderDropdownMenu: React.FC<PurchaseOrderDropdownMenuProps> = ({ anchorEl, onClose, open }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem>
        <ListItemIcon>
          <EditOutlined sx={{ fontVariationSettings: '"wght" 300' }} />
        </ListItemIcon>
        <ListItemText primary='Update PO' />
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <UploadFileOutlined />
        </ListItemIcon>
        <ListItemText primary='Attach File to PO' />
      </MenuItem>
    </Menu>
  )
}

export default PurchaseOrderDropdownMenu
