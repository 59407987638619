import { Typography } from "@mui/material"
import type { FunctionComponent } from "react"

import { getTimeFormat } from "./TechnicalInformationContainer"

import type { Product } from "@/graphql/codegen/graphql"

type TechnicalInformationListProps = {
  productDetails: Pick<
    Product,
    "specificGravity" | "shelfLifeDays" | "cassRegistryId" | "harmonizedTariffSchedule" | "usmcaClass" | "unNumber"
  >
}

export const TechnicalInformationList: FunctionComponent<TechnicalInformationListProps> = ({ productDetails }) => {
  const shelfLifeDays = productDetails.shelfLifeDays || 0
  const { divisible, format } = getTimeFormat(shelfLifeDays)

  return (
    <div className='mr-20'>
      {[
        { label: "Specific Gravity:", value: productDetails.specificGravity },
        {
          label: `Shelf Life (${format})`,
          value: (shelfLifeDays / divisible).toFixed(1).replace(/\.?0+$/, ""),
        },
        { label: "CAS:", value: productDetails.cassRegistryId },
        { label: "HTS:", value: productDetails.harmonizedTariffSchedule },
        { label: "USMCA (NAFTA):", value: productDetails.usmcaClass ? "Selected" : "Not Selected" },
        { label: "UN Number:", value: productDetails.unNumber },
      ].map((item, index) => (
        <div key={index} className='flex h-[54px] items-center border-b border-gray-500'>
          <Typography variant='body2' className='flex-1 text-left text-gray-900'>
            {item.label}
          </Typography>
          <Typography variant='body2' className='flex-1 text-left text-sm'>
            {item.value || "-"}
          </Typography>
        </div>
      ))}
    </div>
  )
}
