import { Box, Checkbox, IconButton } from "@mui/material"
import {
  AddOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpFilledOutlined,
  WarningOutlined,
} from "@mui-symbols-material/w300"
import { Link } from "@tanstack/react-router"
import type { CellContext, ColumnDef } from "@tanstack/react-table"
import type { ReactElement } from "react"

import { ProductActionMenu } from "./ProductActionsMenu"
import { useProductActionsStore } from "./stores/useProductsActions"
import { ProductActionsDialogs } from "./types/schema"
import { ProductStatusMap } from "./utils/productStatusMap"

import { Button } from "@/components/common/Button"
import { StatusIndicator } from "@/components/common/StatusIndicator/StatusIndicator"
import { type Product, ProductStatusReason } from "@/graphql/codegen/graphql"

const ActionsCell = ({ cell }: CellContext<Product, unknown>): ReactElement => {
  const { row } = cell.getContext()

  return (
    <Box className='flex w-full items-center justify-end space-x-4'>
      <Link to='/product/$productId/edit' params={{ productId: row.original.productId }}>
        <Button appearance='outlined' variant='primary' startIcon={<AddOutlined />}>
          Product Details
        </Button>
      </Link>
      <ProductActionMenu {...row} />
      <IconButton aria-label='expand row' size='small' onClick={() => row.toggleExpanded()}>
        {row.getIsExpanded() ? <KeyboardArrowUpFilledOutlined /> : <KeyboardArrowDownOutlined />}
      </IconButton>
    </Box>
  )
}

const SKUCell = ({ getValue, row }: CellContext<Product, unknown>): ReactElement => {
  const value = getValue() as string
  const setOpenedDialog = useProductActionsStore((state) => state.setOpenedDialog)
  const setProductAtAction = useProductActionsStore((state) => state.setProductAtAction)

  if (!value) {
    return (
      <Button
        appearance='text'
        variant='primary'
        startIcon={<WarningOutlined />}
        onClick={() => {
          setProductAtAction(row.original)
          setOpenedDialog(ProductActionsDialogs.EDIT_PRODUCT)
        }}
      >
        Add SKU
      </Button>
    )
  }
  return <>{value}</>
}

const LocationCell = ({ row }: CellContext<Product, unknown>): ReactElement => {
  const value =
    row.original.locationsAssociations?.length > 0 ? row.original.locationsAssociations[0].shipping?.name : ""
  const setOpenedDialog = useProductActionsStore((state) => state.setOpenedDialog)
  const setProductAtAction = useProductActionsStore((state) => state.setProductAtAction)

  if (!value) {
    return (
      <Button
        appearance='text'
        variant='primary'
        startIcon={<WarningOutlined />}
        onClick={() => {
          setProductAtAction(row.original)
          setOpenedDialog(ProductActionsDialogs.EDIT_PRODUCT)
        }}
      >
        Add Location
      </Button>
    )
  }
  return <>{value}</>
}
export const companyProductListColumns: ColumnDef<Product>[] = [
  {
    accessorKey: "productId",
    id: "status",
    header: "",
    cell: ({ row }: CellContext<Product, unknown>): ReactElement => {
      return (
        <Box className='space-x-5'>
          <Checkbox
            id={row.id}
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            disabled={!row.getCanSelect()}
          />
        </Box>
      )
    },
    size: 50,
  },
  {
    header: "Product ID",
    accessorKey: "productId",
    cell: ({ row }) => (
      <Box className='flex items-center gap-x-4'>
        <StatusIndicator
          status={row.original.activityStatusReason ?? ProductStatusReason.Rejected}
          map={ProductStatusMap}
        />
        {row.original.productId}
      </Box>
    ),
    size: 80,
  },
  {
    accessorKey: "companyProductDescription",
    header: "Akrochem Description",
  },
  {
    accessorKey: "vendorSku",
    header: "Vendor SKU",
    cell: SKUCell,
  },
  {
    accessorKey: "vendorDescription",
    header: "Vendor Description",
  },
  {
    accessorKey: "shipFromLocation",
    header: "Ship From",
    cell: LocationCell,
  },
  {
    accessorKey: "actions",
    header: "",
    cell: ActionsCell,
    size: 325,
  },
]
