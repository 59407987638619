import { useCallback, useEffect, useState } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import type { Contact, ContactListSuccess, Query } from "@/graphql/codegen/graphql"
import { ContactListDocument } from "@/graphql/codegen/graphql"

const LIMIT = 20

export const useGetContacts = (
  companyId: string
): {
  contactListError: CombinedError | undefined
  contactList: Contact[]
  contactListFetching: boolean
  triggerQuery: () => void
  loadMore: () => void
} => {
  const [offset, setOffset] = useState(0)
  const [contactList, setContactList] = useState<Contact[]>([])

  const [
    { data: contactDetails, fetching: contactDetailsFetching, error: contactDetailsError },
    queryContactByCompanyId,
  ] = useQuery<Pick<Query, "contact">>({
    query: ContactListDocument,
    variables: { input: { filter: { companyIds: [companyId] }, limit: LIMIT, offset } },
  })

  useEffect(() => {
    if (contactDetails) {
      const locations = (contactDetails.contact?.list as ContactListSuccess).contacts
      setContactList((prev) => (offset === 0 ? locations : [...prev, ...locations]))
    }
  }, [contactDetails, offset])

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  const triggerQuery = useCallback(
    () => queryContactByCompanyId({ companyId, requestPolicy: "network-only" }),
    [companyId, queryContactByCompanyId]
  )

  return {
    contactList,
    contactListFetching: contactDetailsFetching,
    contactListError: contactDetailsError,
    loadMore,
    triggerQuery,
  }
}
