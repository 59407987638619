import { Box, LinearProgress, Typography } from "@mui/material"
import { ArrowDownwardOutlined, ArrowUpwardOutlined, ChevronRightOutlined } from "@mui-symbols-material/w300"
import { useSearch } from "@tanstack/react-router"
import { flexRender } from "@tanstack/react-table"
import React, { Suspense, useRef } from "react"
import { useShallow } from "zustand/shallow"

import { useSupplierReportSearchStore } from "../../stores/useSupplierReportSearchStore"

import { Button } from "@/components/common/Button"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore"
import { useThrottle } from "@/hooks/useThrottle"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import { PurchaseOrderDialog } from "@/screens/Procurement/PurchaseOrder/components/PurchaseOrderDialog"
import { SupplierReportProductTableRow } from "@/screens/Procurement/Supplier/components/Table/SupplierReportProductTableRow"
import { useSupplierReportPurchaseOrder } from "@/screens/Procurement/Supplier/hooks/useSupplierReportPurchaseOrder"
import { useSupplierReportTable } from "@/screens/Procurement/Supplier/hooks/useSupplierReportTable.tsx"

const LazyExpandedContent = React.lazy(() => import("./ExpandedRow/SupplierReportProductTable"))

const ROW_HEIGHT = 64

export const SupplierReportTable: React.FC = () => {
  const currentSearch = useSearch({ from: "/procurement/_layout/supplier-report" })

  const data = useSupplierReportSearchStore(({ data }) => data)
  const fetching = useSupplierReportSearchStore(({ fetching }) => fetching)
  const error = useSupplierReportSearchStore(({ error }) => error)
  const loadMore = useSupplierReportSearchStore(useShallow(({ loadMore }) => loadMore))

  const { expanded, table, productRowSelection, getFlexibleColumnWidth, handleProductSelectionChange } =
    useSupplierReportTable()
  const {
    fetching: purchaseOrderFetching,
    isPurchaseOrderDialogOpen,
    selectedCompany,
    selectedCompanyProducts,
    handleStartPO,
    handleCloseDialog,
    handleSubmitPO,
  } = useSupplierReportPurchaseOrder({ data, productRowSelection })

  const tableContainerRef = useRef<HTMLDivElement>(null)
  const [throttledLoadMore] = useThrottle(() => loadMore(queryClient, currentSearch), 200)
  const onScroll = useScrollLoadMore({ fetching, loadMore: throttledLoadMore, scrollContainerRef: tableContainerRef })

  return (
    <>
      {isPurchaseOrderDialogOpen && (
        <PurchaseOrderDialog
          open={isPurchaseOrderDialogOpen}
          fetching={purchaseOrderFetching}
          company={selectedCompany!} // this is defined when dialog is opened
          products={selectedCompanyProducts}
          onClose={handleCloseDialog}
          onSubmit={handleSubmitPO}
        />
      )}

      <Box className='flex h-full flex-col'>
        {/* Sticky Container */}
        <Box className='sticky z-10'>
          <LinearProgress className={`h-1 ${fetching ? "visible" : "invisible"}`} />
          {/* Headers */}
          <Box className='bg-gray-100 text-gray-700'>
            <Box
              className='grid border-b border-gray-200'
              style={{
                height: ROW_HEIGHT,
                gridTemplateColumns: table.getFlatHeaders().map(getFlexibleColumnWidth).join(" "),
              }}
            >
              {table.getFlatHeaders().map((header) => (
                <Box
                  key={header.id}
                  className={`flex items-center px-2 font-bold text-gray-700 ${
                    header.column.getCanSort() ? "cursor-pointer select-none" : ""
                  }`}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{ width: header.getSize() }}
                >
                  <Box className='flex items-center'>
                    {header.column.getCanSort() && (
                      <Box className='mr-2'>
                        {header.column.getIsSorted() === "asc" ? (
                          <ArrowUpwardOutlined fontSize='small' />
                        ) : header.column.getIsSorted() === "desc" ? (
                          <ArrowDownwardOutlined fontSize='small' />
                        ) : (
                          <span className='size-4' /> // Placeholder for unsorted state
                        )}
                      </Box>
                    )}
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        {/* Scrollable Content */}
        <Box className='grow overflow-auto' onScroll={onScroll} ref={tableContainerRef}>
          <Box className='w-full'>
            {!data.length && (
              <Box className='w-full'>
                <Box
                  className='grid items-center border-b border-gray-200 bg-white text-gray-700'
                  style={{
                    gridTemplateColumns: table.getFlatHeaders().map(getFlexibleColumnWidth).join(" "),
                    height: ROW_HEIGHT,
                  }}
                >
                  <Box className='col-span-full px-2'>
                    <Typography variant='body2' className='truncate text-center'>
                      No results found according to your search criteria
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            {table.getRowModel().rows.map((row) => {
              const isExpanded = Boolean(expanded[row.id])
              const companySelectedItemsCount = Object.keys(productRowSelection[row.original.companyId] || {}).length

              return (
                <SupplierReportProductTableRow
                  key={row.original.companyId}
                  row={row}
                  isExpanded={isExpanded}
                  getFlexibleColumnWidth={getFlexibleColumnWidth}
                >
                  <Box className='w-full'>
                    <Box className='flex items-center'>
                      <Box className='flex w-full flex-col'>
                        <Box className='mb-2 flex justify-between px-8'>
                          <Box>
                            <Typography variant='h6' className='text-gray-700'>
                              {row.original.name}
                            </Typography>
                            <Typography variant='body2' className='text-gray-500'>
                              Vendor no: {row.original.externalSystemId || "-"} |{" "}
                              {row.original?.locationsAssociations?.[0]?.shipping?.city || "-"}{" "}
                              {row.original?.locationsAssociations?.[0]?.shipping?.region?.name || "-"}{" "}
                              {row.original?.locationsAssociations?.[0]?.shipping?.postalCode || "-"}
                            </Typography>
                          </Box>
                          <Box className='flex items-center gap-4'>
                            <Box className='flex items-center gap-2'>
                              <Typography variant='body1' className='font-bold'>
                                {companySelectedItemsCount} Items
                              </Typography>
                              selected
                            </Box>
                            <Button
                              endIcon={<ChevronRightOutlined />}
                              disabled={companySelectedItemsCount === 0}
                              rootElementName='button'
                              className={companySelectedItemsCount > 0 ? "bg-primary text-white" : ""}
                              onClick={() => handleStartPO(row.original.companyId)}
                            >
                              Start PO
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box key={`expanded-content-${row.original.companyId}`}>
                      <Suspense fallback={<LinearProgress />}>
                        <LazyExpandedContent
                          companyId={row.original.companyId}
                          rowSelection={Object.values(productRowSelection[row.original.companyId] || {})}
                          onRowSelectionChange={(products) =>
                            handleProductSelectionChange(row.original.companyId, products)
                          }
                        />
                      </Suspense>
                    </Box>
                  </Box>
                </SupplierReportProductTableRow>
              )
            })}
          </Box>
        </Box>

        {/* Error and No Data Messages */}
        {error && (
          <Typography color='error' className='p-4'>
            {error.message}
          </Typography>
        )}
      </Box>
    </>
  )
}
