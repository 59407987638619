import { Box, Typography } from "@mui/material"
import { CheckCircleFilledOutlined } from "@mui-symbols-material/w300"
import type { ReactElement } from "react"

import { StatusIndicator } from "@/components/common/StatusIndicator/StatusIndicator"
import type { Product } from "@/graphql/codegen/graphql"
import { ProductStatusReason } from "@/graphql/codegen/graphql"
import { ProductStatusMap } from "@/screens/Companies/products/utils/productStatusMap"

type ProductsAddedProps = {
  products: Product[]
}

export const ProductsAdded = ({ products }: ProductsAddedProps): ReactElement => {
  return (
    <Box className='space-y-4'>
      <Box className='flex items-center'>
        <Typography variant='body1' fontWeight={700} className='text-gray-700'>
          Products Added
        </Typography>
        <Box className='ml-2 flex items-center text-green-500'>
          <CheckCircleFilledOutlined className='text-tertiary-green-500' />
        </Box>
      </Box>
      <Box className='max-h-64 w-full overflow-y-auto rounded-md border border-gray-100 text-sm text-gray-700'>
        {products.map((product) => (
          <Box key={product.productId} className='grid w-full' style={{ gridTemplateColumns: "30% 70%" }}>
            <Box className='flex items-center space-x-4 border border-gray-100 p-4'>
              <StatusIndicator
                status={product.activityStatusReason ?? ProductStatusReason.Discontinued}
                map={ProductStatusMap}
              />
              <Box className='flex items-center space-x-2'>
                <span>{product.productId}</span>
              </Box>
            </Box>
            <Box className='flex items-center border border-gray-100 p-4'>
              {product.vendorDescription || product.companyProductDescription}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
