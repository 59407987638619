import type { Maybe } from "graphql/jsutils/Maybe"
import type React from "react"
import { twMerge } from "tailwind-merge"

import StyledChip from "@/components/common/Chip/Chip"
import { ShipStatus } from "@/graphql/codegen/graphql"

export interface GetStatusDisplayPropertiesParams {
  shipStatus?: Maybe<ShipStatus>
}

export type StatusChipColor = "warning" | "success" | "error" | "default" | "primary" | "secondary" | "info" | undefined

export interface StatusDisplayProperties {
  color: StatusChipColor
  label: string | null
}

export type GetStatusDisplayProperties = (params: GetStatusDisplayPropertiesParams) => StatusDisplayProperties

const getStatusDisplayProperties: GetStatusDisplayProperties = ({ shipStatus }) => {
  switch (shipStatus) {
    case ShipStatus.Pending:
      return {
        color: "default",
        label: "Pending",
      }
    case ShipStatus.InTransit:
      return {
        color: "warning",
        label: "In Transit",
      }
    case ShipStatus.PartiallyDelivered:
      return {
        color: "warning",
        label: "Partially Delivered",
      }
    case ShipStatus.Delivered:
      return {
        color: "success",
        label: "Delivered",
      }
    default:
      return {
        color: undefined,
        label: null,
      }
  }
}

interface ShipStatusChipProps {
  shipStatus?: Maybe<ShipStatus>
  className?: string
}

export const ShipStatusFlatChip: React.FC<ShipStatusChipProps> = ({ shipStatus, className, ...rest }) => {
  const { color, label } = getStatusDisplayProperties({ shipStatus })

  //   if status is not available, return NA warning
  if (!label) {
    return <StyledChip label='NA' color='warning' />
  }

  return (
    !!label && (
      <StyledChip
        {...rest}
        size='small'
        className={twMerge("rounded-sm border-none text-xs text-gray-800", className)}
        classes={{
          colorDefault: "bg-tertiary-yellow-100",
        }}
        variant='outlined'
        color={color}
        label={label}
      />
    )
  )
}
