import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback, useState } from "react"
import { useFormContext } from "react-hook-form"

import { AddProductPriceBreakdown } from "./AddProductInformation"
import { AddShippingTerms } from "./AddShippingTerms"

import { Button } from "@/components/common/Button"

type AddPricingTiersModalProps = {
  open: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data) => void
}

const AddPricingTiersModal: FunctionComponent<AddPricingTiersModalProps> = ({ open, onClose, onSubmit }) => {
  const [step, setStep] = useState(0)
  const methods = useFormContext()
  const { watch } = methods

  const pricingPackageId = watch("pricingPackageId")

  const onNextStep = useCallback(async () => {
    const fieldNames = methods.getValues()
    const fieldsToValidate = Object.keys(fieldNames).filter(
      (field) =>
        !(
          step === 0 &&
          (field === "freightTerms" ||
            field === "incoterms" ||
            field === "incotermsLocationId" ||
            field === "pricingTiersToCreate")
        )
    )
    const isValid = await methods.trigger(fieldsToValidate)
    if (isValid) {
      setStep((prevState) => prevState + 1)
    }
  }, [methods, step])

  const onBackClick = useCallback(() => {
    setStep((prevState) => prevState - 1)
  }, [])

  const handleResetClick = useCallback(() => {
    methods.reset()
    setStep(0)
    onClose("cancel")
  }, [methods, onClose])

  const handleOnSubmit = useCallback(
    (value) => {
      onSubmit(value)
      setStep(0)
      onClose("success")
    },
    [onClose, onSubmit]
  )

  const handleClose = useCallback(
    (reason: "backdropClick" | "escapeKeyDown" | "cancel") => {
      methods.reset()
      setStep(0)
      onClose(reason)
    },
    [methods, onClose]
  )

  const priceTierFields = watch("pricingTiersToCreate")

  const allFieldsValid = priceTierFields.every((_, index) => {
    const { unitOfMeasurement, numberOfUnits, pricePerUnit } = methods.getValues(`pricingTiersToCreate.${index}`)

    const [uomError, unitsError, priceError] = [
      methods.getFieldState(`pricingTiersToCreate.${index}.unitOfMeasurement.id`, methods.formState).error,
      methods.getFieldState(`pricingTiersToCreate.${index}.numberOfUnits`, methods.formState).error,
      methods.getFieldState(`pricingTiersToCreate.${index}.pricePerUnit`, methods.formState).error,
    ]
    const noErrors = !uomError && !unitsError && !priceError
    if (index === 0) {
      return unitOfMeasurement?.id && numberOfUnits && pricePerUnit && noErrors
    }

    // Check conditions: uomId is present and error-free, and either both or neither of units and price are present
    return unitOfMeasurement?.id && noErrors && ((numberOfUnits && pricePerUnit) || (!numberOfUnits && !pricePerUnit))
  })

  const effectiveDateState = methods.getFieldState("effectiveDate", methods.formState)
  const expirationDateState = methods.getFieldState("expirationDate", methods.formState)

  const isPriceBreakdownDisabled =
    allFieldsValid &&
    !effectiveDateState.invalid &&
    watch("effectiveDate") &&
    (!expirationDateState.invalid || !watch("expirationDate"))

  const isShippingTermsDisabled =
    watch("freightTerms") &&
    watch("incoterms") &&
    watch("incotermsLocationId") &&
    Object.keys(methods.formState.dirtyFields).length > 0

  return (
    <Dialog open={open} onClose={(_, reason) => handleClose(reason)} fullWidth>
      <DialogTitle className='px-[38px] font-normal text-primary'>
        {pricingPackageId ? "Edit" : "Add"} Pricing
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => handleClose("cancel")}
        className='absolute right-4 top-4 text-primary'
      >
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent className='px-[38px]'>
        {step === 0 && <AddProductPriceBreakdown />}
        {step === 1 && <AddShippingTerms />}
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleResetClick}>
          Cancel
        </Button>
        {step === 0 && (
          <Button onClick={onNextStep} disabled={!isPriceBreakdownDisabled}>
            Continue
          </Button>
        )}
        {step === 1 && (
          <Box>
            <Button variant='primary' appearance='text' className='mr-6' onClick={onBackClick}>
              Go Back
            </Button>
            <Button disabled={!isShippingTermsDisabled} onClick={() => handleOnSubmit(methods.getValues())}>
              {pricingPackageId ? "Update" : "Save"}
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default AddPricingTiersModal
