import { zodResolver } from "@hookform/resolvers/zod"
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { CloseOutlined } from "@mui-symbols-material/w300"
import { type FC, useCallback, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { AddContactSubscriptionSchema } from "./AddContactSubscriptionSchema"
import { ContactSubscriptionContent } from "./ContactSubscriptionContent"

import { Button } from "@/components/common/Button"
import type { ContactSubscription } from "@/graphql/codegen/graphql"
import { useGetSubscription } from "@/screens/Contacts/edit/hooks/useGetSubscription"

type Props = {
  open: boolean
  contactId: string
  name: string
  companyName?: string
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: () => void
}

const defaultSelectedSubscriptionList: ContactSubscription[] = []

export const ContactSubscriptionDialog: FC<Props> = ({ open, contactId, name, companyName, onClose, onSubmit }) => {
  const {
    selectedSubscriptionList = defaultSelectedSubscriptionList,
    selectedSubscriptionDetailsFetching,
    subscriptionList = [],
    subscriptionListDetailsFetching,
    deleteSubscriptions,
    reexecuteQueryWithReset,
    batchCreateSubscriptions,
  } = useGetSubscription(contactId)

  const methods = useForm({
    defaultValues: {
      batchContactSubscriptionsToCreate: [],
    },
    resolver: zodResolver(AddContactSubscriptionSchema),
    mode: "all",
  })
  const selectedSubscriptions = methods.watch("batchContactSubscriptionsToCreate")

  useEffect(() => {
    methods.reset({
      batchContactSubscriptionsToCreate: selectedSubscriptionList.map(({ contactSubscriptionId, name }) => ({
        contactSubscriptionId,
        name,
      })),
    })
  }, [selectedSubscriptionList])

  const onHandleSubmit = useCallback(
    async (data: { batchContactSubscriptionsToCreate: ContactSubscription[] }) => {
      const deleteSubscriptionList = selectedSubscriptionList
        .filter(
          ({ contactSubscriptionId }) =>
            !data.batchContactSubscriptionsToCreate.some(
              ({ contactSubscriptionId: selectedSubscriptionId }) => selectedSubscriptionId === contactSubscriptionId
            )
        )
        .map(({ contactSubscriptionBridgeId }) => contactSubscriptionBridgeId)

      if (deleteSubscriptionList.length > 0) {
        await deleteSubscriptionList.map((contactSubscriptionBridgeId: string) => {
          deleteSubscriptions({
            input: {
              contactSubscriptionBridgeId,
            },
          })
        })
      }
      const formattedSubscriptionData = selectedSubscriptions.map(({ contactSubscriptionId }) => ({
        contactId,
        contactSubscriptionId,
      }))

      await batchCreateSubscriptions({
        input: {
          batchContactSubscriptionsToCreate: formattedSubscriptionData,
        },
      })
      onSubmit()
      methods.reset()
      onClose("success")
      reexecuteQueryWithReset()
    },
    [
      selectedSubscriptionList,
      selectedSubscriptions,
      batchCreateSubscriptions,
      onSubmit,
      methods,
      onClose,
      reexecuteQueryWithReset,
      deleteSubscriptions,
      contactId,
    ]
  )

  const isButtonEnabled = methods.formState.isValid && methods.formState.isDirty

  if (subscriptionListDetailsFetching || selectedSubscriptionDetailsFetching) {
    return <CircularProgress />
  }

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        methods.reset()
        onClose(reason)
      }}
      fullWidth
    >
      <FormProvider {...methods}>
        <DialogTitle className='pb-3 pl-10 font-normal text-primary'>
          {name}
          {companyName && (
            <Typography variant='subtitle1' className='text-gray-700'>
              {companyName}
            </Typography>
          )}
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={() => {
            methods.reset()
            onClose("cancel")
          }}
          className='absolute right-4 top-4 text-primary'
        >
          <CloseOutlined />
        </IconButton>
        <Divider />
        <DialogContent classes={{ root: "p-10" }}>
          <ContactSubscriptionContent subscriptionList={subscriptionList} />
        </DialogContent>
        <DialogActions className={"justify-between px-10 pb-10"}>
          <Button
            appearance='outlined'
            onClick={() => {
              methods.reset()
              onClose("cancel")
            }}
          >
            Cancel
          </Button>
          <Button onClick={methods.handleSubmit(onHandleSubmit)} disabled={!isButtonEnabled}>
            Save
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
