import { type PropsWithChildren, createContext, useContext, useState } from "react"
import { useStore } from "zustand"

import type { PDFState, createPDFStore } from "../stores/usePDFStore"

const PDFStoreContext = createContext<ReturnType<typeof createPDFStore> | null>(null)

export function PDFProvider({
  initialStore,
  children,
}: PropsWithChildren<{ initialStore: ReturnType<typeof createPDFStore> }>): JSX.Element {
  const [store] = useState(initialStore)

  return <PDFStoreContext.Provider value={store}>{children}</PDFStoreContext.Provider>
}

// Base hook for store access
const usePDFStore = <T,>(selector: (state: PDFState) => T): T => {
  const store = useContext(PDFStoreContext)
  if (!store) {
    throw new Error("usePDFStore must be used within PDFProvider")
  }
  return useStore(store, selector)
}

// Atomic selectors
export const useIsPDFGenerating = (): boolean => usePDFStore((state) => state.isGenerating)
export const usePDFUrl = (): string | null => usePDFStore((state) => state.url)
export const usePDFError = (): string | null => usePDFStore((state) => state.error)
export const usePDFPendingUpdate = (): boolean => usePDFStore((state) => state.pendingUpdate)

// Actions
export const useSetPDFGenerating = (): ((isGenerating: boolean) => void) => usePDFStore((state) => state.setGenerating)
export const useSetPDFUrl = (): ((url: string | null) => void) => usePDFStore((state) => state.setURL)
export const useSetPDFError = (): ((error: string | null) => void) => usePDFStore((state) => state.setError)
export const useSetPDFPendingUpdate = (): ((pendingUpdate: boolean) => void) =>
  usePDFStore((state) => state.setPendingUpdate)
export const useResetPDF = (): (() => void) => usePDFStore((state) => state.reset)
