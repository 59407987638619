import { Box, Collapse, IconButton, Paper, Typography } from "@mui/material"
import {
  AddOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  WarningOutlined,
} from "@mui-symbols-material/w300"
import { useLoaderData, useParams } from "@tanstack/react-router"
import { type ReactElement, useCallback, useEffect, useMemo, useState } from "react"
import { useMutation } from "urql"

import { useProductOrigin } from "../hooks/useProductOrigin"

import { InformationMissingAlert } from "@/components/common/Alerts/InformationMissingAlert"
import { Button } from "@/components/common/Button"
import { ProductUpdateDocument } from "@/graphql/codegen/graphql"
import { ProductOriginDialog } from "@/screens/Products/components/Product/Information/Origin/ProductOriginDialog"
import { ProductOriginGrid } from "@/screens/Products/components/Product/Information/Origin/ProductOriginGrid"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

const ProductOriginAlert = ({ onAdd }: { onAdd: () => void }): ReactElement => {
  return (
    <InformationMissingAlert
      icon={<WarningOutlined color='primary' />}
      title='Add Product Origin Information'
      action={
        <Button
          color='inherit'
          size='small'
          appearance='outlined'
          className='text-primary-500'
          startIcon={<AddOutlined />}
          onClick={onAdd}
          data-testid='add-origin-button'
        >
          Origin
        </Button>
      }
      description={""}
      data-testid='product-origin-alert'
    />
  )
}

export const ProductOriginContainer = (): ReactElement => {
  const { productId } = useParams({ from: "/product/$productId/edit/_layout/information" })
  const [dialogOpen, setDialogOpen] = useState(false)

  const notify = useNotificationsStore(({ enqueueNotification }) => enqueueNotification)

  const [{ data: updateProductData, error: updateProductError, fetching: updateProductFetching }, updateProduct] =
    useMutation(ProductUpdateDocument)

  const { shippingLocations, companyLocations, countries } = useLoaderData({
    from: "/product/$productId/edit/_layout/information",
  })
  const { data: productOrigin, fetch: fetchProductOrigin } = useProductOrigin({ productId: Number(productId) })

  const handleCloseOriginDialog = useCallback(() => {
    setDialogOpen(false)
  }, [])

  const handleOpenOriginDialog = useCallback(() => {
    setDialogOpen(true)
  }, [])

  const onDialogSubmit = useCallback(
    (data: {
      shippingOrigin: string
      country?: string | null
      city?: string | null
      supplierLocation?: string | null
    }) => {
      updateProduct({
        input: {
          productId: Number(productId),
          locationsAssociations: [{ locationAssociationId: data.shippingOrigin }],
          cityOfOrigin: data.city ?? null,
          countryOfOrigin: data.country ?? null,
          locationOriginId: data.supplierLocation || null,
        },
      })
    },
    [productId, updateProduct]
  )

  useEffect(() => {
    if (!updateProductData) return

    if (!updateProductFetching && updateProductData?.product.update.__typename === "ProductUpdateSuccess") {
      fetchProductOrigin({ productId: Number(productId) })
      notify({
        message: "Product origin updated successfully",
        type: "success",
      })
    }

    if (
      !updateProductFetching &&
      (updateProductData?.product.update.__typename === "ProductUpdateFailure" || updateProductError)
    ) {
      notify({
        message: "Error updating product origin",
        type: "error",
      })
    }
  }, [updateProductData, updateProductFetching, updateProductError, productId])

  const hasOrigin = useMemo(
    () => (productOrigin?.locationsAssociations?.length ?? 0) > 0,
    [productOrigin?.locationsAssociations]
  )

  const initialData = useMemo(() => {
    if (!hasOrigin) return null
    return {
      shippingOrigin: productOrigin?.locationsAssociations[0]?.locationAssociationId || "",
      supplierLocation: productOrigin?.locationOriginId || "",
      country: productOrigin?.countryOfOrigin?.isoCode || "",
      city: productOrigin?.cityOfOrigin || "",
    }
  }, [productOrigin, hasOrigin])

  const [expanded, setExpanded] = useState(true)

  return (
    <>
      <ProductOriginDialog
        open={dialogOpen}
        onClose={handleCloseOriginDialog}
        onSubmit={onDialogSubmit}
        countries={countries}
        initialData={initialData}
        shippingOriginLocations={shippingLocations}
        companyManfucaturingOriginLocations={companyLocations}
        data-testid='product-origin-dialog'
      />
      <Paper className='border border-gray-300 p-6' elevation={0} data-testid='product-origin-container'>
        <Box className='flex items-center justify-between'>
          <Typography variant='h6' className='mb-6' data-testid='product-origin-title'>
            Product Origin
          </Typography>
          <IconButton onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label='show more'>
            {expanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
          </IconButton>
        </Box>
        <Collapse in={expanded}>
          {hasOrigin ? (
            <ProductOriginGrid
              shippingOrigin={{
                address: productOrigin?.locationsAssociations[0]?.shipping?.address1 ?? "",
                country: productOrigin?.locationsAssociations[0]?.shipping?.country?.name ?? "",
                city: productOrigin?.locationsAssociations[0]?.shipping?.city || "",
              }}
              manufacturingOrigin={{
                country: productOrigin?.countryOfOrigin?.name || "",
                city: productOrigin?.cityOfOrigin || "",
              }}
              onEdit={handleOpenOriginDialog}
              data-testid='product-origin-grid'
            />
          ) : (
            <ProductOriginAlert onAdd={handleOpenOriginDialog} />
          )}
        </Collapse>
      </Paper>
    </>
  )
}
