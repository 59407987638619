import { z } from "zod"

function beautifyZodError(error: z.ZodError): string {
  return error.errors
    .map((err) => {
      const { path, message } = err
      return `
----------------------------------------
⚠️ Validation Error:
----------------------------------------
- Field:       ${path.join(" > ")}
- Message:     ${message}

💡 Suggested Fix:
Please check your .env files and ensure that the variable "${path.join(" > ")}" is defined and correctly mapped to the expected type in the validation schema.
----------------------------------------
`.trim()
    })
    .join("\n\n")
}

// Define the schema as an object with all the env variables and their types
const envSchema = z.object({
  VITE_PORT: z.coerce.number().min(1000),
  VITE_LOG_LEVELS: z.string().refine(
    (val) => {
      const levels = ["trace", "debug", "info", "warn", "error"]
      return val.split(",").every((level) => levels.includes(level))
    },
    { message: "Invalid log levels. Allowed values: trace, debug, info, warn, error." }
  ),

  VITE_FEATURE_FLAGS: z.string().refine(
    (val) => {
      const flags = ["ENABLE_DARK_MODE", "ENABLE_FOO_BAR"]
      return val.split(",").every((flag) => flags.includes(flag))
    },
    { message: "Invalid feature flags. Allowed values: ENABLE_DARK_MODE, ENABLE_FOO_BAR." }
  ),
  VITE_GRAPHQL_SCHEMA_PATH: z.string(),
  VITE_API_ORIGIN: z.string().url(),
  VITE_AWS_REGION: z
    .string()
    .regex(/^us-east-\d$/, { message: "Invalid AWS region. Expected format: us-east-X where X is a digit." }),
  VITE_USER_POOL_ID: z
    .string()
    .regex(/^us-east-\d_[A-Za-z0-9]{9,}$/, { message: "Invalid User Pool ID. Expected format: us-east-X_XXXXXXXXX." }),
  VITE_APP_CLIENT_ID: z.string().min(10, "App Client ID must be at least 10 characters."),
  VITE_COGNITO_DOMAIN: z.string().regex(/^[a-z0-9-]+\.auth\.us-east-2\.amazoncognito\.com$/, {
    message: "Invalid Cognito domain. Expected format: {name}.auth.us-east-2.amazoncognito.com.",
  }),
  VITE_REDIRECT_SIGN_IN: z.string().url(),
  VITE_REDIRECT_SIGN_OUT: z.string().url(),
  VITE_OAUTH_SCOPES: z.string().refine(
    (val) => {
      const scopes = ["email", "openid", "profile"]
      return val.split(" ").every((scope) => scopes.includes(scope))
    },
    { message: "Invalid OAuth scopes. Allowed values: email, openid, profile." }
  ),
  VITE_RESPONSE_TYPE: z.literal("code"),
  VITE_GRANT_TYPE: z.literal("authorization_code"),
  VITE_ENV: z.union([z.literal("development"), z.literal("staging"), z.literal("prod")]).default("development"),
  VITE_MAX_NOTIFICATION_STACK: z.coerce.number().max(10).default(3),
})

let env: Environment

try {
  env = envSchema.parse(import.meta.env)
} catch (e) {
  if (e instanceof z.ZodError) {
    console.error(beautifyZodError(e))
    throw new Error("Environment variable validation error. Please check the console for details.")
  } else {
    throw e // Rethrow if it's not a ZodError
  }
}

// Declare the types
export type Environment = z.infer<typeof envSchema>

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface ImportMetaEnv extends z.infer<typeof envSchema> {}
}

// Export the result, so we can use it in the project
export default env
