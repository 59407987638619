import { useMemo } from "react"

import type { ProductSelectionItem } from "../../Supplier/types/types"

import { useGetPurchaseOrderIncotermsLocations } from "./useGetPurchaseOrderIncotermsLocations"
import { useGetPurchaseOrderShipToLocation } from "./useGetPurchaseOrderShipToLocation"

import { type Company, type FreightTerms, type Incoterms, type Location } from "@/graphql/codegen/graphql"
import type { LocationAssociation } from "@/screens/Products/components/ProductShipLocationSelect"
import { useGetPricingPackage } from "@/screens/Products/hooks/useGetPricingPackage"

type UseGetStartPurchaseOrderDataReturn = {
  shipToLocations: LocationAssociation[]
  shipToLocationListFetching: boolean
  loadMore: () => void
  freightTerms: FreightTerms | undefined
  incoterms: Incoterms | undefined
  incotermsLocation: Location | undefined
  pricingListFetching: boolean
}

// Facade hook to facilitate fetching pricing data for the purchase order when location association is not present
const usePricingData = (selectedCompany: Company, selectedProducts: ProductSelectionItem[]) => {
  const locationAssociationId = selectedCompany?.locationsAssociations?.[0]?.companyLocationAssociationId

  const { pricingList, pricingListFetching } = useGetPricingPackage({
    filterSpecs: {
      companyId: selectedCompany?.companyId ?? null,
      productId: selectedProducts[0]?.productId ?? null,
      sourceCompanyLocationAssociationId: locationAssociationId ?? "",
      effectiveNow: true,
      effectiveDateRange: null,
    },
  })

  if (!locationAssociationId) {
    return {
      pricingList: { currentPricing: undefined, futurePricing: [] },
      pricingListFetching,
    }
  }

  return { pricingList, pricingListFetching }
}

export const useGetStartPurchaseOrderData = ({
  selectedProducts,
  selectedCompany,
}: {
  selectedProducts: ProductSelectionItem[]
  selectedCompany: Company
}): UseGetStartPurchaseOrderDataReturn => {
  const { shipToLocations, shipToLocationListFetching, loadMore } = useGetPurchaseOrderShipToLocation()
  const { locations: incotermLocations } = useGetPurchaseOrderIncotermsLocations()

  const { pricingList, pricingListFetching } = usePricingData(selectedCompany, selectedProducts)

  const freightTerms = useMemo(
    () => pricingList.currentPricing?.freightTerms,
    [pricingList.currentPricing?.freightTerms]
  )
  const incoterms = useMemo(() => pricingList.currentPricing?.incoterms, [pricingList.currentPricing?.incoterms])

  const incotermsLocation = useMemo(
    () => incotermLocations.find(({ locationId }) => locationId === pricingList.currentPricing?.incotermsLocationId),
    [incotermLocations]
  )
  return {
    shipToLocations,
    shipToLocationListFetching,
    loadMore,
    pricingListFetching,
    incotermsLocation,
    freightTerms,
    incoterms,
  }
}
