import type { AnyRoute } from "@tanstack/react-router"
import { createFileRoute, retainSearchParams } from "@tanstack/react-router"
import { fallback } from "@tanstack/zod-adapter"
import { z } from "zod"

import { ProductSortField, SortingOrder } from "@/graphql/codegen/graphql"
import ProductContainer from "@/screens/Products/components/ProductContainer"

const SearchParamsValidator = z.object({
  query: fallback(z.string().optional().default(""), ""),
  field: fallback(z.nativeEnum(ProductSortField), ProductSortField.CreatedOn),
  order: fallback(z.nativeEnum(SortingOrder), SortingOrder.Desc),
  category: fallback(z.string().optional(), ""),
})

export const Route: AnyRoute = createFileRoute("/companies/$companyId/edit/_layout/products")({
  validateSearch: SearchParamsValidator,
  search: {
    middlewares: [retainSearchParams(["query", "field", "order", "category"])],
  },
  component: () => <ProductContainer />,
})
