import "@/styles/index.css"

import { ThemeProvider } from "@mui/material"
import StyledEngineProvider from "@mui/material/StyledEngineProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { StrictMode } from "react"
import ReactDOM from "react-dom/client"

import { DragDropProvider } from "@/providers/DragDropProvider"
import { GraphqlRouterProvider } from "@/providers/GraphqlRouterProvider"
import theme from "@/styles/mui-theme.ts"

// Render the app
const rootElement = document.getElementById("root")!
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DragDropProvider>
              <GraphqlRouterProvider />
            </DragDropProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StrictMode>
  )
}
