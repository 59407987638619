import { IconButton } from "@mui/material"
import { VisibilityOutlined } from "@mui-symbols-material/w300"
import { useNavigate } from "@tanstack/react-router"
import type { CellContext, ColumnDef } from "@tanstack/react-table"
import { useCallback, useMemo } from "react"
import type { ReactElement } from "react"

import Chip from "@/components/common/Chip/Chip.tsx"
import LimitedSizeChipGroup from "@/components/common/Chip/LimitedSizeChipGroup.tsx"
import { StatusIndicator } from "@/components/common/StatusIndicator/StatusIndicator.tsx"
import type { AccountManager, Company } from "@/graphql/codegen/graphql.ts"
import { CompanySortField, CompanyStatus } from "@/graphql/codegen/graphql.ts"
import { AccountManagersCell } from "@/screens/Companies/components/tables/companies.accountManager.tsx"
import { useCompanyTypesStore } from "@/screens/Companies/stores/useCompanyTypesStore"
import { findCompanyType } from "@/screens/Companies/utils/findCompanyType.ts"
import { createStatusMap } from "@/utils/statusMap.ts"

export const companyStatusMap = createStatusMap<
  CompanyStatus,
  "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning"
>([
  [CompanyStatus.Active, "success"],
  [CompanyStatus.Pending, "warning"],
  [CompanyStatus.Rejected, "error"],
  [CompanyStatus.Inactive, "secondary"],
])

export type SortableAccessorKey = "accountManagers" | "types" | "name"

export const accessorKeyToCompanySortField: ReadonlyMap<SortableAccessorKey, CompanySortField> = new Map([
  ["accountManagers", CompanySortField.AccountManager],
  ["types", CompanySortField.CompanyType],
  ["name", CompanySortField.Name],
])

export const companySortFieldToAccessoryKey: ReadonlyMap<CompanySortField, SortableAccessorKey> = new Map([
  [CompanySortField.AccountManager, "accountManagers"],
  [CompanySortField.CompanyType, "types"],
  [CompanySortField.Name, "name"],
])

const CompanyTypeCell = ({ types, maxSize }: { types: string[]; maxSize: number }): ReactElement => {
  const companyTypes = useCompanyTypesStore((state) => state.companyTypes)
  const getChipName = useCallback((type: string) => findCompanyType(companyTypes, type)?.name, [companyTypes])

  const chips = useMemo(() => types.map((type, index) => ({ id: `${type}-${index}`, name: type })), [types])

  return (
    <LimitedSizeChipGroup
      chips={chips}
      maxSize={maxSize}
      renderChip={(chip) => {
        const label = getChipName(chip.name)
        return (
          <Chip
            className='mr-2 rounded'
            key={chip.id}
            label={label}
            icon={chip?.icon}
            color='primary'
            style={{ maxWidth: chip?.maxWidth ?? "auto" }}
          />
        )
      }}
    />
  )
}

export const useCompanyColumns = (): ColumnDef<Company>[] => {
  const navigate = useNavigate()
  const handleCompanyRowClick = useCallback((companyId: string) => {
    void navigate({ from: "/companies", to: `${companyId}/edit` })
  }, [])

  return useMemo(
    () => [
      {
        accessorKey: "status",
        header: "",
        cell: ({ getValue, cell }: CellContext<Company, unknown>): ReactElement => (
          <StatusIndicator
            data-testid={`${cell.id}-${getValue()}`}
            status={getValue() as string}
            map={companyStatusMap}
          />
        ),
        size: 24,
        enableSorting: false,
      },
      {
        accessorKey: "name",
        header: "Company Name",
        sortingFn: "alphanumeric",
        enableSorting: true,
        size: 400,
      },
      {
        accessorKey: "types",
        header: "Company Type",
        cell: ({ getValue, cell }: CellContext<Company, unknown>): ReactElement => (
          <CompanyTypeCell types={getValue() as string[]} maxSize={cell.column.getSize()} />
        ),
        enableSorting: true,
        size: 400,
      },
      {
        accessorKey: "accountManagers",
        header: "Account Managers",
        cell: ({ getValue }: CellContext<Company, unknown>): ReactElement => (
          <AccountManagersCell accountManagers={getValue() as AccountManager[]} />
        ),
        sortingFn: "alphanumeric",
        enableSorting: true,
      },
      {
        accessorKey: "actions",
        header: "",
        cell: ({ row }) => (
          <IconButton
            onClick={() => handleCompanyRowClick(row.original.companyId)}
            onKeyUp={(e) => e.key === "Enter" && handleCompanyRowClick(row.original.companyId)}
          >
            <VisibilityOutlined className='block text-gray-600' />
          </IconButton>
        ),
        size: 100,
        enableSorting: false,
      },
    ],
    []
  )
}
