import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback, useState } from "react"
import { useFormContext } from "react-hook-form"

import { AddProductInformation } from "./AddProductInformation"
import { AddProductSpecifics } from "./AddProductSpecifics"

import { Button } from "@/components/common/Button"
import type { ProductUpdateInput } from "@/graphql/codegen/graphql"

type EditProductModalProps = {
  open: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: ProductUpdateInput) => void
}

const EditProductModal: FunctionComponent<EditProductModalProps> = ({ open, onClose, onSubmit }) => {
  const [step, setStep] = useState(0)
  const methods = useFormContext()

  const onNextStep = useCallback(() => {
    setStep((prevState) => prevState + 1)
  }, [])

  const onBackClick = useCallback(() => {
    setStep((prevState) => prevState - 1)
  }, [])

  const handleResetClick = useCallback(() => {
    methods.reset()
    setStep(0)
    onClose("cancel")
  }, [methods, onClose])

  const handleOnSubmit = useCallback(
    (value: ProductUpdateInput) => {
      onSubmit(value)
      setStep(0)
      onClose("success")
    },
    [onClose, onSubmit]
  )

  const isSpecificStepDisabled = !methods.getFieldState("classificationId").invalid

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        methods.reset()
        setStep(0)
        onClose(reason)
      }}
      fullWidth
    >
      <DialogTitle className='pl-10 font-normal text-primary'>Edit Product</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => {
          methods.reset()
          setStep(0)
          onClose("cancel")
        }}
        className='absolute right-4 top-4 text-primary'
      >
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        {step === 0 && <AddProductInformation />}
        {step === 1 && <AddProductSpecifics />}
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleResetClick}>
          Cancel
        </Button>
        {step === 0 && (
          <Button onClick={onNextStep} disabled={!methods.formState.isValid}>
            Continue
          </Button>
        )}
        {step === 1 && (
          <Box>
            <Button variant='primary' appearance='text' className='mr-6' onClick={onBackClick}>
              Go Back
            </Button>
            <Button disabled={!isSpecificStepDisabled} onClick={methods.handleSubmit(handleOnSubmit)}>
              Save
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default EditProductModal
