import { Box, CircularProgress, IconButton, Typography } from "@mui/material"
import { EditOutlined } from "@mui-symbols-material/w300"
import type { Row } from "@tanstack/react-table"
import type { ReactElement } from "react"

import { usePriceUomColumn } from "../hooks/usePriceUomColumn"

import type { OrderLine } from "@/graphql/codegen/graphql"

export const PriceUomColumn = ({
  row,
  onOpenPriceDialog,
}: {
  row: Row<OrderLine>
  onOpenPriceDialog?: (rowContent: OrderLine) => void
}): ReactElement => {
  const { convertedUomValue, currentPricing, total, fetching } = usePriceUomColumn({ row })

  return (
    <Box className='gap-2'>
      {fetching ? (
        <Box className='flex w-full justify-center'>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div className='flex gap-2'>
            <Typography variant='h6' className='font-bold text-gray-700'>
              {currentPricing}
            </Typography>
            {onOpenPriceDialog && (
              <IconButton
                color='primary'
                className='p-0'
                size='small'
                aria-label='edit price per unit of measurement'
                onClick={() => onOpenPriceDialog(row.original)}
              >
                <EditOutlined />
              </IconButton>
            )}
          </div>
          <Typography variant='caption' className='pt-2 text-gray-700'>
            {convertedUomValue}
          </Typography>
          <Typography variant='subtitle2' className='mt-3 text-gray-700'>
            Total: ${total}
          </Typography>
        </>
      )}
    </Box>
  )
}
