import type { StoreApi } from "zustand"
import { createStore } from "zustand"

export interface PDFState {
  url: string | null
  isGenerating: boolean
  lastUpdateTime: number | null
  error: string | null
  pendingUpdate: boolean
  setGenerating: (isGenerating: boolean) => void
  setURL: (url: string | null) => void
  setError: (error: string | null) => void
  setPendingUpdate: (pending: boolean) => void
  reset: () => void
}

export const createPDFStore = (): StoreApi<PDFState> =>
  createStore<PDFState>((set, get) => ({
    url: null,
    isGenerating: false,
    lastUpdateTime: null,
    error: null,
    pendingUpdate: false,
    setGenerating: (isGenerating) => {
      if (get().isGenerating !== isGenerating) {
        set({ isGenerating })
      }
    },
    setURL: (url) => {
      if (get().url !== url) {
        set({
          url,
          isGenerating: false,
          pendingUpdate: false,
        })
      }
    },
    setPendingUpdate: (pending) => {
      if (get().pendingUpdate !== pending) {
        set({
          pendingUpdate: pending,
          isGenerating: pending,
        })
      }
    },
    setError: (error) =>
      set({
        error,
        isGenerating: false,
        pendingUpdate: false,
      }),
    reset: () =>
      set({
        url: null,
        isGenerating: false,
        lastUpdateTime: null,
        error: null,
        pendingUpdate: false,
      }),
  }))
