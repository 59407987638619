import { Box, CircularProgress } from "@mui/material"
import Drawer from "@mui/material/Drawer"
import Toolbar from "@mui/material/Toolbar"
import { Outlet, createRootRoute, useLocation } from "@tanstack/react-router"
import { jwtDecode } from "jwt-decode"
import { useEffect } from "react"
import { checkAuthStatus } from "shared/services/auth.service"
import { twMerge } from "tailwind-merge"

import AppHeader from "@/components/common/Header/AppHeader"
import { Notifications } from "@/components/common/Notifications/Notifications"
import SideNav from "@/components/SideNav/SideNav"
import { useAuthStore } from "@/stores/authStore"
import theme from "@/styles/mui-theme"
import type { User } from "@/types/user/user.schema"
import { UserSchema } from "@/types/user/user.schema"
import { isPublicRoute } from "@/utils/auth/routeUtils"

export const Route = createRootRoute({
  component: RootComponent,
  // Verify auth status before any route is rendered
  beforeLoad: async ({ location }) => {
    const store = useAuthStore.getState()

    // Skip auth check for public routes
    if (isPublicRoute(location.pathname)) {
      return null
    }

    // For all other routes, check auth status
    const isValid = await checkAuthStatus()
    if (!isValid) {
      store.signIn() // Redirect to Cognito if not authenticated
      return null
    }

    return null
  },
})

function RootComponent() {
  const { status, user: currentUser, tokens, initializeAuth } = useAuthStore()
  const location = useLocation()
  const isPublic = isPublicRoute(location.pathname)

  useEffect(() => {
    initializeAuth()
  }, [initializeAuth])

  // Check auth when access token is about to expire
  useEffect(() => {
    if (!tokens.accessToken) return

    const timeUntilExpiry = getTimeUntilExpiry(tokens.accessToken)
    if (timeUntilExpiry <= 0) return

    const timeout = setTimeout(
      () => {
        checkAuthStatus()
      },
      Math.max(0, timeUntilExpiry - 60000)
    )

    return () => clearTimeout(timeout)
  }, [tokens.accessToken])

  const user: User = UserSchema.parse({
    id: currentUser?.id ?? "",
    name: currentUser?.fullName ?? "",
    email: currentUser?.email ?? "",
    avatar: currentUser?.avatarUrl ?? "",
  })

  // Only show loading for initial auth check
  if (status === "loading") {
    return (
      <Box className='flex h-screen items-center justify-center'>
        <CircularProgress
          size={48}
          className='transition-opacity duration-300 ease-in-out'
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      </Box>
    )
  }

  // Public routes don't show the app shell
  if (isPublic) {
    return <Outlet />
  }

  return (
    <div className='flex h-screen bg-gray-100'>
      <AppHeader
        className={twMerge([`z-[${theme.zIndex.drawer + 1}]`, "bg-transparent p-6"])}
        user={user}
        notifications={0}
        breadcrumbs={[]}
        pathname={location.pathname}
      />
      <Drawer
        classes={{ paperAnchorLeft: "border-0 pl-6 pr-4", paper: "bg-transparent" }}
        sx={{
          width: 272,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 272,
            boxSizing: "border-box",
          },
        }}
        variant='permanent'
        anchor='left'
      >
        <Toolbar className='h-28 py-6' />
        <SideNav />
      </Drawer>
      <main className='flex grow flex-col overflow-hidden bg-transparent'>
        <Toolbar className='h-28 py-6' />
        <Box className='flex flex-1 overflow-y-auto'>
          <Outlet />
        </Box>
      </main>
      <Notifications />
    </div>
  )
}

// Helper function to get milliseconds until token expiry
function getTimeUntilExpiry(token: string): number {
  try {
    const { exp } = jwtDecode<{ exp: number }>(token)
    if (!exp) return 0
    return exp * 1000 - Date.now()
  } catch {
    return 0
  }
}
