import { CompanyStatus } from "@/graphql/codegen/graphql"

/**
 * The color of the company based on its status.
 */
type CompanyStatusColor = "success" | "error" | "warning"

/**
 * Get the color of the company based on its status.
 * @param status The status of the company which is CompanyStatus enum
 * @returns The color of the company based on its status
 */
export const getCompanyStatusColor = (status: CompanyStatus): CompanyStatusColor => {
  switch (status) {
    case CompanyStatus.Active:
      return "success"
    case CompanyStatus.Inactive:
      return "error"
    default:
      return "warning"
  }
}
