import { useCallback, useEffect, useMemo, useState } from "react"
import type { CombinedError, OperationContext, UseMutationExecute } from "urql"
import { useMutation, useQuery } from "urql"

import type { Location } from "@/graphql/codegen/graphql"
import {
  CompanyListBasicDocument,
  CompanyLocationAssociationListDocument,
  CreateNewCompanyLocationAssociationMutationDocument,
  EditCompanyLocationAssociationMutationDocument,
  LocationListQueryDocument,
} from "@/graphql/codegen/graphql.ts"
import type {
  Company,
  CompanyListSuccess,
  CompanyLocationAssociationListSuccess,
  CompanyLocationAssociationMutationCreateArgs,
  CompanyLocationAssociationMutationUpdateArgs,
  CompanyLocationAssociationOutput,
  LocationListSuccess,
  LocationQueryListArgs,
  Mutation,
  Query,
} from "@/graphql/codegen/graphql.ts"

const LIMIT = 20
const OFFSET = 0

export const useGetAssociatedLocations = ({
  companyId,
}: {
  companyId: string
}): {
  loadMore: () => void
  resetList: () => void
  reexecuteQueryWithReset: () => void
  reexecuteCompanyNameQuery: (opts?: Partial<OperationContext>) => void
  locations: Location[]
  fetching: boolean
  error: CombinedError | undefined
  companyNameList: Company[]
  companyNameListFetching: boolean
  createCompanyLocationAssociation:
    | UseMutationExecute<Pick<Mutation, "companyLocationAssociation">, CompanyLocationAssociationMutationCreateArgs>
    | undefined
  updateCompanyLocationAssociation:
    | UseMutationExecute<Pick<Mutation, "companyLocationAssociation">, CompanyLocationAssociationMutationUpdateArgs>
    | undefined
  associatedLocationList: CompanyLocationAssociationOutput[]
  associatedLocationListFetching: boolean
  reexecuteAssociatedLocationQuery: (opts?: Partial<OperationContext>) => void
} => {
  const [offset, setOffset] = useState(0)
  const [locationList, setLocationList] = useState<Location[]>([])
  const [companyNameList, setCompanyNameList] = useState<Company[]>([])

  const [
    { data: companyLocationsData, fetching: companyLocationsFetching, error: companyLocationsError },
    reexecuteCompanyLocationsQuery,
  ] = useQuery<Pick<Query, "location">, LocationQueryListArgs>({
    query: LocationListQueryDocument,
    variables: {
      input: { filter: { companyId }, limit: LIMIT, offset },
    },
  })

  const [{ data: companyNameListData, fetching: companyNameListFetching }, reexecuteCompanyNameQuery] = useQuery<
    Pick<Query, "company">
  >({
    query: CompanyListBasicDocument,
    variables: { filter: { types: ["TRANSPORTATION"] }, limit: LIMIT, offset },
  })

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  const resetList = useCallback(() => {
    setOffset(0)
    setLocationList([])
    setCompanyNameList([])
  }, [])

  const [
    { data: associatedLocationListData, fetching: associatedLocationListFetching },
    reexecuteAssociatedLocationQuery,
  ] = useQuery<Pick<Query, "companyLocationAssociation">>({
    query: CompanyLocationAssociationListDocument,
    variables: { input: { filter: { companyId }, limit: LIMIT, offset: OFFSET } },
  })

  // Memoize the location list
  const associatedLocationList = useMemo(() => {
    return (
      (associatedLocationListData?.companyLocationAssociation.list as CompanyLocationAssociationListSuccess)
        ?.companyLocationAssociations || []
    )
  }, [associatedLocationListData])

  // Mutation to create a new company associated location
  const [, createCompanyLocationAssociation] = useMutation<
    Pick<Mutation, "companyLocationAssociation">,
    CompanyLocationAssociationMutationCreateArgs
  >(CreateNewCompanyLocationAssociationMutationDocument)

  // Mutation to edit associated location
  const [, updateCompanyLocationAssociation] = useMutation<
    Pick<Mutation, "companyLocationAssociation">,
    CompanyLocationAssociationMutationUpdateArgs
  >(EditCompanyLocationAssociationMutationDocument)

  useEffect(() => {
    if (companyNameListData) {
      const companyList = (companyNameListData?.company.list as CompanyListSuccess)?.companies || []
      setCompanyNameList((prev) => (offset === 0 ? companyList : [...prev, ...companyList]))
    }
  }, [companyNameListData, offset])

  useEffect(() => {
    if (companyLocationsData) {
      const locations = (companyLocationsData.location.list as LocationListSuccess).locations
      setLocationList((prev) => (offset === 0 ? locations : [...prev, ...locations]))
    }
  }, [companyLocationsData, offset])

  const reexecuteQueryWithReset = useCallback(() => {
    reexecuteCompanyLocationsQuery({ requestPolicy: "network-only" })
  }, [reexecuteCompanyLocationsQuery])

  const reexecuteAssociatedLocationQueryWithReset = useCallback(() => {
    reexecuteAssociatedLocationQuery({ requestPolicy: "network-only" })
  }, [reexecuteAssociatedLocationQuery])

  return {
    locations: locationList,
    fetching: companyLocationsFetching,
    error: companyLocationsError,
    companyNameList,
    companyNameListFetching,
    reexecuteCompanyNameQuery,
    reexecuteQueryWithReset,
    createCompanyLocationAssociation,
    associatedLocationList,
    associatedLocationListFetching,
    reexecuteAssociatedLocationQuery: reexecuteAssociatedLocationQueryWithReset,
    loadMore,
    resetList,
    updateCompanyLocationAssociation,
  }
}
