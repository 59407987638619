import { Box } from "@mui/material"
import { createFileRoute } from "@tanstack/react-router"
import { useCallback, useState } from "react"

import { SupplierReportFilterDrawer } from "@/screens/Procurement/Supplier/components/Filters/SupplierReportFilterDrawer"
import { SupplierReportSearch } from "@/screens/Procurement/Supplier/components/SupplierReportSearch"
import { SupplierReportTable } from "@/screens/Procurement/Supplier/components/Table/SupplierReportTable"

const SupplierReport = () => {
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false)

  const toggleFilterDrawer = useCallback(() => setFilterDrawerOpen((prev) => !prev), [])

  return (
    <>
      <SupplierReportFilterDrawer showFilterDrawer={isFilterDrawerOpen} toggleDrawer={toggleFilterDrawer} />

      <SupplierReportSearch onShowFilter={toggleFilterDrawer} />

      <Box className='flex flex-1 flex-col overflow-y-auto'>
        <SupplierReportTable />
      </Box>
    </>
  )
}

export const Route = createFileRoute("/procurement/_layout/supplier-report")({
  component: SupplierReport,
})
