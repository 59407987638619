import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import type {
  ListPricingPackagesSuccess,
  PricingPackage,
  PricingPackageQueryableFields,
  PricingPackageSortingOptions,
  Query,
} from "@/graphql/codegen/graphql"
import { PricingPackageListQueryDocument } from "@/graphql/codegen/graphql"
import type { ClassifiedPricingPackages } from "@/screens/Procurement/PurchaseOrder/types"

const LIMIT = 20

export const useGetPricingPackage = ({
  filterSpecs,
  sortSpecs,
}: {
  filterSpecs: PricingPackageQueryableFields
  sortSpecs?: PricingPackageSortingOptions
}): {
  pricingListError: CombinedError | undefined
  pricingList: ClassifiedPricingPackages
  pricingListFetching: boolean
  loadMore: () => void
  reexecuteQueryWithReset: () => void
} => {
  const [offset, setOffset] = useState(0)
  const [pricingData, setPricingData] = useState<{
    currentPricing: PricingPackage | undefined
    futurePricing: PricingPackage[]
  }>({
    currentPricing: undefined,
    futurePricing: [],
  })
  const { productId, companyId, sourceCompanyLocationAssociationId } = filterSpecs ?? {}

  const [
    { data: currentPricingList, fetching: currentPricingListFetching, error: currentPricingListError },
    reexecuteCurrentPricingListQuery,
  ] = useQuery<Pick<Query, "pricingPackage">>({
    query: PricingPackageListQueryDocument,
    variables: {
      input: {
        filter: {
          ...filterSpecs,
          effectiveNow: true,
        },
        sort: sortSpecs,
        limit: LIMIT,
        offset,
      },
    },
    pause: !productId && !companyId && !sourceCompanyLocationAssociationId,
  })

  const [
    { data: pricingListDetails, fetching: pricingListFetching, error: pricingListError },
    reexecuteSelectedPricingListQuery,
  ] = useQuery<Pick<Query, "pricingPackage">>({
    query: PricingPackageListQueryDocument,
    variables: {
      input: {
        filter: {
          ...filterSpecs,
          effectiveDateRange: {
            startDate: dayjs().add(1, "day").format("MM/DD/YYYY"),
          },
        },
        sort: sortSpecs,
        limit: LIMIT,
        offset,
      },
    },
    pause: !productId && !companyId && !sourceCompanyLocationAssociationId,
  })

  const reexecuteQueryWithReset = useCallback(() => {
    reexecuteCurrentPricingListQuery({ requestPolicy: "network-only" })
    reexecuteSelectedPricingListQuery({ requestPolicy: "network-only" })
  }, [reexecuteCurrentPricingListQuery, reexecuteSelectedPricingListQuery])

  useEffect(() => {
    if (pricingListDetails && currentPricingList) {
      const currentPricingPackagesList =
        (currentPricingList?.pricingPackage?.list as ListPricingPackagesSuccess).pricingPackages || []
      const futurePricingPackageList =
        (pricingListDetails?.pricingPackage?.list as ListPricingPackagesSuccess).pricingPackages || []

      setPricingData((prev) => ({
        ...prev,
        currentPricing: currentPricingPackagesList[0],
        futurePricing: futurePricingPackageList,
      }))
    }
  }, [offset, pricingListDetails, currentPricingList])

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  return {
    pricingList: pricingData,
    pricingListFetching: currentPricingListFetching || pricingListFetching,
    pricingListError: currentPricingListError || pricingListError,
    loadMore,
    reexecuteQueryWithReset,
  }
}
