import { Box, Tooltip } from "@mui/material"
import type { Cell, Row } from "@tanstack/react-table"
import { flexRender } from "@tanstack/react-table"
import React from "react"

import type { Company } from "@/graphql/codegen/graphql"

interface SupplierReportRowProps {
  row: Row<Company>
  isExpanded: boolean
  getFlexibleColumnWidth: (cell: any) => string
  children?: React.ReactNode
}

const ROW_HEIGHT = 64

export const SupplierReportProductTableRow: React.FC<SupplierReportRowProps> = ({
  row,
  isExpanded,
  getFlexibleColumnWidth,
  children,
}) => {
  return (
    <Box className='flex w-full flex-col'>
      <Box
        className={`grid items-center border-b border-gray-200 text-gray-700 ${isExpanded ? "bg-primary-100" : "bg-white"}`}
        style={{
          gridTemplateColumns: row
            .getVisibleCells()
            .map((cell) => getFlexibleColumnWidth({ column: cell.column }))
            .join(" "),
          height: ROW_HEIGHT,
        }}
      >
        {row.getVisibleCells().map((cell: Cell<Company, unknown>) =>
          cell.column.id === "status" ? (
            <Box key={cell.id} className='flex w-full items-center justify-center px-2'>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Box>
          ) : (
            <Box key={cell.id} className='flex w-full items-center overflow-hidden px-2'>
              <Tooltip title={cell.getValue() as string} enterDelay={1000}>
                <Box className='w-full truncate'>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box>
              </Tooltip>
            </Box>
          )
        )}
      </Box>
      {isExpanded && <Box className='box-border overflow-hidden bg-white p-2'>{children}</Box>}
    </Box>
  )
}
