import { Box, IconButton } from "@mui/material"
import { KeyboardArrowDownOutlined, KeyboardArrowUpFilledOutlined, MoreVertOutlined } from "@mui-symbols-material/w300"
import type { Row } from "@tanstack/react-table"
import { type ReactElement, useRef, useState } from "react"

import type { Order } from "@/graphql/codegen/graphql"
import PurchaseOrderDropdownMenu from "@/screens/Procurement/PurchaseOrders/components/PurchaseOrderDropdownMenu.tsx"

export const PurchaseOrderActions = ({ row }: { row: Row<Order> }): ReactElement => {
  const anchorElRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Box className='flex items-center justify-end space-x-4'>
      <Box className='flex items-center justify-end' ref={anchorElRef}>
        <IconButton onClick={handleOpen}>
          <MoreVertOutlined />
        </IconButton>
        <PurchaseOrderDropdownMenu open={open} anchorEl={anchorElRef.current} onClose={handleClose} />
      </Box>
      <IconButton aria-label='expand row' size='small' onClick={() => row.toggleExpanded()} data-testid='expand-button'>
        {row.getIsExpanded() ? <KeyboardArrowUpFilledOutlined /> : <KeyboardArrowDownOutlined />}
      </IconButton>
    </Box>
  )
}
