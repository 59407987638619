import { Box, Divider, Typography } from "@mui/material"
import { useNavigate, useSearch } from "@tanstack/react-router"
import type { FC } from "react"
import { useCallback } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { AerosDatePicker } from "@/components/common/DatePicker/AerosDatePicker"
import { StatusFilter } from "@/components/common/Filters/StatusFilter"
import type { DateRange, OrderListFilter } from "@/graphql/codegen/graphql.ts"
import { OrderListFilterSchema, OrderStatus, ShipStatus } from "@/graphql/codegen/graphql.ts"
import { getRefinedSearchParams } from "@/screens/Companies/utils.ts"

const orderStatusList = Object.values(OrderStatus)
// const orderStatusKey = "orderStatuses"
const shippingStatusList = Object.values(ShipStatus)
// const shippingStatusKey = "shippingStatuses"

const orderStatusLabelLookup = {
  [OrderStatus.Cancelled]: "Cancelled",
  [OrderStatus.Completed]: "Completed",
  [OrderStatus.Confirmed]: "Confirmed",
  [OrderStatus.Draft]: "Draft",
  [OrderStatus.Sent]: "Sent",
}

const shippingStatusLabelLookup = {
  [ShipStatus.Delivered]: "Delivered",
  [ShipStatus.InTransit]: "In Transit",
  [ShipStatus.PartiallyDelivered]: "Partially Delivered",
  [ShipStatus.Pending]: "Pending",
}

type PurchaseOrdersFilterProps = {
  routePath: string
}

export const PurchaseOrdersFilter: FC<PurchaseOrdersFilterProps> = ({ routePath }) => {
  const navigate = useNavigate({ from: routePath })
  const currentSearch = useSearch({ from: routePath })

  const { statuses, shipStatuses, start, end } = currentSearch

  const methods = useForm<OrderListFilter>({
    values: {
      statuses,
      shipStatuses,
    },
    defaultValues: OrderListFilterSchema().parse({
      statuses: [],
      shipStatuses: [],
    }),
  })

  const handleFilterChange = useCallback(
    (name: keyof OrderListFilter | keyof DateRange, value: any) => {
      const params = getRefinedSearchParams(currentSearch, {
        ...currentSearch,
        [name]: value,
      })

      void navigate(params)
    },
    [currentSearch, navigate]
  )

  return (
    <FormProvider {...methods}>
      <Box className='flex h-full flex-col gap-y-2'>
        <Box className='flex-none'>
          <Typography variant='subtitle1' fontWeight={700} className='px-4 py-1.5 text-gray-700'>
            Company Status
          </Typography>
          <Divider />
          <StatusFilter
            name='statuses'
            data={orderStatusList}
            control={methods.control}
            onFilterChange={handleFilterChange}
            labelMap={orderStatusLabelLookup}
          />
        </Box>
        {/* Shipping Status */}
        <Box className='flex-none'>
          <Typography variant='subtitle1' fontWeight={700} className='px-4 py-1.5 text-gray-700'>
            Shipping Status
          </Typography>
          <Divider />
          <StatusFilter
            name='shipStatuses'
            data={shippingStatusList}
            control={methods.control}
            onFilterChange={handleFilterChange}
            labelMap={shippingStatusLabelLookup}
          />
        </Box>
        <Box className='flex-none'>
          <Typography variant='subtitle1' fontWeight={700} className='px-4 py-1.5 text-gray-700'>
            PO Sent Date Range
          </Typography>
          <Box className='flex gap-x-2 px-4'>
            <Box>
              <AerosDatePicker
                label='From'
                required
                name='poSentStartDate'
                value={start}
                onChange={(date) => handleFilterChange("start", date)}
              />
            </Box>
            <Box>
              <AerosDatePicker
                label='To'
                required
                name='poSentEndDate'
                value={end}
                onChange={(date) => handleFilterChange("end", date)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  )
}
