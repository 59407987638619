import { useNavigate, useSearch } from "@tanstack/react-router"
import type { FC } from "react"
import { useCallback } from "react"

import { SearchFilterDrawer } from "@/components/common/SearchFilterDrawer/SearchFilterDrawer.tsx"
import { getRefinedSearchParams } from "@/screens/Companies/utils.ts"
import { SupplierReportFilter } from "@/screens/Procurement/Supplier/components/Filters/SupplierReportFilter"
import { SupplierReportFilterSchema } from "@/screens/Procurement/Supplier/types/supplierReportFilter"

type SupplierReportSearchFilterProps = {
  showFilterDrawer: boolean
  toggleDrawer: () => void
}

export const SupplierReportFilterDrawer: FC<SupplierReportSearchFilterProps> = ({ showFilterDrawer, toggleDrawer }) => {
  const navigate = useNavigate({ from: "/procurement/_layout/supplier-report" })
  const currentSearch = useSearch({
    from: "/procurement/_layout/supplier-report",
    strict: true,
  })

  const handleClearFilters = useCallback(() => {
    const {
      search: { query, ...rest },
      replace,
    } = getRefinedSearchParams(currentSearch, SupplierReportFilterSchema.parse({}))
    void navigate({ search: { ...rest, query: currentSearch?.query }, replace })
  }, [currentSearch, navigate])

  return (
    <SearchFilterDrawer toggleDrawer={toggleDrawer} open={showFilterDrawer} clear={handleClearFilters}>
      <SupplierReportFilter />
    </SearchFilterDrawer>
  )
}
