import { z } from "zod"

import { ApplicableToEnum, IncludeWithEnum, TransportationModeEnum } from "@/graphql/codegen/graphql"

export const AddInstructionsSchema = z.object({
  applicableTo: z.array(
    z.nativeEnum(ApplicableToEnum, {
      errorMap: () => ({ message: "Select applicable to" }),
    })
  ),
  transportationMode: z.array(
    z.nativeEnum(TransportationModeEnum, {
      errorMap: () => ({ message: "Select transportation mode" }),
    })
  ),
  includedWith: z.array(
    z.nativeEnum(IncludeWithEnum, {
      errorMap: () => ({ message: "Select included with" }),
    })
  ),
  productInstructionId: z.array(z.string()).min(1, "Select instructions"),
})
