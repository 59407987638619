import type { Row } from "@tanstack/react-table"
import { useCallback } from "react"

import {
  useCurrentPO,
  useOrderLines,
  useUpdateModifiedFields,
  useUpdateSectionFields,
} from "../../contexts/PurchaseOrderContext"
import { PurchaseOrderSection } from "../../types"

import type { OrderLine, OrderLineInput, OrderUpdateInput } from "@/graphql/codegen/graphql"
import { usePurchaseOrder } from "@/screens/Procurement/hooks/usePurchaseOrder"

interface UseActionsColumn {
  onConfirmCancelClick: () => void
}

export const useActionsColumn = ({ row }: { row: Row<OrderLine> }): UseActionsColumn => {
  const order = useCurrentPO()
  const orderLines = useOrderLines()
  const updateSectionFields = useUpdateSectionFields()
  const updateModifiedFields = useUpdateModifiedFields()

  const { updatePO } = usePurchaseOrder()

  const onConfirmCancelClick = useCallback(() => {
    if (!orderLines) return
    const removeSelectedOrderLine: OrderLineInput[] = (orderLines ?? []).filter(
      ({ productId }) => productId !== row.original.productId
    )

    const formatOrderLine: OrderLineInput[] = removeSelectedOrderLine.map((orderLine) => ({
      productId: parseInt(orderLine.productId.toString()),
      quantityInUom: parseFloat(orderLine.quantityInUom.toString()),
      pricePerUom: parseFloat((orderLine.pricePerUom ?? 0).toString()),
      uomId: orderLine.uomId,
      numberOfPallets: parseInt((orderLine.numberOfPallets ?? 0).toString()),
      priceUomId: orderLine.priceUomId,
    }))

    updatePO({
      input: {
        orderId: order?.orderId ?? 0,
        orderLines: formatOrderLine,
      },
    })
    updateSectionFields(PurchaseOrderSection.ORDER_LINES, removeSelectedOrderLine)
    updateModifiedFields(PurchaseOrderSection.ORDER_LINES, removeSelectedOrderLine as Partial<OrderUpdateInput>)
  }, [order?.orderId, orderLines, row.original.productId, updateModifiedFields, updatePO, updateSectionFields])

  return {
    onConfirmCancelClick,
  }
}
