import { FormControl, MenuItem, Select } from "@mui/material"
import { HelpOutlined, KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import type { ReactNode } from "react"
import { memo, useCallback, useId, useMemo, useRef } from "react"
import type { ControllerFieldState, ControllerRenderProps } from "react-hook-form"

import { useScrollLoadMore } from "@/hooks/useScrollLoadMore"

const InputFieldClasses =
  "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2 pr-14 w-full text-ellipsis"

interface Option<T = string | number> {
  [key: string]: T | string | number
}

interface SelectFieldProps<T = any> {
  key?: string
  field: ControllerRenderProps<any, string>
  fieldState: ControllerFieldState
  options: Option<T>[]
  optionValueKey?: string
  optionLabelKey?: string
  formatOptionLabel?: (option: any) => ReactNode
  loadMore?: () => void
  fetching?: boolean
  disabled?: boolean
  placeholder?: string
}

const SelectFieldComponent = <T extends string | number>({
  key,
  field,
  fieldState,
  options = [],
  optionValueKey = "value",
  optionLabelKey = "label",
  formatOptionLabel,
  disabled = false,
  loadMore = () => {},
  fetching = false,
  placeholder = "Select One",
}: SelectFieldProps<T>): JSX.Element => {
  const id = useId()
  const uniqueKey = useRef(key || id)
  const selectRef = useRef<HTMLDivElement>(null)

  const onScroll = useScrollLoadMore({
    fetching,
    loadMore,
    scrollContainerRef: selectRef,
  })

  const isValidValue = useMemo(() => {
    if (!field.value) return true
    return options.some((opt) => opt[optionValueKey] === field.value)
  }, [field.value, options, optionValueKey])

  const renderValue = useCallback(
    (value: any) => {
      if (!value) return placeholder
      const selectedOption = options.find((opt) => opt[optionValueKey] === value)
      return selectedOption ? String(selectedOption[optionLabelKey]) : value
    },
    [options, optionLabelKey, optionValueKey, placeholder]
  )

  const renderOptionLabel = useCallback(
    (option: any) => {
      if (formatOptionLabel) {
        return formatOptionLabel(option)
      }
      return option[optionLabelKey]
    },
    [formatOptionLabel, optionLabelKey]
  )

  const menuProps = useMemo(
    () => ({
      PaperProps: {
        ref: selectRef,
        style: { maxHeight: 200 },
      },
    }),
    []
  )

  const slotProps = useMemo(
    () => ({
      input: { className: InputFieldClasses },
      root: { className: "pr-0" },
    }),
    []
  )

  const endAdornment = useMemo(() => <HelpOutlined fontSize='small' className='absolute right-8' />, [])

  const selectProps = useMemo(
    () => ({
      ...field,
      id: uniqueKey.current,
      onChange: field.onChange,
      value: isValidValue ? field.value || "" : "",
      displayEmpty: true,
      IconComponent: KeyboardArrowDownOutlined,
      renderValue,
      endAdornment,
      disabled,
      onScrollCapture: onScroll,
      MenuProps: menuProps,
      slotProps,
    }),
    [field, isValidValue, renderValue, endAdornment, disabled, onScroll, menuProps, slotProps]
  )

  const menuItems = useMemo(
    () => [
      <MenuItem key={field.name + "-select-one"} value='' divider>
        {placeholder}
      </MenuItem>,
      ...options.map((option) => (
        <MenuItem key={option[optionValueKey]} value={option[optionValueKey]} divider>
          {renderOptionLabel(option)}
        </MenuItem>
      )),
    ],
    [options, field.name, optionValueKey, renderOptionLabel, placeholder]
  )

  return (
    <FormControl fullWidth error={!!fieldState.error}>
      <Select {...selectProps}>{menuItems}</Select>
    </FormControl>
  )
}

const SelectField = memo(SelectFieldComponent) as typeof SelectFieldComponent

export { SelectField }
