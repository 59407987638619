import { getVendorPrice } from "./getVendorPrice"

import { KILOGRAMS_TO_POUNDS, POUNDS_TO_KILOGRAMS, POUND_UOM_ID } from "@/constants/unitOfMeasurements"
import type { OrderLineInput } from "@/graphql/codegen/graphql"
import type { ClassifiedPricingPackages } from "@/screens/Procurement/PurchaseOrder/types"

type Weight = {
  pounds: number
  kilograms: number
}

type Summary = {
  totalPallets: number
  totalWeight: Weight
  totalAmount: number
}

// This function gets total summary details of the products added in the purchase order which includes pallets, weight and amount
export const getTotalSummaryPurchaseOrder = (
  orderLines: OrderLineInput[],
  orderLineDetails: Record<number, ClassifiedPricingPackages>
): Summary => {
  const { totalPallets, totalWeight, totalAmount } = (orderLines ?? []).reduce<Summary>(
    (acc, { productId, numberOfPallets = 0, priceUomId, quantityInUom = 0, uomId, pricePerUom = 0 }) => {
      // Check if manual override price is applied
      const isManualOverridePrice = Boolean(pricePerUom && priceUomId)
      const pricingList = orderLineDetails ? orderLineDetails[productId]?.currentPricing : undefined

      const currentPrice = isManualOverridePrice
        ? pricePerUom
        : pricingList
          ? getVendorPrice(pricingList, quantityInUom, uomId)
          : 0

      const pricingUom = !isManualOverridePrice ? pricingList?.unitOfMeasurementId : priceUomId

      // Convert price to alternate UOM (e.g., lb to kg or vice versa)
      const uomFactor = pricingUom === POUND_UOM_ID ? KILOGRAMS_TO_POUNDS : POUNDS_TO_KILOGRAMS
      const convertedValue: number = currentPrice ? parseFloat(currentPrice.toString()) * uomFactor : 0
      acc.totalAmount += pricingUom === uomId ? Number(currentPrice) * quantityInUom : convertedValue * quantityInUom

      acc.totalPallets += numberOfPallets
      pricePerUom * quantityInUom
      if (uomId === POUND_UOM_ID) {
        acc.totalWeight.pounds += quantityInUom
        acc.totalWeight.kilograms += quantityInUom * POUNDS_TO_KILOGRAMS
      } else {
        acc.totalWeight.pounds += quantityInUom * KILOGRAMS_TO_POUNDS
        acc.totalWeight.kilograms += quantityInUom
      }
      return acc
    },
    {
      totalPallets: 0,
      totalWeight: { pounds: 0, kilograms: 0 },
      totalAmount: 0,
    }
  )

  return {
    totalPallets: parseFloat(totalPallets.toFixed(2)),
    totalWeight: {
      pounds: parseFloat(totalWeight.pounds.toFixed(2)),
      kilograms: parseFloat(totalWeight.kilograms.toFixed(2)),
    },
    totalAmount,
  }
}
