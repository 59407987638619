import { zodResolver } from "@hookform/resolvers/zod"
import dayjs from "dayjs"
import { useCallback } from "react"
import type { UseFormReturn } from "react-hook-form"
import { useForm } from "react-hook-form"

import type { PricingPackage } from "@/graphql/codegen/graphql"
import { AddPricingSchema } from "@/screens/Products/components/PricingTiers/AddPricingSchema"
import { useCreatePricingTier } from "@/screens/Products/hooks/useCreatePricingTier"

interface UsePricingPackageProps {
  methods: UseFormReturn<typeof AddPricingSchema>
  createPricingTier: (input: any) => Promise<any>
  updatePricingTier: (input: any) => Promise<any>
  onPricingEditClick: (pricingDetails: PricingPackage, uomName: string) => void
}

export const usePricingPackage = (): UsePricingPackageProps => {
  const { createPricingTier, updatePricingTier } = useCreatePricingTier()

  const methods = useForm({
    defaultValues: {
      pricingTiersToCreate: [
        { unitOfMeasurement: { id: "", name: "" }, numberOfUnits: "", pricePerUnit: "" },
        { unitOfMeasurement: { id: "", name: "" }, numberOfUnits: "", pricePerUnit: "" },
        { unitOfMeasurement: { id: "", name: "" }, numberOfUnits: "", pricePerUnit: "" },
      ],
      effectiveDate: "",
      expirationDate: "",
      freightTerms: "",
      incoterms: "",
      incotermsLocationId: "",
      sourceCompanyLocationAssociationId: "",
      pricingPackageId: "",
    },
    resolver: zodResolver(AddPricingSchema),
    mode: "all",
  })

  const onPricingEditClick = useCallback(
    (pricingDetails: PricingPackage, uomName?: string) => {
      methods.reset({
        ...pricingDetails,
        effectiveDate: dayjs.utc(pricingDetails.effectiveDate).format("MM/DD/YYYY"),
        expirationDate: pricingDetails.expirationDate
          ? dayjs.utc(pricingDetails.expirationDate).format("MM/DD/YYYY")
          : "",
        sourceCompanyLocationAssociationId: pricingDetails.sourceCompanyLocationAssociationId ?? "",
        pricingTiersToCreate: (pricingDetails.pricingTiers ?? []).map((tier) => ({
          unitOfMeasurement: { id: pricingDetails.unitOfMeasurementId, name: uomName },
          numberOfUnits: tier.numberOfUnits.toString(),
          pricePerUnit: tier.pricePerUnit.toString(),
        })),
      })
    },
    [methods]
  )

  return {
    createPricingTier,
    updatePricingTier,
    methods,
    onPricingEditClick,
  }
}
