import { Box, Checkbox, FormControlLabel, capitalize } from "@mui/material"
import type { ReactElement } from "react"
import type { Control, FieldPath } from "react-hook-form"
import { Controller } from "react-hook-form"

export const StatusFilter = <T extends Record<string, unknown>, K extends string, F extends FieldPath<T>>({
  data = [],
  control,
  name,
  onFilterChange,
  multiple = true,
  labelMap,
  disabled = false,
}: {
  data: K[]
  name: F
  control: Control<T>
  onFilterChange: (name: F, value: K | K[]) => void
  multiple?: boolean
  labelMap?: Record<K, string>
  disabled?: boolean
}): ReactElement => (
  <Box className='flex flex-col px-8 py-4'>
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          {data.map((status) => (
            <FormControlLabel
              key={status}
              control={
                <Checkbox
                  disabled={disabled}
                  checked={Array.isArray(field.value) ? field.value.includes(status) : field.value === status}
                  onChange={(e) => {
                    let newValue: K | K[]
                    if (multiple) {
                      newValue = e.target.checked
                        ? [...(Array.isArray(field.value) ? field.value : []), status]
                        : (Array.isArray(field.value) ? field.value : []).filter((s: K) => s !== status)
                    } else {
                      newValue = e.target.checked ? status : ([] as K[])
                    }
                    field.onChange(newValue)
                    onFilterChange(name, newValue)
                  }}
                />
              }
              label={labelMap ? labelMap[status] : capitalize(status.toLowerCase())}
            />
          ))}
        </>
      )}
    />
  </Box>
)
