import { useMemo } from "react"
import type { CombinedError } from "urql"
import { useQuery } from "urql"

import {
  type Company,
  type CompanyGetSuccess,
  GetContactGeneralInformationDocument,
  type Location,
  type LocationGetSuccess,
  type Query,
} from "@/graphql/codegen/graphql"

type GetContactGeneralInformationQuery = Pick<Query, "company" | "location">

interface UseGetContactGeneralInformationParams {
  companyId?: string
  locationId?: string
}

type UseGetContactGeneralInformation = ({ companyId, locationId }: UseGetContactGeneralInformationParams) => {
  company: Company | undefined
  location: Location | undefined
  fetching: boolean
  error: CombinedError | undefined
}

export const useGetContactGeneralInformation: UseGetContactGeneralInformation = ({ companyId, locationId } = {}) => {
  const [{ data, fetching, error }] = useQuery<GetContactGeneralInformationQuery>({
    query: GetContactGeneralInformationDocument,
    variables: { companyId, locationId },
    pause: !companyId && !locationId,
  })

  const company = useMemo(() => {
    const fetchedCompany = data?.company?.get as CompanyGetSuccess | undefined
    return fetchedCompany?.company
  }, [data?.company?.get])

  const location = useMemo(() => {
    const fetchedLocation = data?.location?.get as LocationGetSuccess | undefined
    return fetchedLocation?.location
  }, [data?.location?.get])

  return {
    company,
    location,
    fetching,
    error,
  }
}
