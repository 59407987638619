import { zodResolver } from "@hookform/resolvers/zod"
import { Alert, AlertTitle, Box, Typography } from "@mui/material"
import { PersonAddOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { useNavigate, useParams } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { useCallback, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

import ContactCard from "./ContactCard"
import { ContactSubscriptionDialog } from "./ContactSubscriptionDialog"

import { Button } from "@/components/common/Button"
import type {
  Contact,
  ContactCreateInput,
  ContactCreateSuccess,
  ContactLocationCreateInput,
} from "@/graphql/codegen/graphql"
import { CompanyDepartment, ContactChannel, ContactRole, ContactType } from "@/graphql/codegen/graphql"
import { useInfiniteScroll } from "@/hooks/useInfniniteScroll"
import AddContactDialog from "@/screens/Companies/create/components/AddContactDialog"
import type { ContactModalSchemaType } from "@/screens/Companies/create/components/AddContactSchema"
import { ContactModalSchema } from "@/screens/Companies/create/components/AddContactSchema"
import AddContactLocationRoles from "@/screens/Companies/edit/components/contacts/AddContactLocationRoles"
import { useCreateNewContactModal } from "@/screens/Companies/hooks/useCreateNewContact"
import { useGetContacts } from "@/screens/Companies/hooks/useGetContacts"
import AddContactComment from "@/screens/Contacts/edit/components/AddContactComment"
import { useGetContactMethods } from "@/screens/Contacts/edit/hooks/useGetContactMethods"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

const ContactContainer = (): ReactElement => {
  const [isAddContactDialogOpen, setIsAddContactDialogOpen] = useState(false)
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false)
  const [isLocationRolesDialogOpen, setIsLocationRolesDialogOpen] = useState(false)
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null)
  const [isSubscriptionsDialogOpen, setIsSubscriptionsDialogOpen] = useState(false)

  const navigate = useNavigate()
  const { companyId: queryCompanyId } = useParams({ from: "/companies/$companyId/edit" })
  const { contactList, contactListFetching, triggerQuery, loadMore } = useGetContacts(queryCompanyId)
  const { containerRef } = useInfiniteScroll(loadMore, {
    isLoading: contactListFetching,
    hasMore: contactList.length % 20 === 0,
    threshold: 200,
  })
  const { batchCreateContactMethods } = useGetContactMethods()
  const { createContact, createContactLocation } = useCreateNewContactModal()
  const notify = useNotificationsStore((state) => state.enqueueNotification)

  const methods = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      companyDepartment: "",
      title: "",
      physicalLocationId: "",
      phoneNumber: [{ number: "", type: "Text", isPreferred: false }],
      locationRoles: [{ locationId: "", role: "" }],
      email: "",
    },
    resolver: zodResolver(ContactModalSchema),
    mode: "all",
  })

  const handleAddContact = useCallback(() => {
    methods.reset()
    setIsAddContactDialogOpen(true)
  }, [methods])

  const handleSubmit = useCallback(
    async (data: ContactModalSchemaType) => {
      const formattedContactDetails: ContactCreateInput = {
        companyId: queryCompanyId,
        firstName: data.firstName,
        lastName: data.lastName,
        companyDepartment: CompanyDepartment[data.companyDepartment as keyof typeof CompanyDepartment],
        title: data.title ?? "",
        physicalLocationId: data.physicalLocationId || null,
      }
      const { error, data: contactDetails } = await createContact({ input: formattedContactDetails })

      if (error?.message || contactDetails?.contact.create.__typename === "ContactCreateFailure") {
        notify({
          type: "error",
          message: error?.message || "Failed to create contact",
        })
      } else {
        const contactCreateSuccess = contactDetails?.contact.create as ContactCreateSuccess
        const { contactId } = contactCreateSuccess.createdContact
        if (!contactId) return

        const formattedContactMethods = [
          ...(data.phoneNumber ?? [])
            .filter(({ number }) => number)
            .sort((a) => (a.isPreferred ? -1 : 1))
            .map(({ number, type }) => ({
              contactId,
              contactType: ContactType[type as keyof typeof ContactType],
              value: number!,
              contactChannel: ContactChannel.Phone,
            })),
          ...(data.email
            ? [
                {
                  contactId,
                  value: data.email,
                  contactType: ContactType.Work,
                  contactChannel: ContactChannel.Email,
                },
              ]
            : []),
        ]

        await batchCreateContactMethods({
          input: {
            contactMethodCreateInputs: formattedContactMethods,
          },
        })

        for (const { locationId, role } of data.locationRoles ?? []) {
          if (!locationId || !role) continue
          const formattedContactLocation: ContactLocationCreateInput = {
            contactId,
            locationId,
            contactRole: ContactRole[role as keyof typeof ContactRole],
          }
          await createContactLocation({ input: formattedContactLocation })
        }
      }
      triggerQuery()
      setIsAddContactDialogOpen(false)
    },
    [batchCreateContactMethods, createContact, createContactLocation, notify, queryCompanyId, triggerQuery]
  )

  const handleContactCardClick = useCallback(
    (contactId: string) => {
      void navigate({ to: `/contact/${contactId}` })
    },
    [navigate]
  )

  const handleCloseDialogs = useCallback(() => {
    setIsCommentDialogOpen(false)
    setIsLocationRolesDialogOpen(false)
    setIsSubscriptionsDialogOpen(false)
    setSelectedContact(null)
  }, [])

  const contactCards = useMemo(
    () =>
      contactList.map((contact: Contact) => (
        <ContactCard
          key={contact.contactId}
          contact={contact}
          onCardClick={() => handleContactCardClick(contact.contactId ?? "")}
          onCommentClick={() => {
            setIsCommentDialogOpen(true)
            setSelectedContact(contact)
          }}
          onSubscriptionClick={() => {
            setSelectedContact(contact)
            setIsSubscriptionsDialogOpen(true)
          }}
          onLocationClick={() => {
            setSelectedContact(contact)
            setIsLocationRolesDialogOpen(true)
          }}
        />
      )),
    [contactList, handleContactCardClick]
  )

  if (contactListFetching) {
    return <Typography variant='h5'>Loading...</Typography>
  }

  return (
    <>
      {contactList.length === 0 ? (
        <Box my={2}>
          <Alert
            icon={<WarningOutlined color='primary' />}
            severity='info'
            variant='filled'
            classes={{
              message: "w-full flex flex-row justify-between",
            }}
          >
            <Box>
              <AlertTitle className='font-normal leading-7'>
                Add contacts and define their role at locations created
              </AlertTitle>
              <Box className='w-full font-thin'>One contact per location is required</Box>
            </Box>
            <Box className='flex items-center'>
              <Button size='small' appearance='outlined' onClick={handleAddContact}>
                <PersonAddOutlined />
                Add Contact
              </Button>
            </Box>
          </Alert>
        </Box>
      ) : (
        <Box className='grid w-full grid-cols-[repeat(auto-fill,_minmax(321px,_1fr))] gap-6 px-4' ref={containerRef}>
          {contactCards}
          <ContactCard isEmpty onCardClick={handleAddContact} />
        </Box>
      )}
      <FormProvider {...methods}>
        <AddContactDialog
          open={isAddContactDialogOpen}
          onClose={() => {
            methods.reset()
            setIsAddContactDialogOpen(false)
          }}
          onSubmit={handleSubmit}
        />
      </FormProvider>
      <AddContactComment
        open={isCommentDialogOpen}
        name={`${selectedContact?.firstName} ${selectedContact?.lastName}`}
        companyName={selectedContact?.company?.name ?? undefined}
        contactId={selectedContact?.contactId ?? ""}
        onClose={handleCloseDialogs}
        onSubmit={handleCloseDialogs}
      />
      <ContactSubscriptionDialog
        open={isSubscriptionsDialogOpen}
        onClose={() => setIsSubscriptionsDialogOpen(false)}
        contactId={selectedContact?.contactId ?? ""}
        name={`${selectedContact?.firstName} ${selectedContact?.lastName}`}
        companyName={selectedContact?.company?.name ?? undefined}
        onSubmit={handleCloseDialogs}
      />
      <AddContactLocationRoles
        open={isLocationRolesDialogOpen}
        contactId={selectedContact?.contactId ?? ""}
        name={`${selectedContact?.firstName} ${selectedContact?.lastName}`}
        companyName={selectedContact?.company?.name ?? undefined}
        companyId={selectedContact?.companyId ?? ""}
        onClose={() => setIsLocationRolesDialogOpen(false)}
        onSubmit={handleCloseDialogs}
      />
    </>
  )
}

export default ContactContainer
