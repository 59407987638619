import { Box, Typography } from "@mui/material"
import { useNavigate, useSearch } from "@tanstack/react-router"
import React, { useCallback } from "react"

import { AccountManagerChips } from "@/components/common/Filters/AccountManagerChip"
import { StatusChips } from "@/components/common/Filters/StatusChip"
import type { CompanyListFilter, CompanyStatus } from "@/graphql/codegen/graphql"

export const SupplierReportrSearchFilterChips: React.FC = () => {
  const navigate = useNavigate({ from: "/procurement/_layout/supplier-report" })
  const { statuses, accountManagers } = useSearch({ from: "/procurement/_layout/supplier-report", strict: true })
  const handleRemoveFilter = useCallback(
    (key: keyof CompanyListFilter, value: string | CompanyStatus) => {
      void navigate({
        search: (prev: CompanyListFilter) => {
          const newFilter = { ...prev }

          if (Array.isArray(newFilter[key])) {
            ;(newFilter[key] as (string | CompanyStatus)[]) = (newFilter[key] as (string | CompanyStatus)[]).filter(
              (item) => item !== value
            )
            if ((newFilter[key] as Array<string | CompanyStatus>).length === 0) {
              delete newFilter[key]
            }
          } else {
            delete newFilter[key]
          }

          return newFilter
        },
        replace: true,
      })
    },
    [navigate]
  )

  if (!statuses && !accountManagers) {
    return null
  }

  return (
    <Box data-testid='company-search-filter-chips' display='flex' flexWrap='wrap' alignItems='center' gap={2}>
      <Typography variant='body1'>Filters:</Typography>
      <StatusChips
        statuses={statuses}
        onRemove={(key, value) => handleRemoveFilter(key as keyof CompanyListFilter, value)}
      />
      <AccountManagerChips
        accountManagers={accountManagers || []}
        onRemove={(key, value) => handleRemoveFilter(key as keyof CompanyListFilter, value)}
      />
    </Box>
  )
}
