import type { Client, CombinedError } from "urql"
import { create } from "zustand"

import { type Product, ProductListDocument, ProductSortField, SortingOrder } from "@/graphql/codegen/graphql.ts"

export type ProductSearchFilters = {
  query?: string
  category?: string
  field?: ProductSortField
  order?: SortingOrder
  [key: string]: unknown
}

export type ProductSearchState = {
  companyId: string
  data: Product[]
  fetching: boolean
  error: CombinedError | Error | undefined
  offset: number
  limit: number
  hasMore: boolean
  lastExecutedFilters: ProductSearchFilters | undefined
  setCompanyId: (companyId: string) => void
  setFetching: (fetching: boolean) => void
  setError: (error: CombinedError | Error | undefined) => void
  resetSearch: () => void
  setData: (data: Product[]) => void
  appendData: (data: Product[]) => void
  executeSearch: (client: Client, filters: ProductSearchFilters) => Promise<void>
  loadMore: (client: Client, filters: ProductSearchFilters) => Promise<void>
  clearStore: () => void
}

const INITIAL_STATE = {
  companyId: "",
  data: [],
  fetching: false,
  error: undefined,
  offset: 0,
  limit: 20,
  hasMore: true,
  lastExecutedFilters: undefined,
}

export const useProductSearchStore = create<ProductSearchState>((set, get) => ({
  ...INITIAL_STATE,

  setFetching: (fetching) => set({ fetching }),

  setError: (error) => set({ error }),

  resetSearch: () => set({ ...INITIAL_STATE }),

  setData: (data) =>
    set(() => ({
      data,
      error: undefined,
    })),

  appendData: (data) =>
    set((state) => ({
      data: [...state.data, ...data],
      error: undefined,
    })),

  setCompanyId: (companyId) => set({ companyId }),

  executeSearch: async (client: Client, filters: ProductSearchFilters) => {
    const state = get()
    if (state.fetching) return

    const { query = "", ...restFilters } = filters || {}
    const validQuery = typeof query === "string" && query.length >= 3 ? query : ""
    const currentFilters = { ...restFilters, query: validQuery }

    const shouldExecuteSearch = validQuery !== undefined || Object.keys(restFilters).length > 0
    if (!shouldExecuteSearch) {
      set({ data: [], hasMore: false, lastExecutedFilters: undefined, offset: 0 })
      return
    }

    set({ fetching: true })

    try {
      const { category, field, order, ...otherFilters } = currentFilters
      const queryInput = {
        filter: { ...otherFilters, companyId: state.companyId },
        offset: 0,
        limit: state.limit,
        sort: { field: field ?? ProductSortField.CreatedOn, order: order ?? SortingOrder.Desc },
      }

      const result = await client
        .query(ProductListDocument, { input: queryInput }, { requestPolicy: "network-only" })
        .toPromise()

      if (result.data?.product?.list.__typename === "ProductListSuccess") {
        const products = result.data.product.list.products as Product[]
        set({
          data: products,
          offset: 0,
          hasMore: products.length === state.limit,
          lastExecutedFilters: currentFilters,
        })
      }

      if (result.error) set({ error: result.error })
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },

  loadMore: async (client: Client, filters: ProductSearchFilters) => {
    const state = get()
    if (state.fetching || !state.hasMore) return

    const { query = "", ...restFilters } = filters || {}
    const validQuery = typeof query === "string" && query.length >= 3 ? query : undefined
    const currentFilters = { ...restFilters, ...(validQuery ? { query: validQuery } : {}) }

    const newOffset = state.offset + state.limit

    set({ fetching: true, offset: newOffset })

    try {
      const { category, field, order, ...otherFilters } = currentFilters
      const queryInput = {
        filter: { ...otherFilters, companyId: state.companyId },
        offset: newOffset,
        limit: state.limit,
        sort: { field: field ?? ProductSortField.CreatedOn, order: order ?? SortingOrder.Desc },
      }

      const result = await client.query(ProductListDocument, { input: queryInput }).toPromise()

      if (result.data?.product?.list.__typename === "ProductListSuccess") {
        const products = result.data.product.list.products as Product[]
        set((prevState) => ({
          data: [...prevState.data, ...products],
          offset: newOffset,
          hasMore: products.length === state.limit,
          lastExecutedFilters: currentFilters,
        }))
      }

      if (result.error) set({ error: result.error })
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },

  clearStore: () =>
    set({
      data: [],
      fetching: false,
      error: undefined,
      offset: 0,
      hasMore: true,
      lastExecutedFilters: undefined,
    }),
}))
