import { IconButton } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import { KeyboardArrowDownOutlined, KeyboardArrowUpFilledOutlined } from "@mui-symbols-material/w300"
import type { CellContext, ColumnDef, Row } from "@tanstack/react-table"
import type { ReactElement } from "react"

import StyledChip from "@/components/common/Chip/Chip"
import { StatusIndicator } from "@/components/common/StatusIndicator/StatusIndicator"
import { type Company, CompanyStatus } from "@/graphql/codegen/graphql.ts"
import { companyStatusMap } from "@/screens/Companies/components/tables/companies.const"

const GoodStockChip = ({ row }: { row: Row<Company> }) => {
  return (
    <StyledChip
      key={row.original.externalSystemId}
      className='bg-tertiary-green-100'
      label={<>Good</>}
      avatar={<Avatar className='rounded bg-tertiary-green-500 text-white'>00</Avatar>}
      data-testid={`leadTimeWeeks-${row.original.externalSystemId}`}
    />
  )
}
const LowStockChip = ({ row }: { row: Row<Company> }) => {
  return (
    <StyledChip
      key={row.original.externalSystemId}
      label={<>Low</>}
      className='bg-tertiary-yellow-300'
      avatar={<Avatar className='rounded bg-tertiary-yellow-900 text-white'>00</Avatar>}
      data-testid={`leadTimeWeeks-${row.original.externalSystemId}`}
    />
  )
}

const OutStockChip = ({ row }: { row: Row<Company> }) => {
  return (
    <StyledChip
      key={row.original.externalSystemId}
      className='bg-secondary-200'
      label={<>Out</>}
      avatar={<Avatar className='rounded bg-secondary-300 text-white'>00</Avatar>}
      data-testid={`leadTimeWeeks-${row.original.externalSystemId}`}
    />
  )
}

const ActionsCell = ({ cell }: CellContext<Company, unknown>): ReactElement => {
  const { row } = cell.getContext()

  return (
    <Box className='flex items-center justify-end space-x-4'>
      <IconButton aria-label='expand row' size='small' onClick={() => row.toggleExpanded()} data-testid='expand-button'>
        {row.getIsExpanded() ? <KeyboardArrowUpFilledOutlined /> : <KeyboardArrowDownOutlined />}
      </IconButton>
    </Box>
  )
}

export const SupplierReportTableColumns: ColumnDef<Company>[] = [
  {
    header: "",
    id: "status",
    cell: ({ row }) => <StatusIndicator status={row.original.status ?? CompanyStatus.Pending} map={companyStatusMap} />,
    size: 50,
  },
  {
    header: "Company ID",
    accessorKey: "externalSystemId",
    size: 200,
    cell: ({ getValue }) => getValue() || "-",
  },
  {
    header: "Supplier Name",
    accessorKey: "name",
    sortingFn: "alphanumeric",
    enableSorting: true,
    cell: ({ getValue }) => getValue() || "-",
  },
  {
    header: "Ship From",
    cell: ({ row }: { row: Row<Company> }): string => {
      const shipping = row.original.locationsAssociations?.[0]?.shipping
      const shipFrom = [shipping?.address1, shipping?.city, shipping?.region?.name].filter(Boolean).join(", ")

      if (!shipFrom && !shipping?.name) return "-"

      return `${shipping?.name} - ${shipFrom}`
    },
  },
  {
    header: "Stock Level",
    cell: ({ row }) => (
      <Box className='flex gap-2'>
        <GoodStockChip row={row} />
        <LowStockChip row={row} />
        <OutStockChip row={row} />
      </Box>
    ),
  },
  {
    header: "",
    id: "expand",
    cell: ActionsCell,
  },
]
