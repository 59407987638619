import { zodResolver } from "@hookform/resolvers/zod"
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback, useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { PackagingInfoForm } from "./PackagingInfoForm"
import { AddPackagingInfoSchema } from "./PackagingInfoSchema"
import type { PackagingInfo } from "./usePackagingInfo"

import { Button } from "@/components/common/Button"
import { POUND_UOM_ID } from "@/constants/unitOfMeasurements"
import transformFormDefaultValues from "@/utils/forms/transformFormDefaultValues"

type AddPackagingInfoModalProps = {
  open: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: any) => Promise<void>
  defaultValues: PackagingInfo | null
}

const initialDefaultValues: PackagingInfo = {
  freightItem: null,
  freightClass: null,
  packagingType: null,
  packageWeight: null,
  packageWeightUom: null,
  packageLength: null,
  packageWidth: null,
  packageHeight: null,
  packageDimensionsUom: null,
  palletWeight: null,
  palletWeightUom: null,
  palletSizeLength: null,
  palletSizeWidth: null,
  palletSizeHeight: null,
  palletSizeUom: null,
  packagesPerPallet: null,
}

const PackagingInfoModal: FunctionComponent<AddPackagingInfoModalProps> = ({
  open,
  onClose,
  onSubmit,
  defaultValues: defaultValuesProp,
}) => {
  const defaultValues = useMemo(
    () =>
      transformFormDefaultValues({
        ...initialDefaultValues,
        ...defaultValuesProp,
        packageWeightUom: POUND_UOM_ID,
        packageDimensionsUom: "USSTD_DISTANCE_INCH",
        palletSizeUom: "USSTD_DISTANCE_INCH",
        palletWeightUom: POUND_UOM_ID,
      }),
    [defaultValuesProp]
  )

  const { reset, trigger, ...form } = useForm({
    defaultValues,
    resolver: zodResolver(AddPackagingInfoSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
  })

  useEffect(() => {
    if (open) {
      reset(defaultValues)
      trigger()
    }
  }, [reset, open, defaultValues, trigger])

  const handleCancelClick = useCallback(() => {
    onClose("cancel")
  }, [onClose])

  const handleFormSubmit = useCallback(
    async (values: PackagingInfo) => {
      await onSubmit(values)
      onClose("success")
    },
    [onClose, onSubmit]
  )

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        reset()
        onClose(reason)
      }}
      fullWidth
    >
      <DialogTitle className='pl-10'>
        <span className='font-normal text-primary'>Packaging information</span>
      </DialogTitle>
      <IconButton aria-label='close' onClick={handleCancelClick} className='absolute right-4 top-4 text-primary'>
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        <FormProvider {...{ ...form, reset, trigger }}>
          <PackagingInfoForm />
        </FormProvider>
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleCancelClick} disabled={form.formState.isSubmitting}>
          Cancel
        </Button>
        <Button
          onClick={form.handleSubmit(handleFormSubmit)}
          disabled={!form.formState.isValid || form.formState.isSubmitting}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PackagingInfoModal
