import { useNavigate, useSearch } from "@tanstack/react-router"
import type { ExpandedState, OnChangeFn, Row, RowSelectionState, SortingState } from "@tanstack/react-table"
import { ErrorMessage } from "components/common/ErrorMessage/ErrorMessage"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { useShallow } from "zustand/react/shallow"

import { usePurchaseOrderTable } from "../hooks/usePurchaseOrderTable"

import { PurchaseOrdersExapndedRowDetails } from "./PurchaseOrdersExapndedRowDetails"

import { AerosBaseTable } from "@/components/Tables/Aeros/components/AerosBaseTable"
import type { Order, OrderSortField } from "@/graphql/codegen/graphql"
import { SortingOrder } from "@/graphql/codegen/graphql"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore"
import { useThrottle } from "@/hooks/useThrottle"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import { getRefinedSearchParams } from "@/screens/Companies/utils.ts"
import { useProductPurchaseOrdersSearchStore } from "@/screens/Procurement/PurchaseOrders/stores/useProductPurchaseOrdersSearchStore.tsx"
import type { SortablePurchaseOrderAccessorKey } from "@/screens/Procurement/PurchaseOrders/types"
import {
  accessoryKeyToOrderSortField,
  accessoryProductKeyToOrderSortField,
} from "@/screens/Procurement/PurchaseOrders/types"
import { getProductOrderColumns } from "@/screens/Procurement/PurchaseOrders/utils/productOrderColumns.tsx"
import useDebounce from "@/utils/useDebounce"

export const PurchaseProductOrdersTable = (): JSX.Element => {
  const navigate = useNavigate()
  const currentSearch = useSearch({ from: "/procurement/_layout/purchase-orders" })
  const { category, query, field, order, ...restSearchParams } = currentSearch

  const data = useProductPurchaseOrdersSearchStore(({ data }) => data)
  const fetching = useProductPurchaseOrdersSearchStore(({ fetching }) => fetching)
  const error = useProductPurchaseOrdersSearchStore(({ error }) => error)
  const executeSearch = useProductPurchaseOrdersSearchStore(useShallow((state) => state.executeSearch))
  const loadMore = useProductPurchaseOrdersSearchStore(useShallow((state) => state.loadMore))

  const columns = useMemo(() => getProductOrderColumns(), [])

  const { expanded, selected, sorting, setExpanded, setSelected } = usePurchaseOrderTable({
    sortBy: field,
    sortOrder: order,
  })

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const debouncedExecuteSearch = useDebounce(
    (filters: {
      query?: string
      category?: string
      field?: OrderSortField
      order?: SortingOrder
      [key: string]: unknown
    }) => {
      executeSearch(queryClient, filters)
    },
    300
  )

  const handleSortingChange = (updaterFn: (state: SortingState) => SortingState) => {
    const newSorting = updaterFn(sorting)
    if (newSorting.length > 0) {
      const sort = newSorting[0]
      const key = sort.id as SortablePurchaseOrderAccessorKey
      const field = accessoryKeyToOrderSortField.get(key) || accessoryProductKeyToOrderSortField.get(key)
      const params = {
        ...restSearchParams,
        query,
        category,
        field,
        order: sort.desc ? SortingOrder.Desc : SortingOrder.Asc,
      }
      const refinedParams = getRefinedSearchParams(params)
      void navigate({ search: refinedParams.search, to: "/procurement/purchase-orders/", replace: true })
    }
  }

  const [throttledLoadMore] = useThrottle(() => loadMore(queryClient, currentSearch), 200)
  const scrollHandler = useScrollLoadMore({
    fetching,
    loadMore: throttledLoadMore,
    scrollContainerRef: tableContainerRef,
  })
  const onScroll = useCallback(scrollHandler, [scrollHandler])

  useEffect(() => {
    debouncedExecuteSearch(currentSearch)
  }, [debouncedExecuteSearch, currentSearch])

  if (error) {
    return <ErrorMessage error={error} />
  }

  return (
    <AerosBaseTable<any>
      features={{
        table: {
          stickyHeader: true,
        },
        tanstackOptions: {
          columns,
          data,
          state: {
            expanded,
            rowSelection: selected,
            sorting,
          },
          enableRowSelection: true,
          enableMultiRowSelection: true,
          enableExpanding: true,
          getRowCanExpand: (row: Row<Order>) => Boolean(row.original.orderLines),
          onRowSelectionChange: setSelected as OnChangeFn<RowSelectionState>,
          onExpandedChange: setExpanded as OnChangeFn<ExpandedState>,
          getRowId: (row) => row.orderId.toString() + row.productId.toString(),
          renderExpandedContent: PurchaseOrdersExapndedRowDetails,
          onSortingChange: handleSortingChange as OnChangeFn<SortingState>,
          enableSortingRemoval: false,
          manualSorting: true,
        },
      }}
      slotProps={{
        bodyCellExpanded: { className: "bg-primary-100" },
        container: {
          ref: tableContainerRef,
          onScroll,
        },
      }}
    />
  )
}
