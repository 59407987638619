import type { CombinedError } from "urql"

import type { Product } from "@/graphql/codegen/graphql.ts"
import { ProductListFilterSchema } from "@/graphql/codegen/graphql.ts"

export const ProductFilterSchema = ProductListFilterSchema().extend({
  accountManagers: ProductListFilterSchema().shape.companyId.default(null),
  statuses: ProductListFilterSchema().shape.statuses.default(null),
  query: ProductListFilterSchema().shape.query.default(""),
})

export interface ProductActionsStore {
  /**
   * key - product id
   * value - array of product pricing
   */
  // TODO
  //   productPricing: Map<string, ProductPricing[]>
  productAtAction: Product | null
  openedDialog: ProductActionsDialogs | ProductPricingActionsDialogs | ProductConfirmationDialogs | null
  setProductAtAction: (product: Product | null) => void
  setOpenedDialog: (
    dialog: ProductActionsDialogs | ProductPricingActionsDialogs | ProductConfirmationDialogs | null
  ) => void
  onAction: (action: ProductActions | ProductPricingActions) => void
  loadProductPricing: (productId: string) => void
  // TODO
  //   setProductPricing: (productPricing: Map<string, ProductPricing[]>) => void
}

export enum ProductConfirmationDialogs {
  ADD_SUCCESSFUL = "ADD_SUCCESSFUL",
  CANCEL_PRODUCT = "CANCEL_PRODUCT",
  DELETE_PRODUCT = "DELETE_PRODUCT",
}

export enum ProductActionsDialogs {
  NEW_PRODUCT = "NEW_PRODUCT",
  EDIT_PRODUCT = "EDIT_PRODUCT",
  ADD_SKU = "ADD_SKU",
  EDIT_SKU = "EDIT_SKU",
  ADD_LOCATION = "ADD_LOCATION",
  EDIT_LOCATION = "EDIT_LOCATION",
}

export enum ProductActions {
  ACTIVATE_PRODUCT = "ACTIVATE_PRODUCT",
  DEACTIVATE_PRODUCT = "DEACTIVATE_PRODUCT",
  EDIT_PRODUCT_DETAILS = "EDIT_PRODUCT_DETAILS",
}

export enum ProductPricingActionsDialogs {
  EDIT_PRICING = "EDIT_PRICING",
  NEW_PRICING = "NEW_PRICING",
}

export enum ProductPricingActions {
  ARCHIVE_PRICING = "ARCHIVE_PRICING",
}

export type ProductSearchStore = {
  data: Product[]
  fetching: boolean
  error: CombinedError | Error | undefined
  offset: number
  limit: number
  setFetching: (fetching: boolean) => void
  setError: (error: CombinedError | Error | undefined) => void
  setOffset: (offset: number) => void
  resetSearch: () => void
  setData: (data: Product[]) => void
  appendData: (data: Product[]) => void
  incrementOffset: () => void
  resetOffset: () => void
}
