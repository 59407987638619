import { Alert, AlertTitle, Box, ButtonGroup, Typography } from "@mui/material"
import { AddLocationAltOutlined, LinkOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { useNavigate, useParams, useSearch } from "@tanstack/react-router"
import type { FC } from "react"
import { useState } from "react"
import { twMerge } from "tailwind-merge"

import AssociatedLocationContainer from "../../create/components/AssociatedLocationContainer"
import { useGetAssociatedLocations } from "../../hooks/useGetAssociatedLocations"

import { LocationTableDialogs } from "./locations/dialogs/LocationTableDialogs"
import { useLocationTableActions } from "./locations/hooks/useLocationTableActions"
import { LocationMenuDialogs } from "./locations/types/dialogs"

import { Button } from "@/components/common/Button"
import { SectionContainer } from "@/components/SectionContainer/SectionContainer"
import type { Country, Location, Region } from "@/graphql/codegen/graphql"
import LocationsTable from "@/screens/Companies/edit/components/locations/Table.tsx"
import { useLocations } from "@/screens/Companies/hooks/useLocations.tsx"

interface LocationsContentProps {
  locations: Location[]
  loadMore: () => void
  isLocationsFetching: boolean
  regionList: Region[]
  countryList: Country[]
  setOpenedDialog: (dialog: LocationMenuDialogs | null) => void
  setActiveEditLocation: (location: Location | null) => void
  error: Error | null
}

const LocationsContent: FC<LocationsContentProps> = ({
  locations,
  loadMore,
  isLocationsFetching,
  regionList,
  countryList,
  setOpenedDialog,
  setActiveEditLocation,
  error,
}) => {
  return (
    <>
      {locations.length > 0 ? (
        <>
          <LocationsTable
            locations={locations}
            loadMore={loadMore}
            isLocationsFetching={isLocationsFetching}
            regionList={regionList}
            countryList={countryList as Country[]}
            setOpenedDialog={setOpenedDialog}
            setActiveEditLocation={setActiveEditLocation}
          />
          {error && <Typography color='error'>{error.message}</Typography>}
        </>
      ) : (
        <EmptyLocationsAlert setOpenedDialog={setOpenedDialog} />
      )}
    </>
  )
}

interface EmptyLocationsAlertProps {
  setOpenedDialog: (dialog: LocationMenuDialogs | null) => void
}

const EmptyLocationsAlert: FC<EmptyLocationsAlertProps> = ({ setOpenedDialog }) => (
  <Box my={2}>
    <Alert
      icon={<WarningOutlined color='primary' />}
      severity='info'
      variant='filled'
      classes={{
        message: "w-full flex flex-row justify-between",
      }}
    >
      <Box>
        <AlertTitle className='font-normal leading-7'>Start by adding location(s)</AlertTitle>
        <Box className='w-full font-thin'>
          No location has been added yet. Once you add locations you will be able to associate shipping, billing, and PO
          types to them.
        </Box>
      </Box>
      <Box className='flex items-center'>
        <Button size='small' appearance='outlined' onClick={() => setOpenedDialog(LocationMenuDialogs.ADD_ADDRESS)}>
          <AddLocationAltOutlined />
          Add Location
        </Button>
      </Box>
    </Alert>
  </Box>
)

const CompanyLocationsTab: FC = () => {
  const { tab } = useSearch({ strict: false })
  const navigate = useNavigate()
  const { companyId } = useParams({ from: "/companies/$companyId/edit" })
  const { associatedLocationList, reexecuteAssociatedLocationQuery } = useGetAssociatedLocations({
    companyId,
  })

  const handleTabChange = (newTab: string) => {
    void navigate({
      to: "/companies/$companyId/edit/locations",
      search: (old: Record<string, string>) => ({ ...old, tab: newTab }),
      replace: true,
    })
  }

  const {
    companyLocations: { locations, loadMoreLocations, fetching, error, reexecuteQueryWithReset },
    regionList,
    countryList,
  } = useLocations()

  const [showAssociateLocationFields, setShowAssociateLocationFields] = useState(false)

  const {
    openedDialog,
    setOpenedDialog,
    prevOpenedDialog,
    activeEditLocation,
    setActiveEditLocation,
    handleOnCreateSubmit,
    handleOnUpdateSubmit,
  } = useLocationTableActions({ companyId, reexecuteQueryWithReset })

  return (
    <>
      <LocationTableDialogs
        openedDialog={openedDialog}
        setOpenedDialog={setOpenedDialog}
        activeEditLocation={activeEditLocation}
        countryList={countryList}
        regionList={regionList ?? []}
        prevOpenedDialog={prevOpenedDialog}
        handleCreateSubmit={handleOnCreateSubmit}
        handleUpdateSubmit={handleOnUpdateSubmit}
      />
      <SectionContainer className='flex flex-col gap-4 overflow-hidden' paddingVariant='none'>
        <Box className='flex justify-between'>
          <ButtonGroup color='inherit' orientation='horizontal' variant='outlined' className='border-2 border-gray-300'>
            <Button
              onClick={() => handleTabChange("locations")}
              className={twMerge([
                "h-9 rounded-none rounded-l-sm border-gray-400 uppercase text-gray-700",
                tab === "locations" ? "bg-gray-300" : "",
              ])}
              appearance='text'
            >
              List of Locations
            </Button>
            <Button
              onClick={() => handleTabChange("associated")}
              className={twMerge([
                "h-9 rounded-none rounded-l-sm border-gray-400 uppercase text-gray-700",
                tab === "associated" ? "bg-gray-300" : "",
              ])}
              appearance='text'
            >
              Associated Locations
            </Button>
          </ButtonGroup>
          {tab === "locations" && (
            <Button
              appearance='outlined'
              onClick={() => setOpenedDialog(LocationMenuDialogs.ADD_ADDRESS)}
              className={locations?.length > 0 ? "visible" : "invisible"}
            >
              <AddLocationAltOutlined />
              Add Location
            </Button>
          )}
          {tab === "associated" && (
            <Button
              appearance='outlined'
              onClick={() => setShowAssociateLocationFields(true)}
              disabled={showAssociateLocationFields}
            >
              <LinkOutlined />
              Associate Location
            </Button>
          )}
        </Box>
        {tab === "locations" && (
          <LocationsContent
            locations={locations}
            loadMore={loadMoreLocations}
            isLocationsFetching={fetching}
            regionList={regionList}
            countryList={countryList as Country[]}
            setOpenedDialog={setOpenedDialog}
            setActiveEditLocation={setActiveEditLocation}
            error={error ?? null}
          />
        )}
        {tab === "associated" && (
          <AssociatedLocationContainer
            associatedLocationList={associatedLocationList}
            showFields={showAssociateLocationFields}
            onAddFields={setShowAssociateLocationFields}
            reexecuteAssociatedLocationQuery={reexecuteAssociatedLocationQuery}
          />
        )}
      </SectionContainer>
    </>
  )
}

export default CompanyLocationsTab
