import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import { type FC, useCallback, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"

import { AddContactGeneralInformation } from "./AddContactGeneralInformation"
import { AddContactMethods } from "./AddContactMethods"
import type { ContactModalSchemaType } from "./AddContactSchema"
import { AddLocationRoles } from "./AddLocationRoles"

import { Button } from "@/components/common/Button"

type ContactModalProps = {
  open: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: ContactModalSchemaType) => void
}

const AddContactDialog: FC<ContactModalProps> = ({ open, onClose, onSubmit }) => {
  const { reset, trigger, handleSubmit, watch } = useFormContext<ContactModalSchemaType>()
  const [step, setStep] = useState(0)

  const firstName = watch("firstName")
  const lastName = watch("lastName")

  const isGeneralStepDisabled = !firstName || !lastName

  const onNextStep = useCallback(async () => {
    const isValid = await trigger()
    if (isValid) {
      setStep((prevStep) => prevStep + 1)
    }
  }, [trigger])

  const handleFormSubmit = useCallback(
    (value: ContactModalSchemaType) => {
      onSubmit(value)
      onClose("success")
      setStep(0)
    },
    [onClose, onSubmit]
  )

  const handleResetClick = useCallback(() => {
    reset()
    onClose("cancel")
    setStep(0)
  }, [reset, onClose])

  const handleDialogClose = useCallback(
    (_: React.SyntheticEvent, reason: "backdropClick" | "escapeKeyDown") => {
      reset()
      setStep(0)
      onClose(reason)
    },
    [reset, onClose]
  )

  const renderStepContent = useMemo(() => {
    switch (step) {
      case 0:
        return <AddContactGeneralInformation />
      case 1:
        return <AddContactMethods />
      case 2:
        return <AddLocationRoles />
      default:
        return null
    }
  }, [step])

  const renderStepActions = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <Button onClick={onNextStep} disabled={isGeneralStepDisabled}>
            Continue
          </Button>
        )
      case 1:
        return (
          <Box>
            <Button variant='primary' appearance='text' className='mr-6' onClick={handleSubmit(handleFormSubmit)}>
              Save And Exit
            </Button>
            <Button onClick={onNextStep}>Continue</Button>
          </Box>
        )
      case 2:
        return (
          <Box>
            <Button variant='primary' appearance='text' className='mr-6' onClick={handleSubmit(handleFormSubmit)}>
              Save And Exit
            </Button>
            <Button onClick={handleSubmit(handleFormSubmit)}>Save</Button>
          </Box>
        )
      default:
        return null
    }
  }, [step, isGeneralStepDisabled, onNextStep, handleSubmit, handleFormSubmit])

  if (!open) return null

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth>
      <DialogTitle className='pl-10 font-normal text-primary'>Add Contact</DialogTitle>
      <IconButton aria-label='close' onClick={() => onClose("cancel")} className='absolute right-4 top-4 text-primary'>
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>{renderStepContent}</DialogContent>
      <DialogActions className='justify-between px-10 pb-10'>
        <Button appearance='outlined' onClick={handleResetClick}>
          Cancel
        </Button>
        {renderStepActions}
      </DialogActions>
    </Dialog>
  )
}

export default AddContactDialog
