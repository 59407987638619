import { Box, Typography } from "@mui/material"
import type { Row } from "@tanstack/react-table"
import type { ReactElement } from "react"
import { getConvertedUoMValue } from "utils/conversionUtils"
import { pluralize } from "utils/stringUtils"

import { UOM_LABELS } from "@/constants/unitOfMeasurements"
import type { OrderLine } from "@/graphql/codegen/graphql"
import { formatNumber } from "@/utils/formatNumber"

export const OrderQuantityColumnReadOnly = ({ row }: { row: Row<OrderLine> }): ReactElement => {
  const { quantityInUom, uomId, numberOfPallets } = row.original
  const formattedQuantity = formatNumber(quantityInUom)
  const convertedUoMValue = getConvertedUoMValue(quantityInUom, uomId)
  const palletLabel = pluralize(numberOfPallets ?? 0, "Pallet", "Pallets")

  return (
    <Box className='flex gap-2'>
      <Typography>{numberOfPallets}</Typography>
      <Typography>{palletLabel}</Typography>
      <Typography>
        {formattedQuantity} {UOM_LABELS[uomId]} ({convertedUoMValue})
      </Typography>
    </Box>
  )
}
