import { useCallback, useRef } from "react"

import { useThrottle } from "@/hooks/useThrottle"

interface ScrollLoadMoreProps {
  fetching: boolean
  loadMore: () => void
  scrollContainerRef: React.RefObject<HTMLElement>
  threshold?: number
  throttleMs?: number
}

export const useScrollLoadMore = ({
  fetching,
  loadMore,
  scrollContainerRef,
  threshold = 200,
  throttleMs = 150,
}: ScrollLoadMoreProps): (() => void) => {
  const lastScrollTop = useRef(0)
  const lastScrollTime = useRef(Date.now())

  const shouldLoadMore = useCallback((): boolean => {
    const container = scrollContainerRef.current
    if (!container || fetching) return false

    const { scrollTop, clientHeight, scrollHeight } = container
    const isScrollingDown = scrollTop > lastScrollTop.current
    lastScrollTop.current = scrollTop

    // Only load more when scrolling down and near bottom
    if (!isScrollingDown) return false

    const distanceFromBottom = scrollHeight - scrollTop - clientHeight
    const shouldTriggerLoad = distanceFromBottom <= threshold

    // Add time-based throttling
    const now = Date.now()
    const timeSinceLastScroll = now - lastScrollTime.current
    if (timeSinceLastScroll < throttleMs) return false

    if (shouldTriggerLoad) {
      lastScrollTime.current = now
    }

    return shouldTriggerLoad
  }, [fetching, threshold, throttleMs, scrollContainerRef])

  const handleScroll = useCallback(() => {
    if (shouldLoadMore()) {
      loadMore()
    }
  }, [shouldLoadMore, loadMore])

  // Apply throttling to the scroll handler
  const [throttledScroll] = useThrottle(handleScroll, throttleMs)

  return throttledScroll
}
