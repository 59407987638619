import { Box, IconButton, Modal } from "@mui/material"
import { DeleteOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpFilledOutlined } from "@mui-symbols-material/w300"
import type { CellContext } from "@tanstack/react-table"
import type { ReactElement } from "react"
import { useCallback, useState } from "react"

import { useActionsColumn } from "../hooks/useActionsColumn"

import { CancelTemplate } from "@/components/common/Modals/CancelTemplate"
import type { OrderLine } from "@/graphql/codegen/graphql"

export const ActionsCell = ({ cell }: CellContext<OrderLine, unknown>): ReactElement => {
  const { row } = cell.getContext()
  const { onConfirmCancelClick } = useActionsColumn({ row })
  const [isCancelConfirmationOpen, setCancelConfirmationOpen] = useState(false)

  const handleCancel = useCallback(() => {
    setCancelConfirmationOpen(false)
  }, [setCancelConfirmationOpen])

  const handleConfirmCancel = useCallback(() => {
    onConfirmCancelClick()
    setCancelConfirmationOpen(false)
  }, [onConfirmCancelClick])

  return (
    <Box className='flex items-center justify-end gap-4 space-x-4'>
      <IconButton
        className='p-0'
        size='small'
        aria-label='delete the entire row'
        onClick={() => setCancelConfirmationOpen(true)}
      >
        <DeleteOutlined />
      </IconButton>
      <IconButton aria-label='expand row' size='small' onClick={() => row.toggleExpanded()} data-testid='expand-button'>
        {row.getIsExpanded() ? <KeyboardArrowUpFilledOutlined /> : <KeyboardArrowDownOutlined />}
      </IconButton>
      <Modal open={isCancelConfirmationOpen} onClose={handleCancel}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            width: 576,
            borderRadius: 2,
          }}
        >
          <CancelTemplate
            title={`Are you sure you want to delete this product
ID ${row.original.productId} ?`}
            message='This action will affect the order total quantities and total amount.'
            cancelText='Yes, Delete'
            goBackText='Cancel'
            onClose={setCancelConfirmationOpen}
            confirmCancel={handleConfirmCancel}
          />
        </Box>
      </Modal>
    </Box>
  )
}
