import Cookies from "js-cookie"
import { jwtDecode } from "jwt-decode"
import { create } from "zustand"

import type { AuthTokens } from "@/graphql/codegen/graphql"
import { redirectToSignIn, redirectToSignOut } from "@/utils/auth/auth"

export interface User {
  id: string
  fullName: string
  oldId: string
  role: string
  email: string
  avatarUrl?: string
}

interface AuthState {
  tokens: {
    idToken: string | null
    accessToken: string | null
    refreshToken: string | null
  }
  user: User | null
  status: "idle" | "loading" | "authenticated" | "unauthenticated"
  setTokens: (tokens: Partial<AuthTokens & { idToken?: string }>) => void
  setStatus: (status: AuthState["status"]) => void
  clearAuth: () => void
  getUser: () => User | null
  signIn: () => void
  signOut: () => void
  initializeAuth: () => void
}

const COOKIE_CONFIG = {
  secure: true,
  sameSite: "strict" as const,
  path: "/",
  expires: 7, // days
}

export const useAuthStore = create<AuthState>((set, get) => ({
  tokens: {
    idToken: Cookies.get("id_token") ?? null,
    accessToken: Cookies.get("access_token") ?? null,
    refreshToken: Cookies.get("refresh_token") ?? null,
  },
  user: null,
  status: "idle",

  setStatus: (status) => {
    set({ status })
  },

  setTokens: (tokens) => {
    const newTokens = { ...get().tokens }
    let user = null

    if (tokens.idToken) {
      Cookies.set("id_token", tokens.idToken, COOKIE_CONFIG)
      newTokens.idToken = tokens.idToken
      user = get().getUser()
    }
    if (tokens.accessToken) {
      Cookies.set("access_token", tokens.accessToken, COOKIE_CONFIG)
      newTokens.accessToken = tokens.accessToken
    }
    if (tokens.refreshToken) {
      Cookies.set("refresh_token", tokens.refreshToken, COOKIE_CONFIG)
      newTokens.refreshToken = tokens.refreshToken
    }

    set({
      tokens: newTokens,
      user,
      status: "authenticated",
    })
  },

  clearAuth: () => {
    ;["id_token", "access_token", "refresh_token"].forEach((key) => {
      Cookies.remove(key, { path: "/" })
    })

    set({
      tokens: {
        idToken: null,
        accessToken: null,
        refreshToken: null,
      },
      user: null,
      status: "unauthenticated",
    })
  },

  getUser: () => {
    const { idToken } = get().tokens
    if (!idToken) return null

    try {
      const decoded = jwtDecode<{
        "cognito:username": string
        "custom:as400_id": string
        "custom:user_role": string
        "custom:avatar_url": string
        name: string
        email: string
      }>(idToken)

      return {
        id: decoded["cognito:username"],
        oldId: decoded["custom:as400_id"],
        role: decoded["custom:user_role"],
        avatarUrl: decoded["custom:avatar_url"] ?? undefined,
        fullName: decoded.name,
        email: decoded.email,
      }
    } catch (error) {
      console.error("Failed to decode ID token:", error)
      return null
    }
  },

  initializeAuth: () => {
    const { tokens } = get()

    if (tokens.idToken) {
      const user = get().getUser()
      set({
        user,
        status: user ? "authenticated" : "unauthenticated",
      })
    } else {
      set({
        user: null,
        status: "unauthenticated",
      })
    }
  },

  signIn: () => {
    redirectToSignIn()
  },

  signOut: () => {
    get().clearAuth()
    redirectToSignOut()
  },
}))
