import type { Row } from "@tanstack/react-table"
import type { ReactElement } from "react"

import { getPurchaseOrderProductTableColumns } from "../../PurchaseOrder/ProductAdded/components/getPurchaseOrderProductTableColumns"

import { SectionContainer } from "@/components/SectionContainer/SectionContainer"
import { AerosBaseTable } from "@/components/Tables/Aeros/components/AerosBaseTable"
import type { OrderLine } from "@/graphql/codegen/graphql"

export const PurchaseOrdersExapndedRowDetails = (row: Row<any>): ReactElement => {
  const showCompanyName = row?.original?.productDetail?.product?.company?.name
  const columns = getPurchaseOrderProductTableColumns(undefined, true, showCompanyName)
  const products = row.original?.orderLines ?? []

  return (
    <SectionContainer>
      <AerosBaseTable<OrderLine>
        features={{
          tanstackOptions: {
            columns,
            data: products,
          },
        }}
        slotProps={{
          headerCell: { className: "bg-primary-100" },
        }}
        noDataMessage='No products found'
      />
    </SectionContainer>
  )
}
