import { zodResolver } from "@hookform/resolvers/zod"
import type { Row } from "@tanstack/react-table"
import { useCallback, useEffect, useMemo } from "react"
import type { UseFormReturn } from "react-hook-form"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { useShallow } from "zustand/shallow"

import { useOrderLines, useUpdateModifiedFields, useUpdateSectionFields } from "../../contexts/PurchaseOrderContext"
import { PurchaseOrderSection } from "../../types"

import { KILOGRAMS_TO_POUNDS, KILOGRAM_UOM_ID, POUNDS_TO_KILOGRAMS, POUND_UOM_ID } from "@/constants/unitOfMeasurements"
import { OrderLineInputSchema } from "@/graphql/codegen/graphql"
import type { OrderLine, OrderLineInput, OrderUpdateInput, UnitOfMeasurement } from "@/graphql/codegen/graphql"
import { useWeightPerPallet } from "@/screens/Procurement/PurchaseOrder/ProductAdded/hooks/useWeightPerPallet.ts"
import { useUnitOfMeasurementsStore } from "@/stores/unitOfMeasurmentsStore"

interface UseOrderQuantityColumnProps {
  filteredUoM: UnitOfMeasurement[]
  onInputChange: (args: { value: string; name: string }) => void
  methods: UseFormReturn<OrderLineInput>
}

const OrderLineSchema = z.array(OrderLineInputSchema())

export const useOrderQuantityColumn = ({ row }: { row: Row<OrderLine> }): UseOrderQuantityColumnProps => {
  const orderLines = useOrderLines()
  const updateModifiedFields = useUpdateModifiedFields()
  const updateSectionFields = useUpdateSectionFields()

  const selectedOrderLine = useMemo(
    () => orderLines?.find((line) => line.productId === row.original.productId),
    [row.original.productId, orderLines]
  )

  const weightPerPallet = useWeightPerPallet(row)

  const units = useUnitOfMeasurementsStore(useShallow((s) => s.units))

  // Fetching only pounds and kilograms
  const filteredUoM = units.filter(({ id }) => id === POUND_UOM_ID || id === KILOGRAM_UOM_ID)

  const methods = useForm<OrderLine>({
    defaultValues: selectedOrderLine ?? undefined,
    resolver: zodResolver(OrderLineSchema),
    mode: "all",
  })

  useEffect(() => {
    if (selectedOrderLine) {
      methods.reset(selectedOrderLine) // Update the form values when the `selectedOrderLine` changes
    }
  }, [selectedOrderLine, methods.reset]) // Dependency array to re-run effect

  const { setValue, getValues } = methods

  const onInputChange = useCallback(
    ({ value, name }: { value: string; name: string }) => {
      const parsedValue = parseFloat(value) || 0

      // Handles the case where weight per pallet is zero
      const handleZeroWeight = () => {
        switch (name) {
          case "numberOfPallets": {
            setValue(name as keyof OrderLine, parsedValue)
            break
          }
          case "quantityInUom":
            setValue(name as keyof OrderLine, parsedValue)
            break
          case "uomId": {
            const currentQuantity = getValues("quantityInUom")
            const uomFactor = value === POUND_UOM_ID ? KILOGRAMS_TO_POUNDS : POUNDS_TO_KILOGRAMS
            setValue("quantityInUom", currentQuantity * uomFactor)
            setValue(name as keyof OrderLine, value)
            break
          }
        }
      }

      // Handles the case where weight per pallet is non-zero
      const handleNonZeroWeight = () => {
        const currentQuantity = getValues("quantityInUom")
        switch (name) {
          case "numberOfPallets": {
            return {
              numberOfPallets: parsedValue,
              quantityInUom: parsedValue * weightPerPallet,
            }
          }
          case "quantityInUom":
            return {
              quantityInUom: parsedValue,
              numberOfPallets: parsedValue / weightPerPallet,
            }
          case "uomId": {
            const quantityUomFactor = value === POUND_UOM_ID ? KILOGRAMS_TO_POUNDS : POUNDS_TO_KILOGRAMS
            return {
              uomId: value,
              quantityInUom: currentQuantity * quantityUomFactor,
              numberOfPallets: getValues("numberOfPallets"), // currentQuantity / weightPerPallet,
            }
          }
        }
      }

      if (weightPerPallet === 0) {
        handleZeroWeight()
      } else {
        const updatedValues = handleNonZeroWeight()
        if (updatedValues) {
          setValue("numberOfPallets", updatedValues.numberOfPallets)
          setValue("quantityInUom", updatedValues.quantityInUom)
          setValue("uomId", updatedValues.uomId ?? getValues("uomId"))
        }
      }
    },
    [getValues, setValue, weightPerPallet]
  )

  const handleFormDataUpdate = useCallback(
    (formData: OrderLineInput) => {
      if (!formData) return
      const updatedOrderLine = {
        productId: formData.productId,
        quantityInUom: formData.quantityInUom,
        uomId: formData.uomId,
        numberOfPallets: formData.numberOfPallets,
        showPalletsError: formData.numberOfPallets % 1 !== 0, // Setting true to display the error message if the pallets is a decimal
      }
      const updatedOrderLines: OrderLineInput[] = orderLines?.map((line) =>
        line.productId === row.original.productId ? { ...line, ...updatedOrderLine } : line
      )
      updateSectionFields(PurchaseOrderSection.ORDER_LINES, updatedOrderLines)

      updateModifiedFields(PurchaseOrderSection.ORDER_LINES, updatedOrderLines as Partial<OrderUpdateInput>)
    },
    [orderLines, updateSectionFields, updateModifiedFields, row.original.productId]
  )

  useEffect(() => {
    if (orderLines?.length === 0) return

    const subscription = methods.watch((value) => handleFormDataUpdate(value as OrderLineInput))
    return () => subscription.unsubscribe()
  }, [orderLines, methods, handleFormDataUpdate])

  return {
    filteredUoM,
    onInputChange,
    methods,
    weightPerPallet,
  }
}
