import type { FunctionComponent } from "react"
import React from "react"
import { FormProvider } from "react-hook-form"

import { queryClient } from "@/providers/GraphqlRouterProvider.tsx"
import { useEditProduct } from "@/screens/Companies/products/hooks/useEditProduct.tsx"
import { useProductActionsStore } from "@/screens/Companies/products/stores/useProductsActions.tsx"
import { ProductActionsDialogs } from "@/screens/Companies/products/types/schema.ts"
import EditProductModal from "@/screens/Products/components/EditProductModal.tsx"

type EditProductModalContainerProps = {
  productId: number
  executeSearch: (...args: any) => Promise<void>
  currentSearch: any
}

const EditProductModalContainer: FunctionComponent<EditProductModalContainerProps> = ({
  productId,
  executeSearch,
  currentSearch,
}) => {
  const openedDialog = useProductActionsStore((state) => state.openedDialog)
  const setOpenedDialog = useProductActionsStore((state) => state.setOpenedDialog)

  const { methods: editProductModalMethods, handleSubmit: handleEditProductModalSubmit } = useEditProduct({ productId })

  return (
    <FormProvider {...editProductModalMethods}>
      <EditProductModal
        open={openedDialog === ProductActionsDialogs.EDIT_PRODUCT}
        onClose={() => setOpenedDialog(null)}
        onSubmit={async (data) => {
          await handleEditProductModalSubmit(data)
          await executeSearch(queryClient, currentSearch)
        }}
      />
    </FormProvider>
  )
}

export default EditProductModalContainer
