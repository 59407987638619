import dayjs from "dayjs"
import { z } from "zod"

export const AddPricingSchema = z
  .object({
    pricingTiersToCreate: z
      .array(
        z.object({
          unitOfMeasurement: z.object({
            id: z.string().min(1, { message: "Pricing UoM is mandatory" }),
            name: z.string().min(1, { message: "Pricing UoM is mandatory" }),
          }),
          numberOfUnits: z
            .string()
            .refine((value) => value === "" || (!isNaN(Number(value)) && Number(value) > 0), {
              message: "Product Quantity must be a valid number greater than 0",
            })
            .optional(),
          pricePerUnit: z
            .string()
            .refine((value) => value === "" || /^\d+(?:\.\d{1,5})?$/.test(value), {
              message: "Currency/Price must be a valid currency with up to 5 decimal places",
            })
            .optional(),
        })
      )
      .refine((tiers) => tiers[0]?.numberOfUnits && tiers[0]?.pricePerUnit)
      .refine((tiers) =>
        tiers.every((tier, index) => {
          if (index === 0) return true // Skip first index, already validated.
          const hasNumberOfUnits = tier.numberOfUnits?.trim() !== ""
          const hasPricePerUnit = tier.pricePerUnit?.trim() !== ""
          return (!hasNumberOfUnits && !hasPricePerUnit) || (hasNumberOfUnits && hasPricePerUnit)
        })
      ),
    effectiveDate: z.string().min(1, {
      message: "Effective date is mandatory",
    }),
    expirationDate: z.string().optional(),
    freightTerms: z.string().min(1, { message: "Freight terms is mandatory" }),
    incoterms: z.string().min(1, { message: "Incoterms is mandatory" }),
    incotermsLocationId: z.string().min(1, { message: "Incoterms Location is mandatory" }),
    sourceCompanyLocationAssociationId: z.string().optional(),
    pricingPackageId: z.string().optional(),
  })
  .refine(
    (data) =>
      !data.expirationDate ||
      (data.effectiveDate &&
        dayjs(data.effectiveDate).startOf("day").isBefore(dayjs(data.expirationDate).startOf("day"))),
    {
      message: "Valid until date must be after the effective date",
      path: ["expirationDate"],
    }
  )
