import type { Row } from "@tanstack/react-table"
import { useMemo } from "react"

import {
  KILOGRAMS_TO_POUNDS,
  KILOGRAM_UOM_ID,
  POUNDS_TO_KILOGRAMS,
  POUND_UOM_ID,
} from "@/constants/unitOfMeasurements.ts"
import type { OrderLine } from "@/graphql/codegen/graphql.ts"

export const useWeightPerPallet = (row: Row<OrderLine>): number => {
  return useMemo(() => {
    const product = row.original.productDetail.product
    const palletWeight = product?.palletWeight ?? 0
    const palletWeightUom = product?.palletWeightUom
    const uomId = row.original.uomId

    if (palletWeightUom === uomId) {
      return palletWeight
    }

    if (palletWeightUom === POUND_UOM_ID && uomId === KILOGRAM_UOM_ID) {
      return palletWeight * POUNDS_TO_KILOGRAMS
    }

    if (palletWeightUom === KILOGRAM_UOM_ID && uomId === POUND_UOM_ID) {
      return palletWeight * KILOGRAMS_TO_POUNDS
    }

    return 0
  }, [row.original.productDetail.product, row.original.uomId])
}
