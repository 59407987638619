import { Box, Typography } from "@mui/material"
import { AddOutlined, VisibilityOutlined } from "@mui-symbols-material/w300"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"

import { Button } from "../Button"

import GenericComment from "@/components/Comment/Comment"
import { type Comment, CommentCreateInputSchema, CommentParentTableType } from "@/graphql/codegen/graphql"

interface CommentHistoryListProps {
  comments: Comment[]
  onAddComment: (comment: string) => void
  onUpdateComment: (commentId: string, newValue: string) => void
  onArchiveComment: (commentId: string) => void
}

export const CommentHistoryList: React.FC<CommentHistoryListProps> = ({
  comments,
  onAddComment,
  onUpdateComment,
  onArchiveComment,
}) => {
  const [editingCommentIds, setEditingCommentIds] = useState<Record<string, boolean>>({})
  const [newComment, setNewComment] = useState<Comment | null>(null)

  const defaultValues = useMemo(
    () => ({
      comments: comments?.map((comment) => ({ ...comment, value: comment.value || "" })) || [],
    }),
    [comments]
  )

  const methods = useForm({ defaultValues })

  const { fields, replace } = useFieldArray({
    control: methods.control,
    name: "comments",
  })

  useEffect(() => {
    replace(
      comments?.map((comment) => ({
        ...comment,
        value: comment.value || "",
        isEditing: editingCommentIds[comment.commentId || ""] || false,
      })) || []
    )
  }, [comments, replace, editingCommentIds])

  const handleAddComment = useCallback(
    (value: string) => {
      onAddComment(value)
      setNewComment(null)
    },
    [onAddComment]
  )

  const handleUpdateComment = useCallback(
    (commentId: string, value: string) => {
      onUpdateComment(commentId, value)
      setEditingCommentIds((prevState) => ({ ...prevState, [commentId]: false }))
    },
    [onUpdateComment]
  )

  const handleNewComment = useCallback(() => {
    if (!newComment) {
      setNewComment(
        CommentCreateInputSchema().parse({
          commentId: "new",
          value: "",
          createdBy: "",
          referenceId: "",
          type: CommentParentTableType.Company,
          isActive: true,
        })
      )
    }
  }, [newComment])

  const handleEditComment = useCallback((commentId: string) => {
    setEditingCommentIds((prevState) => ({ ...prevState, [commentId]: true }))
  }, [])

  const handleCancelEdit = useCallback((commentId: string) => {
    if (commentId === "new") {
      setNewComment(null)
    }
    setEditingCommentIds((prevState) => ({ ...prevState, [commentId]: false }))
  }, [])

  return (
    <FormProvider {...methods}>
      <Box className='flex h-full flex-col'>
        <Box className='mb-4'>
          <Box display='flex' justifyContent='space-between'>
            <Button appearance='text' startIcon={<AddOutlined />} onClick={handleNewComment} disabled={!!newComment}>
              New Comment
            </Button>
            <Button appearance='text' startIcon={<VisibilityOutlined />}>
              Show Archived
            </Button>
          </Box>
        </Box>

        <Box className='grow overflow-y-auto'>
          {newComment && (
            <GenericComment
              key='new'
              comment={newComment}
              isEditing={true}
              onCancelEdit={() => handleCancelEdit("new")}
              onCommentAction={handleAddComment}
              isLast={fields.length === 0}
            />
          )}

          {fields.map((field, index) => (
            <GenericComment
              key={field.id}
              comment={field as Comment}
              isEditing={editingCommentIds[field.commentId || ""]}
              onEditClick={() => handleEditComment(field.commentId || "")}
              onCancelEdit={() => handleCancelEdit(field.commentId || "")}
              onArchiveClick={() => onArchiveComment(field.commentId || "")}
              onCommentAction={(value: string) => handleUpdateComment(field.commentId || "", value)}
              isLast={index === fields.length - 1}
            />
          ))}

          {fields.length === 0 && !newComment && (
            <Typography variant='body2' color='textSecondary' align='center'>
              No comments yet. Add a new comment to get started.
            </Typography>
          )}
        </Box>
      </Box>
    </FormProvider>
  )
}
