import type { ReactElement } from "react"
import { useMemo } from "react"

import type { Product } from "@/graphql/codegen/graphql"
import { PurchaseOrderDialog } from "@/screens/Procurement/PurchaseOrder/components/PurchaseOrderDialog"
import type { ProductSelectionItem, PurchaseOrderFormData } from "@/screens/Procurement/Supplier/types/types"

type ProductStartPurchaseOrderProps = {
  open: boolean
  fetching: boolean
  product: Product
  onClose: () => void
  onSubmit: (data: PurchaseOrderFormData, selectedProducts: ProductSelectionItem[]) => void
}

export const ProductStartPurchaseOrder = ({
  open,
  fetching,
  product,
  onClose,
  onSubmit,
}: ProductStartPurchaseOrderProps): ReactElement | null => {
  const products = useMemo(() => {
    if (!product) return []
    return [product]
  }, [product])

  if (!product.company || !product) return null

  return (
    <PurchaseOrderDialog
      open={open}
      fetching={fetching}
      company={product.company}
      products={products}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  )
}
