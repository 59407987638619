import { Box } from "@mui/material"
import { useLoaderData, useParams } from "@tanstack/react-router"
import { type ReactElement, memo, useCallback, useEffect, useRef, useState } from "react"

import { useProductFileHandling } from "./hooks/useProductFileHandling"
import { useProductFormHandlers } from "./hooks/useProductFormHandlers"
import { ProductFormDetailsDialog } from "./ProductFormDetailsDialog"
import { ProductFormGrid } from "./ProductFormGrid"
import { ProductFormPicturesEdit } from "./ProductFormPicturesEdit"
import { ProductFormPicturesPreview } from "./ProductFormPicturesPreview"
import { useProductFormImagesStore } from "./stores/useProductFormImagesStore"
import type { ProductForm, ProductFormData } from "./types"
import { ProductFormDialog } from "./types"

import { ProductFormInfoItem } from "@/graphql/codegen/graphql"
import { useGetProduct } from "@/screens/Products/hooks/useGetProduct.tsx"

const ProductFormContent = memo((): ReactElement => {
  const { productForm, units } = useLoaderData({
    from: "/product/$productId/edit/_layout/information",
  }) as ProductForm

  const forms = useRef(Object.entries(ProductFormInfoItem).map(([key, value]) => ({ id: value, name: key }))).current

  const [formState, setFormState] = useState<ProductFormData>({
    productFormInfo: productForm.productFormInfo,
    uomId: productForm.uomId,
    signedUrls: productForm.signedUrls || [],
  })
  const [openedDialog, setOpenedDialog] = useState<ProductFormDialog | null>(null)
  const { productId } = useParams({ from: "/product/$productId/edit/_layout/information" })

  const { handleUpdateProduct } = useProductFormHandlers(productId)
  const { previewFiles, uploading, handleFileSelect, handleSaveFiles, handleCancel } = useProductFileHandling(productId)
  const { productDetails } = useGetProduct(Number(productId))

  const { getAllImageUrls, setSignedUrls } = useProductFormImagesStore()

  const handleClose = useCallback(() => {
    handleCancel()
    setOpenedDialog(null)
  }, [handleCancel])

  const handleFormSubmit = useCallback(
    async (formData: ProductFormData) => {
      try {
        const mergedSignedUrls = await handleSaveFiles()

        const updatedFormData = {
          ...formData,
          signedUrls: mergedSignedUrls,
        } satisfies ProductFormData

        const success = await handleUpdateProduct(updatedFormData)

        if (success) {
          setFormState((prev) => ({
            ...prev,
            ...updatedFormData,
          }))
          setSignedUrls(mergedSignedUrls)
          setOpenedDialog(null)
        }
      } catch (error) {
        console.error("Failed to submit form:", error)
      }
    },
    [handleUpdateProduct, handleSaveFiles, setSignedUrls]
  )

  const handleEditDetails = useCallback(() => {
    setOpenedDialog(ProductFormDialog.EditForm)
  }, [])

  const handleViewPictures = useCallback(() => {
    setOpenedDialog(ProductFormDialog.PreviewImages)
  }, [])

  const handleEditPictures = useCallback(() => {
    setOpenedDialog(ProductFormDialog.EditPictures)
  }, [])

  // Initialize store with signed URLs
  useEffect(() => {
    setSignedUrls(formState.signedUrls || [])
  }, [formState.signedUrls, setSignedUrls])

  useEffect(() => {
    if (!productDetails) return

    const updatedForm = {
      productFormInfo: productDetails.productFormInfo,
      uomId: productDetails.uomId,
    } as ProductFormData

    setFormState((prev) => ({
      ...prev,
      ...updatedForm,
    }))
  }, [productDetails])

  return (
    <>
      <ProductFormGrid
        formInfo={formState.productFormInfo}
        uomId={formState.uomId}
        imageUrls={getAllImageUrls()}
        units={units}
        onEditDetails={handleEditDetails}
        onViewPictures={handleViewPictures}
      />

      <ProductFormDetailsDialog
        open={openedDialog === ProductFormDialog.EditForm}
        formOptions={forms}
        unitOfMeasurementOptions={units}
        onClose={handleClose}
        onSubmit={handleFormSubmit}
        initialData={formState}
        previewFiles={previewFiles}
        onFileSelect={handleFileSelect}
        uploading={uploading}
      />

      <ProductFormPicturesPreview
        open={openedDialog === ProductFormDialog.PreviewImages}
        onClose={() => setOpenedDialog(null)}
        onEdit={handleEditPictures}
        imageUrls={getAllImageUrls()}
      />

      <ProductFormPicturesEdit
        open={openedDialog === ProductFormDialog.EditPictures}
        onClose={handleClose}
        onSubmit={handleFormSubmit}
        signedUrls={formState.signedUrls}
        previewFiles={previewFiles}
        onFileSelect={handleFileSelect}
        uploading={uploading}
      />
    </>
  )
})

ProductFormContent.displayName = "ProductFormContent"

export const ProductFormContainer = memo(
  (): ReactElement => (
    <Box className='flex flex-col gap-6'>
      <ProductFormContent />
    </Box>
  )
)

ProductFormContainer.displayName = "ProductFormContainer"
