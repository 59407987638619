import { Alert, Box, Paper, Snackbar, Tab, Tabs, Typography } from "@mui/material"
import { CheckCircleFilledRounded, WarningOutlined } from "@mui-symbols-material/w300"
import type { AnyRoute } from "@tanstack/react-router"
import { Link, Outlet, createFileRoute, useLocation, useParams } from "@tanstack/react-router"
import React, { useEffect, useState } from "react"
import { useMutation } from "urql"

import {
  type CommentListSuccess,
  type Company,
  type CompanyGetSuccess,
  type CompanyMutation,
  type CompanyMutationUpdateArgs,
  CompanyStatus,
  UpdateNewCompanyMutationDocument,
} from "@/graphql/codegen/graphql.ts"
import CompanyComments from "@/screens/Companies/edit/components/CompanyComments.tsx"
import EditCompanyModal from "@/screens/Companies/edit/components/locations/EditCompanyModal.tsx"
import { useGetComments } from "@/screens/Companies/hooks/useGetComments.tsx"
import { useGetCompanies } from "@/screens/Companies/hooks/useGetCompanies.tsx"
import { useCompanyStore } from "@/screens/Companies/stores/useCompanyStore"
import { useCompanyTypesStore } from "@/screens/Companies/stores/useCompanyTypesStore.ts"
import CompanyHeader from "@/screens/Companies/view/components/CompanyHeader.tsx"
import { deepEqualIgnoreTypename } from "@/utils/deepEqualIgnoreTypename.ts"

const createTabList = (companyId: string) => [
  { label: "Locations", path: `/companies/${companyId}/edit/locations` },
  { label: "Contacts", path: `/companies/${companyId}/edit/contacts` },
  { label: "Products", path: `/companies/${companyId}/edit/products` },
  { label: "Payment", path: `/companies/${companyId}/edit/payment` },
  { label: "Evaluation", path: `/companies/${companyId}/edit/evaluation` },
  { label: "Registration", path: `/companies/${companyId}/edit/registration` },
  { label: "Certification", path: `/companies/${companyId}/edit/certification` },
]

const CompanyEditLayout: React.FC = () => {
  const location = useLocation()
  const { companyId: queryCompanyId } = useParams({ from: "/companies/$companyId/edit" })
  const tabs = createTabList(queryCompanyId)

  // Determine the current tab based on the pathname
  const currentTab = tabs.find((tab) => location.pathname.startsWith(tab.path)) || tabs[0]

  const [open, setOpen] = useState(false)
  const [showSuccessNotification, setShowSuccessNotification] = useState<boolean>(false)
  const [showWarningNotification, setShowWarningNotification] = useState<boolean>(false)
  const { companyDetails, companyDetailsFetching, reexecuteCompanyDetailsQuery } = useGetCompanies(queryCompanyId)
  const { commentDetails, commentDetailsFetching, updateComment, createComment, reexecuteCommentList } =
    useGetComments(queryCompanyId)
  const {
    companyId,
    externalSystemId,
    name,
    status,
    types = [],
    accountManagers = [],
  } = (companyDetails?.company.get as CompanyGetSuccess)?.company || {}
  const company = useCompanyStore((state) => state.company)
  const setCompany = useCompanyStore((state) => state.setCompany)
  const commentList = (commentDetails?.comment.list as CommentListSuccess)?.comments || {}

  const fetchCompanyTypes = useCompanyTypesStore((state) => state.fetchCompanyTypes)
  const companyTypeFetching = useCompanyTypesStore((state) => state.fetching)
  const companyTypes = useCompanyTypesStore((state) => state.companyTypes)

  const [, updateCompany] = useMutation<CompanyMutation, CompanyMutationUpdateArgs>(UpdateNewCompanyMutationDocument)

  useEffect(() => {
    if (companyDetails?.company.get.__typename === "CompanyGetSuccess") {
      setCompany(companyDetails?.company.get.company)
    }
  }, [companyDetails])

  useEffect(() => {
    if (!companyTypeFetching && companyTypes.length === 0) {
      fetchCompanyTypes()
    }
  }, [companyTypeFetching, companyTypes, fetchCompanyTypes])

  // Fetching the last comment details
  const { commentId, updatedAt, updatedBy, value } = commentList?.[commentList.length - 1] || {}

  const handleCompanyEditModalClose = (updatedCompany: Company | undefined) => {
    setCompany(updatedCompany as Company)
    reexecuteCompanyDetailsQuery()
    setOpen(false)
  }

  const handleCompanyEditModalSuccess = (updatedCompany: Company) => {
    const hasChanges = !deepEqualIgnoreTypename(updatedCompany, {
      name,
      types,
      accountManagers,
    })

    if (!hasChanges) {
      setOpen(false)
      setShowWarningNotification(true)
      return
    }
    setShowSuccessNotification(true)
  }

  const closeSnackbar = () => {
    setShowSuccessNotification(false)
    setShowWarningNotification(false)
  }

  const handleCompanyActivate = () => {
    updateCompany({
      input: {
        companyId,
        status: CompanyStatus.Active,
        types: types ?? [],
      },
    })
    setCompany({ ...company, status: CompanyStatus.Active } as Company)
  }

  const handleCompanyDeactivate = () => {
    updateCompany({
      input: {
        companyId,
        status: CompanyStatus.Inactive,
        types: types ?? [],
      },
    })
    setCompany({ ...company, status: CompanyStatus.Inactive } as Company)
  }

  return (
    <Box className='size-full px-6 pb-6'>
      {companyDetailsFetching && commentDetailsFetching ? (
        <Typography variant='h5'>Loading...</Typography>
      ) : (
        <div className='flex h-full flex-col gap-4'>
          <Paper elevation={0} className='flex min-h-0 grow flex-col space-y-7 overflow-hidden bg-white p-6'>
            <Box className='flex items-center justify-between'>
              <Typography variant='h5' color='primary' fontWeight='normal'>
                {company?.status === CompanyStatus.Pending ? "Create New Company" : ""}
              </Typography>
            </Box>
            <Box className='space-y-2.5'>
              <CompanyHeader
                comments={commentList}
                fetchComments={reexecuteCommentList}
                companyId={companyId}
                externalSystemId={externalSystemId}
                name={company?.name ?? name}
                status={company?.status ?? status}
                types={company?.types ?? types}
                accountManagers={accountManagers}
                onEditClick={setOpen}
                onActivate={handleCompanyActivate}
                onDeactivate={handleCompanyDeactivate}
              />
              {company?.status === CompanyStatus.Inactive && (
                <CompanyComments
                  companyId={companyId}
                  commentId={commentId}
                  updatedAt={updatedAt}
                  value={value}
                  updatedBy={updatedBy}
                  onCreate={createComment}
                  onUpdate={updateComment}
                  reexecuteCommentList={reexecuteCommentList}
                />
              )}
            </Box>
            <Box className='flex w-full flex-1 flex-col overflow-y-auto'>
              <Tabs value={currentTab.path} variant='scrollable' scrollButtons='auto'>
                {tabs.map((tab, index) => (
                  <Tab key={index} label={tab.label} value={tab.path} component={Link} to={tab.path} />
                ))}
              </Tabs>
              <Box className='flex min-h-0 grow flex-col py-4'>
                <Outlet />
              </Box>
            </Box>
            <EditCompanyModal
              isOpen={open}
              data={{
                name,
                types,
                accountManagers,
              }}
              companyId={companyId}
              onClose={handleCompanyEditModalClose}
              onSuccess={handleCompanyEditModalSuccess}
            />
            <Snackbar
              open={showWarningNotification || showSuccessNotification}
              autoHideDuration={3000}
              onClose={closeSnackbar}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert
                onClose={closeSnackbar}
                severity={showWarningNotification ? "warning" : "success"}
                variant='filled'
                sx={{ width: "100%" }}
                classes={{
                  root: showWarningNotification ? "bg-[#FFF4E5] text-[#663C00]" : "bg-success-100 text-[#1E4620]",
                }}
                icon={
                  showWarningNotification ? (
                    <WarningOutlined className='ml-2 text-[#EF6C00]' />
                  ) : (
                    <CheckCircleFilledRounded className='ml-2 text-success-500' />
                  )
                }
              >
                <p>
                  {showWarningNotification
                    ? "No changes updated to your company profile"
                    : "Company profile successfully updated."}
                </p>
              </Alert>
            </Snackbar>
          </Paper>
        </div>
      )}
    </Box>
  )
}

export const Route: AnyRoute = createFileRoute("/companies/$companyId/edit/_layout")({
  component: CompanyEditLayout,
})
