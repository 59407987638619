import { Box, Divider, IconButton, TextareaAutosize, Typography } from "@mui/material"
import { AddOutlined, EditOutlined, VisibilityOutlined } from "@mui-symbols-material/w300"
import { Fragment, useState } from "react"
import type { FC, ReactNode } from "react"

import { Button } from "@/components/common/Button"
import type { Comment } from "@/graphql/codegen/graphql"

interface PageCommentsProps {
  comments: Comment[]
  onAddComment: (show: boolean) => void
  onUpdateComment: (commentId: string, value: string) => void
}

const PageComments: FC<PageCommentsProps> = ({ comments, onAddComment, onUpdateComment }) => {
  const [updateCommentId, setUpdateCommentId] = useState<string>("")

  const handleEditComment = async (commentId: string, value: string) => {
    setUpdateCommentId("")
    onUpdateComment(commentId, value)
  }

  const formatDate = (dateString: string | null | undefined) => {
    if (!dateString) return ""
    const date = new Date(dateString)
    return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`
  }

  return (
    <>
      <Box className='flex items-start gap-4 px-0'>
        <Button
          variant='primary'
          appearance='text'
          className='pl-0'
          startIcon={<AddOutlined />}
          onClick={() => onAddComment(true)}
        >
          New Comment
        </Button>
        <Button variant='primary' appearance='text' startIcon={<VisibilityOutlined />}>
          Archived Comments
        </Button>
      </Box>
      <Box className='w-full items-center justify-between py-3'>
        {comments.map((comment) => {
          const { updatedAt, createdAt, updatedByUser, createdByUser, value, commentId } = comment
          if (!value) return null
          return (
            <Fragment key={commentId}>
              <Divider />
              {updateCommentId === commentId ? (
                <EditComment value={value} commentId={commentId} onEditComment={handleEditComment} />
              ) : (
                <Box className='group relative pl-3 pt-3 hover:bg-primary-100'>
                  <Box className='group flex w-full justify-between'>
                    <Typography variant='caption' className='text-gray-600'>
                      Posted: {formatDate(createdAt)} by {createdByUser?.details?.name}
                      {updatedAt && (
                        <>
                          <br />
                          Updated: {formatDate(updatedAt)} by {updatedByUser?.details?.name}
                        </>
                      )}
                    </Typography>
                    <IconButton
                      color='primary'
                      aria-label='edit comment'
                      size='small'
                      className='absolute right-2.5 top-0.5 hidden rounded-none group-hover:block'
                      onClick={() => setUpdateCommentId(commentId ?? "")}
                    >
                      <EditOutlined className='mr-1' />
                      <Typography variant='caption' className='text-xs'>
                        Edit
                      </Typography>
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography variant='body2' className='mt-2 truncate pb-3 text-gray-800'>
                      {value}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Fragment>
          )
        })}
      </Box>
    </>
  )
}

export default PageComments

const EditComment = ({
  value,
  commentId,
  onEditComment,
}: {
  value: string
  commentId: string
  onEditComment: (commentId: string, value: string) => void
}): ReactNode => {
  const [updatedValue, setUpdatedValue] = useState<string>(value)

  return (
    <div className='relative w-full pt-2'>
      <TextareaAutosize
        minRows={1.5}
        maxRows={1.5}
        className='overflowY-auto w-full rounded-md border border-solid border-gray-300 p-4 pr-[120px] text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#BFDBFE]'
        value={updatedValue}
        onChange={(e) => setUpdatedValue(e.target.value)}
      />
      <Button
        onClick={() => onEditComment(commentId, updatedValue)}
        className='absolute right-8 top-6'
        disabled={updatedValue === value}
      >
        Update
      </Button>
    </div>
  )
}
