import { Box, Checkbox, MenuItem } from "@mui/material"
import { type FC } from "react"
import { type Control, Controller, useFormContext } from "react-hook-form"

import InstructionList from "./InstructionList"

import MultiSelect from "@/components/MultiSelect/MultiSelect"
import { ApplicableToEnum, IncludeWithEnum, TransportationModeEnum } from "@/graphql/codegen/graphql"

const includedWithLabelLookup: Record<IncludeWithEnum, string> = {
  [IncludeWithEnum.Billoflading]: "Bill of Lading",
  [IncludeWithEnum.Packingslip]: "Packing Slip",
  [IncludeWithEnum.Picklist]: "Picklist",
  [IncludeWithEnum.Proformainvoice]: "Proforma Invoice",
  [IncludeWithEnum.Putaway]: "Putaway",
  [IncludeWithEnum.ShippingLabel]: "Shipping Label",
  [IncludeWithEnum.Po]: "Purchase Order",
}

type FormValues = {
  applicableTo: ApplicableToEnum[]
  transportationMode: TransportationModeEnum[]
  includedWith: IncludeWithEnum[]
  productInstructionId: string[]
  groupId?: string
}

export const AddInstructionsContent: FC = () => {
  const { control, watch } = useFormContext<FormValues>()

  const applicableTo = watch("applicableTo") || []
  const transportationMode = watch("transportationMode") || []
  const includedWith = watch("includedWith") || []

  const showInstructionsField = applicableTo.length > 0 && transportationMode.length > 0 && includedWith.length > 0

  return (
    <form>
      <Box className='space-y-7'>
        <Controller
          name='applicableTo'
          control={control as Control<FormValues>}
          render={({ field }) => (
            <MultiSelect
              name='applicableTo'
              control={control as Control<FormValues>}
              placeholder='Select at least one'
              label='Applicable To'
              required
            >
              {Object.values(ApplicableToEnum).map((value) => (
                <MenuItem key={value} value={value}>
                  <Checkbox checked={field.value?.includes(value)} />
                  {value}
                </MenuItem>
              ))}
            </MultiSelect>
          )}
        />
        <Controller
          name='transportationMode'
          control={control as Control<FormValues>}
          render={({ field }) => (
            <MultiSelect
              name='transportationMode'
              control={control as Control<FormValues>}
              placeholder='Select at least one'
              label='Transportation Mode'
              required
            >
              {Object.values(TransportationModeEnum).map((value) => (
                <MenuItem key={value} value={value}>
                  <Checkbox checked={field.value?.includes(value)} />
                  {value}
                </MenuItem>
              ))}
            </MultiSelect>
          )}
        />
        <Controller
          name='includedWith'
          control={control as Control<FormValues>}
          render={({ field }) => (
            <MultiSelect
              name='includedWith'
              control={control as Control<FormValues>}
              label='Include With'
              placeholder='Select at least one'
              required
            >
              {Object.values(IncludeWithEnum).map((value) => (
                <MenuItem key={value} value={value}>
                  <Checkbox checked={field.value?.includes(value)} />
                  {includedWithLabelLookup[value]}
                </MenuItem>
              ))}
            </MultiSelect>
          )}
        />
        {showInstructionsField && (
          <Controller
            name='productInstructionId'
            control={control as Control<FormValues>}
            render={() => (
              <InstructionList
                name='productInstructionId'
                control={control as Control<FormValues>}
                label='Instruction'
              />
            )}
          />
        )}
      </Box>
    </form>
  )
}
