import { useCallback, useMemo, useState } from "react"

import { usePurchaseOrder } from "../../hooks/usePurchaseOrder"
import type { ProductSelection, ProductSelectionItem, PurchaseOrderFormData } from "../types/types"

import type { Company } from "@/graphql/codegen/graphql"

type UseSupplierReportPurchaseOrderProps = {
  data: Company[]
  productRowSelection: ProductSelection
}

type UseSupplierReportPurchaseOrderReturn = {
  fetching: boolean
  shippingLocationMap: Record<string, ProductSelectionItem[]>
  isPurchaseOrderDialogOpen: boolean
  selectedCompany: Company | undefined
  selectedCompanyProducts: ProductSelectionItem[]
  handleStartPO: (companyId: string) => void
  handleCloseDialog: () => void
  handleSubmitPO: (data: PurchaseOrderFormData) => void
}

export const useSupplierReportPurchaseOrder = ({
  data,
  productRowSelection,
}: UseSupplierReportPurchaseOrderProps): UseSupplierReportPurchaseOrderReturn => {
  const [isPurchaseOrderDialogOpen, setPurchaseOrderDialogOpen] = useState(false)
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>()

  const { handleCreatePO, fetching } = usePurchaseOrder()

  const selectedCompany = useMemo(() => {
    if (!selectedCompanyId) return undefined
    return data.find((company) => company.companyId === selectedCompanyId)
  }, [data, selectedCompanyId])

  const selectedCompanyProducts = useMemo(() => {
    if (!selectedCompanyId) return []
    return Object.values(productRowSelection[selectedCompanyId] || {})
  }, [productRowSelection, selectedCompanyId])

  const selectedProducts = useMemo(() => {
    return Object.values(productRowSelection).flatMap((companyProducts) => Object.values(companyProducts))
  }, [productRowSelection])

  // Creates a map of products with same shippingId in locationsAssociations of a product
  const shippingLocationMap = useMemo(() => {
    return selectedProducts.reduce(
      (acc, product) => {
        if (product.locationsAssociations.length > 0) {
          acc[product.locationsAssociations[0].shippingId] = (
            acc[product.locationsAssociations[0].shippingId] || []
          ).concat(product)
        }
        return acc
      },
      {} as Record<string, ProductSelectionItem[]>
    )
  }, [selectedProducts])

  const handleStartPO = useCallback(
    (companyId: string) => {
      setSelectedCompanyId(companyId)
      setPurchaseOrderDialogOpen(true)
    },
    [setPurchaseOrderDialogOpen, setSelectedCompanyId]
  )

  const handleCloseDialog = useCallback(() => {
    setPurchaseOrderDialogOpen(false)
    setSelectedCompanyId(undefined)
  }, [setPurchaseOrderDialogOpen, setSelectedCompanyId])

  const handleSubmitPO = useCallback(
    (data: PurchaseOrderFormData) => {
      handleCreatePO(data, selectedCompanyProducts)
      handleCloseDialog()
    },
    [handleCloseDialog, handleCreatePO]
  )

  return {
    fetching,
    shippingLocationMap,
    isPurchaseOrderDialogOpen,
    selectedCompany,
    selectedCompanyProducts,
    handleStartPO,
    handleCloseDialog,
    handleSubmitPO,
  }
}
