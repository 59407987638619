import { Box, IconButton, Tooltip } from "@mui/material"
import { InfoOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from "@mui-symbols-material/w300"
import type { CellContext, ColumnDef } from "@tanstack/react-table"
import { useMemo } from "react"

import { ActionsComponent } from "../ActionsComponent"

import { StatusIndicator } from "@/components/common/StatusIndicator/StatusIndicator"
import type { Location } from "@/graphql/codegen/graphql"
import type {
  LocationConfirmationDialogs,
  LocationMenuDialogs,
} from "@/screens/Companies/edit/components/locations/types/dialogs"
import { createStatusMap } from "@/utils/statusMap"

const locationStatusMap = createStatusMap<boolean, "secondary" | "success">([
  [true, "success"],
  [false, "secondary"],
])

export const useLocationTableColumns = (
  memoizedCountryOptions: { value: string; label: string }[],
  setOpenedDialog: (dialog: LocationMenuDialogs | LocationConfirmationDialogs | null) => void,
  setActiveEditLocation: (location: Location) => void
): ColumnDef<Location, unknown>[] => {
  return useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Location Name",
        size: 240,
        cell: ({ row, cell }: CellContext<Location, unknown>) => {
          const tooltipTitle = [
            row.original.externalLocationType,
            row.original.externalCompanyId && row.original.externalLocationId
              ? `${row.original.externalCompanyId}-${row.original.externalLocationId}`
              : row.original.externalCompanyId || row.original.externalLocationId,
          ]
            .filter(Boolean)
            .join(" ")
          return (
            <Box className='flex items-center space-x-4'>
              <StatusIndicator
                data-testid={`${cell.id}-${(cell.getValue() as Location).locationId}`}
                status={row.original.isActive}
                map={locationStatusMap}
              />
              <Box className='flex items-center space-x-2'>
                {tooltipTitle ? (
                  <Tooltip title={tooltipTitle} arrow>
                    <Box className='flex items-center space-x-1'>
                      <span>{cell.getValue() as string}</span>
                      <InfoOutlined fontSize='small' />
                    </Box>
                  </Tooltip>
                ) : (
                  <span>{cell.getValue() as string}</span>
                )}
              </Box>
            </Box>
          )
        },
      },
      {
        accessorKey: "address1",
        header: "Address",
        cell: ({ row }: CellContext<Location, unknown>) => {
          const location = row.original
          const country = memoizedCountryOptions.find((c) => c.value === location?.countryIsoCode)?.label
          return [
            location?.address1,
            location?.address2,
            location?.city,
            location?.regionIsoCode,
            country,
            location?.postalCode,
          ]
            .filter(Boolean)
            .join(", ")
        },
        size: 288,
      },
      {
        accessorKey: "notes",
        header: "",
        enableSorting: false,
        cell: () => "",
      },
      {
        accessorKey: "actions",
        header: "",
        cell: ({ row }: CellContext<Location, unknown>) => {
          const handleOpen = (dialog: LocationMenuDialogs | LocationConfirmationDialogs | null) => {
            if (!dialog) return

            setActiveEditLocation(row.original)
            setOpenedDialog(dialog)
          }
          return <ActionsComponent row={row} setOpenedDialog={handleOpen} />
        },
        size: 50,
        enableSorting: false,
      },
      {
        accessorKey: "expander",
        enableSorting: false,
        header: "",
        cell: ({ row }: CellContext<Location, unknown>) => (
          <IconButton onClick={row.getToggleExpandedHandler()} className='p-1'>
            {row.getIsExpanded() ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
          </IconButton>
        ),
        size: 50,
      },
    ],
    [memoizedCountryOptions, setOpenedDialog, setActiveEditLocation]
  )
}
