import type { Client, CombinedError } from "urql"
import { create } from "zustand"

import {
  GetProductListWithOrdersDocument,
  type OrderListInput,
  OrderSortField,
  type ProductListInput,
  ProductSortField,
  SortingOrder,
} from "@/graphql/codegen/graphql.ts"
import {
  orderSortFieldToAccessoryKey,
  productOrderSortFieldToAccessoryKey,
} from "@/screens/Procurement/PurchaseOrders/types"

type ProductPurchaseOrderSearchFilters = {
  query?: string
  category?: string
  field?: OrderSortField | ProductSortField
  order?: SortingOrder
  start?: string
  end?: string
  [key: string]: unknown
}

type PaginationState = {
  offset: number
  limit: number
  hasMore: boolean
}

export type ProductPurchaseOrderSearchState = {
  data: unknown[]
  fetching: boolean
  error: CombinedError | Error | undefined
  pagination: PaginationState
  lastExecutedFilters: ProductPurchaseOrderSearchFilters
  setFetching: (fetching: boolean) => void
  setError: (error: CombinedError | Error | undefined) => void
  resetSearch: () => void
  setData: (data: unknown[]) => void
  executeSearch: (client: Client, filters: ProductPurchaseOrderSearchFilters) => Promise<void>
  loadMore: (client: Client, filters: ProductPurchaseOrderSearchFilters) => Promise<void>
}

const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: undefined,
  pagination: {
    offset: 0,
    limit: 10,
    hasMore: true,
  },
  lastExecutedFilters: {},
}

const PURCHASE_ORDER_LIMIT = 50

const isProductSortField = (value: any): value is ProductSortField => productOrderSortFieldToAccessoryKey.has(value)

const isOrderSortField = (value: any): value is OrderSortField => orderSortFieldToAccessoryKey.has(value)

export const useProductPurchaseOrdersSearchStore = create<ProductPurchaseOrderSearchState>((set, get) => ({
  ...INITIAL_STATE,
  setFetching: (fetching) => set({ fetching }),
  setError: (error) => set({ error }),
  resetSearch: () => set(INITIAL_STATE),
  setData: (data) =>
    set((state) => ({
      data,
      error: undefined,
      pagination: {
        ...state.pagination,
        offset: 0,
        hasMore: data.length === state.pagination.limit,
      },
    })),

  executeSearch: async (client: Client, filters: ProductPurchaseOrderSearchFilters) => {
    const state = get()
    if (state.fetching) return

    const { query, ...restFilters } = filters || {}
    const validQuery = typeof query === "string" && query.length >= 3 ? query : undefined
    const currentFilters = { ...restFilters, ...(validQuery ? { query: validQuery } : {}) }

    if (JSON.stringify(currentFilters) === JSON.stringify(state.lastExecutedFilters)) return

    const shouldExecuteSearch = validQuery || Object.keys(restFilters).length > 0
    if (!shouldExecuteSearch) {
      set({
        data: [],
        pagination: { ...state.pagination, offset: 0, hasMore: false },
        lastExecutedFilters: {},
      })
      return
    }

    set({ fetching: true })

    try {
      const { category, field, order, start, end, query, ...otherFilters } = currentFilters

      const queryInput = {
        filter: { hasOrders: true, query },
        offset: 0,
        limit: state.pagination.limit,
        sort: {
          field: isProductSortField(field) ? field : ProductSortField.CreatedOn,
          order: order ?? SortingOrder.Desc,
        },
      } as ProductListInput

      const orderInput = {
        filter: {
          ...otherFilters,
          ...(start && {
            sentDateRange: {
              start,
              end,
            },
          }),
        },
        offset: 0,
        limit: PURCHASE_ORDER_LIMIT,
        sort: {
          field: isOrderSortField(field) ? field : OrderSortField.UpdatedOn,
          order,
        },
      } as OrderListInput

      const result = await client
        .query(GetProductListWithOrdersDocument, { input: queryInput, orderInput: orderInput })
        .toPromise()

      if (result.data?.product?.list.__typename === "ProductListSuccess") {
        const products = result.data.product.list.products

        const transformedData = products.flatMap((product) => {
          if (product?.orders?.__typename === "OrderListSuccess") {
            return product.orders.orders.map((order) => {
              return {
                ...product,
                ...order,
                ...order.orderLines?.[0],
              }
            })
          }
          return []
        })

        set({
          data: transformedData,
          pagination: {
            ...state.pagination,
            offset: 0,
            hasMore: products.length === state.pagination.limit,
          },
          lastExecutedFilters: currentFilters,
        })
      }

      if (result.error) set({ error: result.error })
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },

  loadMore: async (client: Client, filters: ProductPurchaseOrderSearchFilters) => {
    const state = get()
    if (state.fetching || !state.pagination.hasMore) return

    const { query, ...restFilters } = filters || {}
    const validQuery = typeof query === "string" && query.length >= 3 ? query : undefined
    const currentFilters = { ...restFilters, ...(validQuery ? { query: validQuery } : {}) }

    if (JSON.stringify(currentFilters) !== JSON.stringify(state.lastExecutedFilters)) {
      await state.executeSearch(client, filters)
      return
    }

    set({ fetching: true })
    const newOffset = state.pagination.offset + state.pagination.limit

    try {
      const { category, field, order, start, end, query, ...otherFilters } = currentFilters

      const queryInput = {
        filter: { hasOrders: true, query },
        offset: newOffset,
        limit: state.pagination.limit,
        sort: {
          field: isProductSortField(field) ? field : ProductSortField.CreatedOn,
          order: order ?? SortingOrder.Desc,
        },
      } as ProductListInput

      const orderInput = {
        filter: {
          ...otherFilters,
          ...(start && {
            sentDateRange: {
              start,
              end,
            },
          }),
        },
        offset: 0,
        limit: PURCHASE_ORDER_LIMIT,
        sort: { field: isOrderSortField(field) ? field : OrderSortField.UpdatedOn, order },
      } as OrderListInput

      const result = await client
        .query(GetProductListWithOrdersDocument, { input: queryInput, orderInput: orderInput })
        .toPromise()

      if (result.data?.product?.list.__typename === "ProductListSuccess") {
        const products = result.data.product.list.products

        const transformedData = products.flatMap((product) => {
          if (product?.orders?.__typename === "OrderListSuccess") {
            return product.orders.orders.map((order) => {
              return {
                ...product,
                ...order,
                ...order.orderLines?.[0],
              }
            })
          }
          return []
        })

        set({
          data: [...state.data, ...transformedData],
          pagination: {
            ...state.pagination,
            offset: 0,
            hasMore: transformedData.length === state.pagination.limit,
          },
          lastExecutedFilters: currentFilters,
        })
      }

      if (result.error) set({ error: result.error })
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },
}))
