import { RouterProvider, createRouter } from "@tanstack/react-router"
import type { ReactElement } from "react"
import { Provider as UrqlProvider, cacheExchange, createClient, fetchExchange } from "urql"

import { routeTree } from "../../routeTree.gen"

import { useAuthStore } from "@/stores/authStore"
import { auth } from "@/utils/auth/authExchange"
import env from "@/utils/env"

// Define router context type
export interface RouterContext {
  auth: ReturnType<typeof useAuthStore.getState>
  queryClient: ReturnType<typeof createUrqlClient>
}

// Create URQL client
const createUrqlClient = () =>
  createClient({
    url: env.VITE_API_ORIGIN,
    exchanges: [cacheExchange, auth, fetchExchange],
  })

// Create router with proper context type
export const router = createRouter({
  routeTree,
  context: {
    auth: undefined!,
    queryClient: undefined!,
  } as RouterContext,
  defaultPreload: "intent",
  defaultPreloadDelay: 0,
})

// Register router type
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
}

export const queryClient = createUrqlClient()

// GraphQL and Router Provider component
export function GraphqlRouterProvider(): ReactElement {
  const auth = useAuthStore()

  return (
    <UrqlProvider value={queryClient}>
      <RouterProvider
        router={router}
        context={{
          auth,
          queryClient,
        }}
      />
    </UrqlProvider>
  )
}
