import { Box, Typography } from "@mui/material"
import { CheckCircleFilledRounded, CloseFilledOutlined } from "@mui-symbols-material/w300"
import type { FC, ReactElement } from "react"

import { Button } from "@/components/common/Button"

interface SuccessTemplateProps {
  onClose: (open: boolean) => void
  confirmCancel: () => void
  onConfirm: () => void
  title?: string
  message?: ReactElement
  goBackText?: string
  submitText?: string
  classes?: {
    actionButtons?: string
  }
}

export const SuccessTemplate: FC<SuccessTemplateProps> = ({
  onClose,
  confirmCancel,
  onConfirm,
  title = "Are you sure you want to submit?",
  message = "By submitting now your the information will be saved. Click on “Go Back” if this action was a mistake.",
  goBackText = "Go Back",
  submitText = "Yes, Submit",
  classes = {
    actionButtons: "flex justify-end",
  },
}) => (
  <>
    <Box pt={2} pr={2} className='flex'>
      <Button size='small' appearance='text' onClick={() => onClose(false)} className='w-full justify-end'>
        <CloseFilledOutlined className='text-blue-gray' />
      </Button>
    </Box>
    <Box p={5} pt={2} className='align-center w-full justify-center text-center'>
      <CheckCircleFilledRounded className='mb-4 size-[48px] text-tertiary-green-500' />
      <Typography variant='h5' color='primary' className='font-normal'>
        {title}
      </Typography>
      <Typography
        variant='h6'
        className='mt-6 px-3 text-base font-normal leading-[150%] tracking-[-0.85px] text-gray-700'
      >
        {message}
      </Typography>
      <Box mt={5} className={classes.actionButtons}>
        <Button appearance='text' variant='primary' className='mr-2' onClick={confirmCancel}>
          {goBackText}
        </Button>
        <Button variant='primary' onClick={onConfirm}>
          {submitText}
        </Button>
      </Box>
    </Box>
  </>
)
