// O(n) time complexity where n is number of locations
// O(n) space complexity for storing unique keys
export const deduplicateLocations = <T extends { locationId: string }>(locations: T[]): T[] => {
  // Using object literal for faster key lookups compared to Map
  const uniqueLocations: Record<string, T> = {}

  // Single pass through array with direct object property access
  for (let i = 0; i < locations.length; i++) {
    const location = locations[i]
    uniqueLocations[location.locationId] = location
  }

  return Object.values(uniqueLocations)
}
