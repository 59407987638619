import type { CellContext, ColumnDef } from "@tanstack/react-table"
import type { ReactElement } from "react"

import { StatusIndicator } from "@/components/common/StatusIndicator/StatusIndicator.tsx"
import type { Location } from "@/graphql/codegen/graphql.ts"
import { CompanyStatus, LocationSortField } from "@/graphql/codegen/graphql.ts"
import { createStatusMap } from "@/utils/statusMap.ts"

export const companyStatusMap = createStatusMap<
  CompanyStatus,
  "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning"
>([
  [CompanyStatus.Active, "success"],
  [CompanyStatus.Pending, "warning"],
  [CompanyStatus.Rejected, "error"],
  [CompanyStatus.Inactive, "secondary"],
])

export type SortableAccessorKey = "company_name" | "name"

export const accessorKeyToLocationSortField: Map<SortableAccessorKey, LocationSortField> = new Map([
  ["company_name", LocationSortField.CompanyName],
  ["name", LocationSortField.LocationName],
])

export const locationSortFieldToAccessoryKey: Map<LocationSortField, SortableAccessorKey> = new Map([
  [LocationSortField.CompanyName, "company_name"],
  [LocationSortField.LocationName, "name"],
])

export const columns: ColumnDef<Location>[] = [
  {
    accessorKey: "isActive",
    header: "",
    cell: ({ getValue, cell }: CellContext<Location, unknown>): ReactElement => {
      const status = getValue() ? CompanyStatus.Active : CompanyStatus.Inactive
      return <StatusIndicator data-testid={`${cell.id}-${status}`} status={status} map={companyStatusMap} />
    },
    size: 24,
    enableSorting: false,
  },
  {
    accessorKey: "company.name",
    header: "Company",
    sortingFn: "alphanumeric",
    enableSorting: true,
  },
  {
    accessorKey: "name",
    header: "Location Name",
    sortingFn: "alphanumeric",
  },
  {
    accessorKey: "",
    header: "Location Address",
    cell: ({ row }: CellContext<Location, unknown>): ReactElement => {
      const location = row.original
      const addressParts = [
        location.address1,
        location.address2,
        location.city,
        location.region?.name,
        location.country?.name,
      ].filter(Boolean)

      return <>{addressParts.join(", ")}</>
    },
  },
]
