import { Box, FormControl, InputAdornment, MenuItem, Select, TextField } from "@mui/material"
import type { SelectChangeEvent } from "@mui/material/Select/SelectInput"
import { FilterAltOutlined, KeyboardArrowDownOutlined, SearchOutlined } from "@mui-symbols-material/w300"
import { useNavigate, useSearch } from "@tanstack/react-router"
import type { ChangeEvent, ReactElement, ReactNode } from "react"
import { memo, useMemo } from "react"

import { Button } from "@/components/common/Button"
import { getRefinedSearchParams } from "@/screens/Companies/utils"
import { PurchaseOrderSearchCategory } from "@/screens/Procurement/types/enums.ts"

type PurchaseOrdersSearchBarProps = {
  onShowFilter: () => void
}

const SelectIcon = memo(({ className }: { className: string }) => (
  <KeyboardArrowDownOutlined className={`text-gray-500 ${className}`} />
))

const hintMessageMap = new Map<PurchaseOrderSearchCategory, string>([
  [PurchaseOrderSearchCategory.SUPPLIER, "Search by Supplier Name or ID"],
  [PurchaseOrderSearchCategory.PRODUCT, "Search by Product Name or ID"],
])

export const PurchaseOrdersSearchBar = ({ onShowFilter }: PurchaseOrdersSearchBarProps): ReactElement => {
  const currentSearch = useSearch({ from: "/procurement/_layout/purchase-orders" })
  const navigate = useNavigate({ from: "/procurement/_layout/purchase-orders" })
  const { category, query, statuses } = currentSearch
  const searchHint = useMemo(() => hintMessageMap.get(category), [category])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const queryValue = e.target.value

    const params = getRefinedSearchParams(currentSearch, {
      ...currentSearch,
      query: queryValue,
    })

    navigate(params)
  }

  const handleSearchCategoryDropdownChange = (event: SelectChangeEvent<PurchaseOrderSearchCategory>, _: ReactNode) => {
    const value = event?.target?.value as PurchaseOrderSearchCategory

    const params = getRefinedSearchParams({
      statuses,
      category: value,
    })

    navigate(params)
  }

  return (
    <>
      <Box className='flex space-x-4'>
        <Box className='flex w-full max-w-lg items-center overflow-hidden rounded-lg border border-gray-400'>
          <FormControl className='min-w-32 border-r border-gray-300'>
            <Select<PurchaseOrderSearchCategory>
              data-testid='search-category-select'
              value={category}
              className='min-w-fit rounded-r-none border-r border-gray-400 px-3'
              inputProps={{
                className: "py-2 pr-6 pl-2 bg-transparent text-gray-700 font-thin w-full text-ellipsis",
              }}
              IconComponent={SelectIcon}
              variant='outlined'
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
              onChange={handleSearchCategoryDropdownChange}
            >
              <MenuItem data-testid='supplier-option' value={PurchaseOrderSearchCategory.SUPPLIER}>
                Supplier
              </MenuItem>
              <MenuItem data-testid='product-option' value={PurchaseOrderSearchCategory.PRODUCT}>
                Product
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            data-testid='search-input'
            value={query}
            variant='outlined'
            placeholder={searchHint}
            className='w-full'
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchOutlined className='text-gray-600' />
                  </InputAdornment>
                ),
                classes: {
                  notchedOutline: "border-0",
                  root: "flex items-center justify-center gap-2 rounded border-none px-4 font-normal text-black hover:outline-none",
                  input:
                    "border-none outline-none hover:border-none hover:outline-none focus:border-none focus:outline-none focus:ring-0 px-0 font-thin text-ellipsis",
                },
                onChange: handleInputChange,
              },
            }}
          />
        </Box>
        <Button aria-label='filter' appearance='outlined' onClick={onShowFilter} startIcon={<FilterAltOutlined />}>
          Filter
        </Button>
      </Box>
    </>
  )
}
