import type { Row } from "@tanstack/react-table"
import { useEffect, useMemo } from "react"

import {
  useOrderLineDetails,
  useOrderLines,
  useUpdateModifiedFields,
  useUpdateSectionFields,
} from "../../contexts/PurchaseOrderContext"
import { PurchaseOrderSection } from "../../types"

import { KILOGRAMS_TO_POUNDS, POUNDS_TO_KILOGRAMS, POUND_UOM_ID } from "@/constants/unitOfMeasurements"
import { type OrderLine, type OrderUpdateInput, PricingPackageSortField, SortingOrder } from "@/graphql/codegen/graphql"
import { useGetPricingPackage } from "@/screens/Products/hooks/useGetPricingPackage"
import { formatNumber } from "@/utils/formatNumber"
import { getVendorPrice } from "@/utils/getVendorPrice"

interface UsePriceUomColumn {
  currentPricing: string
  convertedUomValue: string
  total: string
  fetching: boolean
}

export const usePriceUomColumn = ({ row }: { row: Row<OrderLine> }): UsePriceUomColumn => {
  const orderLineDetails = useOrderLineDetails()
  const orderLines = useOrderLines()
  const updateModifiedFields = useUpdateModifiedFields()
  const updateSectionFields = useUpdateSectionFields()

  const {
    productId,
    priceUomId,
    uomId,
    quantityInUom = 0,
    pricePerUom = 0,
    productDetail: { product },
  } = row.original
  const { pricingList, pricingListFetching } = useGetPricingPackage({
    filterSpecs: {
      companyId: product?.companyId ?? "",
      productId,
      sourceCompanyLocationAssociationId: product?.locationsAssociations?.[0]?.locationAssociationId ?? "",
    },
    sortSpecs: {
      field: PricingPackageSortField.EffectiveDate,
      order: SortingOrder.Asc,
    },
  })

  useEffect(() => {
    if ((pricingList.currentPricing || pricingList.futurePricing.length > 0) && pricingList.currentPricing) {
      const updatedOrderLineDetails = {
        ...orderLineDetails,
        [productId]: pricingList,
      }
      updateModifiedFields(PurchaseOrderSection.ORDER_LINES_DETAILS, updatedOrderLineDetails)
      updateSectionFields(PurchaseOrderSection.ORDER_LINES_DETAILS, updatedOrderLineDetails)
    }
  }, [pricingList.currentPricing, pricingList.futurePricing])

  // Check if manual override price is applied
  const isManualOverridePrice = Boolean(pricePerUom && priceUomId)

  // Memoized current price based on manual override or current pricing or 0 if not available
  const currentPrice = useMemo(() => {
    if (isManualOverridePrice) {
      return pricePerUom
    } else if (pricingList.currentPricing) {
      return getVendorPrice(pricingList.currentPricing, quantityInUom, uomId)
    } else {
      return 0
    }
  }, [quantityInUom, pricePerUom, priceUomId, pricingList.currentPricing, uomId])

  useEffect(() => {
    if (!currentPrice) return

    const updatedOrderLines = orderLines.map((line) =>
      line.productId === productId ? { ...line, pricePerUom: currentPrice } : line
    )

    updateModifiedFields(PurchaseOrderSection.ORDER_LINES, updatedOrderLines as Partial<OrderUpdateInput>)
  }, [currentPrice, productId, orderLines, updateModifiedFields])

  // Determine the unit of measure (UOM) and conversion factor

  const pricingUom = !isManualOverridePrice ? pricingList.currentPricing?.unitOfMeasurementId : priceUomId
  const selectedUom = pricingUom === POUND_UOM_ID ? "lb" : "kg"
  // Memoized current pricing display (e.g., "$10.00 / lb")
  const currentPricing = useMemo(
    () => (currentPrice ? `$${formatNumber(currentPrice)} / ${selectedUom}` : "Not calculated"),
    [currentPrice, selectedUom]
  )

  // Convert price to alternate UOM (e.g., lb to kg or vice versa)

  const uomFactor = pricingUom === POUND_UOM_ID ? KILOGRAMS_TO_POUNDS : POUNDS_TO_KILOGRAMS
  const convertedValue: number = useMemo(
    () => (currentPrice ? parseFloat(currentPrice.toString()) * uomFactor : 0),
    [currentPrice, uomFactor]
  )

  // Memoized display for converted UOM price (e.g., "$5.00 / kg")
  const convertedUomValue = useMemo(
    () => (convertedValue ? `$${formatNumber(convertedValue)} / ${selectedUom === "lb" ? "kg" : "lb"}` : ""),
    [convertedValue, selectedUom]
  )

  // Calculate the total cost based on quantity and price in appropriate UOM
  const total = useMemo(
    () =>
      pricingUom === uomId
        ? formatNumber(Number(currentPrice) * quantityInUom)
        : formatNumber(convertedValue * quantityInUom),
    [currentPrice, quantityInUom, convertedValue, pricingUom, uomId]
  )

  return {
    currentPricing,
    convertedUomValue,
    total,
    fetching: pricingListFetching,
  }
}
