/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as RegulatoryRouteImport } from './routes/regulatory.route'
import { Route as FinanceRouteImport } from './routes/finance.route'
import { Route as CompanyRouteImport } from './routes/company.route'
import { Route as IndexRouteImport } from './routes/index.route'
import { Route as ProcurementIndexImport } from './routes/procurement/index'
import { Route as CompaniesIndexImport } from './routes/companies/index'
import { Route as SalesIndexRouteImport } from './routes/sales/index.route'
import { Route as InventoryIndexRouteImport } from './routes/inventory/index.route'
import { Route as ProcurementLayoutImport } from './routes/procurement/_layout'
import { Route as AuthCallbackImport } from './routes/auth/callback'
import { Route as SalesSalesOrdersRouteImport } from './routes/sales/sales-orders.route'
import { Route as SalesCustomersRouteImport } from './routes/sales/customers.route'
import { Route as InventoryTransactionsRouteImport } from './routes/inventory/transactions.route'
import { Route as InventoryProductsRouteImport } from './routes/inventory/products.route'
import { Route as ProcurementLayoutSupplierReportImport } from './routes/procurement/_layout.supplier-report'
import { Route as ProcurementLayoutPurchaseOrdersImport } from './routes/procurement/_layout.purchase-orders'
import { Route as ProductProductIdEditIndexImport } from './routes/product/$productId/edit/index'
import { Route as CompaniesCompanyIdEditIndexImport } from './routes/companies/$companyId/edit/index'
import { Route as ProductProductIdEditLayoutImport } from './routes/product/$productId/edit/_layout'
import { Route as ProcurementLayoutPurchaseOrdersPurchaseOrderIdImport } from './routes/procurement/_layout_.purchase-orders.$purchaseOrderId'
import { Route as CompaniesCompanyIdEditLayoutImport } from './routes/companies/$companyId/edit/_layout'
import { Route as ProductProductIdEditLayoutRegulatoryRouteImport } from './routes/product/$productId/edit/_layout.regulatory.route'
import { Route as ProductProductIdEditLayoutInstructionsRouteImport } from './routes/product/$productId/edit/_layout.instructions.route'
import { Route as ProductProductIdEditLayoutInformationRouteImport } from './routes/product/$productId/edit/_layout.information.route'
import { Route as CompaniesCompanyIdEditLayoutRegistrationRouteImport } from './routes/companies/$companyId/edit/_layout.registration.route'
import { Route as CompaniesCompanyIdEditLayoutProductsRouteImport } from './routes/companies/$companyId/edit/_layout.products.route'
import { Route as CompaniesCompanyIdEditLayoutPaymentRouteImport } from './routes/companies/$companyId/edit/_layout.payment.route'
import { Route as CompaniesCompanyIdEditLayoutLocationsRouteImport } from './routes/companies/$companyId/edit/_layout.locations.route'
import { Route as CompaniesCompanyIdEditLayoutEvaluationRouteImport } from './routes/companies/$companyId/edit/_layout.evaluation.route'
import { Route as CompaniesCompanyIdEditLayoutContactsRouteImport } from './routes/companies/$companyId/edit/_layout.contacts.route'
import { Route as CompaniesCompanyIdEditLayoutCertificationRouteImport } from './routes/companies/$companyId/edit/_layout.certification.route'
import { Route as CompaniesCompanyIdEditLayoutLocationsLocationIdRouteImport } from './routes/companies/$companyId/edit/_layout.locations.$locationId.route'

// Create Virtual Routes

const ProcurementImport = createFileRoute('/procurement')()
const ProductProductIdEditImport = createFileRoute('/product/$productId/edit')()
const CompaniesCompanyIdEditImport = createFileRoute(
  '/companies/$companyId/edit',
)()
const ContactContactIdIndexLazyImport = createFileRoute(
  '/contact/$contactId/',
)()
const ProcurementLayoutSupplierReportNewSupplierLazyImport = createFileRoute(
  '/procurement/_layout/supplier-report/new-supplier',
)()

// Create/Update Routes

const ProcurementRoute = ProcurementImport.update({
  id: '/procurement',
  path: '/procurement',
  getParentRoute: () => rootRoute,
} as any)

const RegulatoryRouteRoute = RegulatoryRouteImport.update({
  id: '/regulatory',
  path: '/regulatory',
  getParentRoute: () => rootRoute,
} as any)

const FinanceRouteRoute = FinanceRouteImport.update({
  id: '/finance',
  path: '/finance',
  getParentRoute: () => rootRoute,
} as any)

const CompanyRouteRoute = CompanyRouteImport.update({
  id: '/company',
  path: '/company',
  getParentRoute: () => rootRoute,
} as any)

const IndexRouteRoute = IndexRouteImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const ProcurementIndexRoute = ProcurementIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ProcurementRoute,
} as any)

const CompaniesIndexRoute = CompaniesIndexImport.update({
  id: '/companies/',
  path: '/companies/',
  getParentRoute: () => rootRoute,
} as any)

const SalesIndexRouteRoute = SalesIndexRouteImport.update({
  id: '/sales/',
  path: '/sales/',
  getParentRoute: () => rootRoute,
} as any)

const InventoryIndexRouteRoute = InventoryIndexRouteImport.update({
  id: '/inventory/',
  path: '/inventory/',
  getParentRoute: () => rootRoute,
} as any)

const ProcurementLayoutRoute = ProcurementLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => ProcurementRoute,
} as any)

const AuthCallbackRoute = AuthCallbackImport.update({
  id: '/auth/callback',
  path: '/auth/callback',
  getParentRoute: () => rootRoute,
} as any)

const SalesSalesOrdersRouteRoute = SalesSalesOrdersRouteImport.update({
  id: '/sales/sales-orders',
  path: '/sales/sales-orders',
  getParentRoute: () => rootRoute,
} as any)

const SalesCustomersRouteRoute = SalesCustomersRouteImport.update({
  id: '/sales/customers',
  path: '/sales/customers',
  getParentRoute: () => rootRoute,
} as any)

const InventoryTransactionsRouteRoute = InventoryTransactionsRouteImport.update(
  {
    id: '/inventory/transactions',
    path: '/inventory/transactions',
    getParentRoute: () => rootRoute,
  } as any,
)

const InventoryProductsRouteRoute = InventoryProductsRouteImport.update({
  id: '/inventory/products',
  path: '/inventory/products',
  getParentRoute: () => rootRoute,
} as any)

const ProductProductIdEditRoute = ProductProductIdEditImport.update({
  id: '/product/$productId/edit',
  path: '/product/$productId/edit',
  getParentRoute: () => rootRoute,
} as any)

const CompaniesCompanyIdEditRoute = CompaniesCompanyIdEditImport.update({
  id: '/companies/$companyId/edit',
  path: '/companies/$companyId/edit',
  getParentRoute: () => rootRoute,
} as any)

const ContactContactIdIndexLazyRoute = ContactContactIdIndexLazyImport.update({
  id: '/contact/$contactId/',
  path: '/contact/$contactId/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/contact/$contactId/index.lazy').then((d) => d.Route),
)

const ProcurementLayoutSupplierReportRoute =
  ProcurementLayoutSupplierReportImport.update({
    id: '/supplier-report',
    path: '/supplier-report',
    getParentRoute: () => ProcurementLayoutRoute,
  } as any)

const ProcurementLayoutPurchaseOrdersRoute =
  ProcurementLayoutPurchaseOrdersImport.update({
    id: '/purchase-orders',
    path: '/purchase-orders',
    getParentRoute: () => ProcurementLayoutRoute,
  } as any)

const ProductProductIdEditIndexRoute = ProductProductIdEditIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ProductProductIdEditRoute,
} as any)

const CompaniesCompanyIdEditIndexRoute =
  CompaniesCompanyIdEditIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => CompaniesCompanyIdEditRoute,
  } as any)

const ProcurementLayoutSupplierReportNewSupplierLazyRoute =
  ProcurementLayoutSupplierReportNewSupplierLazyImport.update({
    id: '/new-supplier',
    path: '/new-supplier',
    getParentRoute: () => ProcurementLayoutSupplierReportRoute,
  } as any).lazy(() =>
    import(
      './routes/procurement/_layout.supplier-report.new-supplier.lazy'
    ).then((d) => d.Route),
  )

const ProductProductIdEditLayoutRoute = ProductProductIdEditLayoutImport.update(
  {
    id: '/_layout',
    getParentRoute: () => ProductProductIdEditRoute,
  } as any,
)

const ProcurementLayoutPurchaseOrdersPurchaseOrderIdRoute =
  ProcurementLayoutPurchaseOrdersPurchaseOrderIdImport.update({
    id: '/_layout_/purchase-orders/$purchaseOrderId',
    path: '/purchase-orders/$purchaseOrderId',
    getParentRoute: () => ProcurementRoute,
  } as any)

const CompaniesCompanyIdEditLayoutRoute =
  CompaniesCompanyIdEditLayoutImport.update({
    id: '/_layout',
    getParentRoute: () => CompaniesCompanyIdEditRoute,
  } as any)

const ProductProductIdEditLayoutRegulatoryRouteRoute =
  ProductProductIdEditLayoutRegulatoryRouteImport.update({
    id: '/regulatory',
    path: '/regulatory',
    getParentRoute: () => ProductProductIdEditLayoutRoute,
  } as any)

const ProductProductIdEditLayoutInstructionsRouteRoute =
  ProductProductIdEditLayoutInstructionsRouteImport.update({
    id: '/instructions',
    path: '/instructions',
    getParentRoute: () => ProductProductIdEditLayoutRoute,
  } as any)

const ProductProductIdEditLayoutInformationRouteRoute =
  ProductProductIdEditLayoutInformationRouteImport.update({
    id: '/information',
    path: '/information',
    getParentRoute: () => ProductProductIdEditLayoutRoute,
  } as any)

const CompaniesCompanyIdEditLayoutRegistrationRouteRoute =
  CompaniesCompanyIdEditLayoutRegistrationRouteImport.update({
    id: '/registration',
    path: '/registration',
    getParentRoute: () => CompaniesCompanyIdEditLayoutRoute,
  } as any)

const CompaniesCompanyIdEditLayoutProductsRouteRoute =
  CompaniesCompanyIdEditLayoutProductsRouteImport.update({
    id: '/products',
    path: '/products',
    getParentRoute: () => CompaniesCompanyIdEditLayoutRoute,
  } as any)

const CompaniesCompanyIdEditLayoutPaymentRouteRoute =
  CompaniesCompanyIdEditLayoutPaymentRouteImport.update({
    id: '/payment',
    path: '/payment',
    getParentRoute: () => CompaniesCompanyIdEditLayoutRoute,
  } as any)

const CompaniesCompanyIdEditLayoutLocationsRouteRoute =
  CompaniesCompanyIdEditLayoutLocationsRouteImport.update({
    id: '/locations',
    path: '/locations',
    getParentRoute: () => CompaniesCompanyIdEditLayoutRoute,
  } as any)

const CompaniesCompanyIdEditLayoutEvaluationRouteRoute =
  CompaniesCompanyIdEditLayoutEvaluationRouteImport.update({
    id: '/evaluation',
    path: '/evaluation',
    getParentRoute: () => CompaniesCompanyIdEditLayoutRoute,
  } as any)

const CompaniesCompanyIdEditLayoutContactsRouteRoute =
  CompaniesCompanyIdEditLayoutContactsRouteImport.update({
    id: '/contacts',
    path: '/contacts',
    getParentRoute: () => CompaniesCompanyIdEditLayoutRoute,
  } as any)

const CompaniesCompanyIdEditLayoutCertificationRouteRoute =
  CompaniesCompanyIdEditLayoutCertificationRouteImport.update({
    id: '/certification',
    path: '/certification',
    getParentRoute: () => CompaniesCompanyIdEditLayoutRoute,
  } as any)

const CompaniesCompanyIdEditLayoutLocationsLocationIdRouteRoute =
  CompaniesCompanyIdEditLayoutLocationsLocationIdRouteImport.update({
    id: '/$locationId',
    path: '/$locationId',
    getParentRoute: () => CompaniesCompanyIdEditLayoutLocationsRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexRouteImport
      parentRoute: typeof rootRoute
    }
    '/company': {
      id: '/company'
      path: '/company'
      fullPath: '/company'
      preLoaderRoute: typeof CompanyRouteImport
      parentRoute: typeof rootRoute
    }
    '/finance': {
      id: '/finance'
      path: '/finance'
      fullPath: '/finance'
      preLoaderRoute: typeof FinanceRouteImport
      parentRoute: typeof rootRoute
    }
    '/regulatory': {
      id: '/regulatory'
      path: '/regulatory'
      fullPath: '/regulatory'
      preLoaderRoute: typeof RegulatoryRouteImport
      parentRoute: typeof rootRoute
    }
    '/inventory/products': {
      id: '/inventory/products'
      path: '/inventory/products'
      fullPath: '/inventory/products'
      preLoaderRoute: typeof InventoryProductsRouteImport
      parentRoute: typeof rootRoute
    }
    '/inventory/transactions': {
      id: '/inventory/transactions'
      path: '/inventory/transactions'
      fullPath: '/inventory/transactions'
      preLoaderRoute: typeof InventoryTransactionsRouteImport
      parentRoute: typeof rootRoute
    }
    '/sales/customers': {
      id: '/sales/customers'
      path: '/sales/customers'
      fullPath: '/sales/customers'
      preLoaderRoute: typeof SalesCustomersRouteImport
      parentRoute: typeof rootRoute
    }
    '/sales/sales-orders': {
      id: '/sales/sales-orders'
      path: '/sales/sales-orders'
      fullPath: '/sales/sales-orders'
      preLoaderRoute: typeof SalesSalesOrdersRouteImport
      parentRoute: typeof rootRoute
    }
    '/auth/callback': {
      id: '/auth/callback'
      path: '/auth/callback'
      fullPath: '/auth/callback'
      preLoaderRoute: typeof AuthCallbackImport
      parentRoute: typeof rootRoute
    }
    '/procurement': {
      id: '/procurement'
      path: '/procurement'
      fullPath: '/procurement'
      preLoaderRoute: typeof ProcurementImport
      parentRoute: typeof rootRoute
    }
    '/procurement/_layout': {
      id: '/procurement/_layout'
      path: '/procurement'
      fullPath: '/procurement'
      preLoaderRoute: typeof ProcurementLayoutImport
      parentRoute: typeof ProcurementRoute
    }
    '/inventory/': {
      id: '/inventory/'
      path: '/inventory'
      fullPath: '/inventory'
      preLoaderRoute: typeof InventoryIndexRouteImport
      parentRoute: typeof rootRoute
    }
    '/sales/': {
      id: '/sales/'
      path: '/sales'
      fullPath: '/sales'
      preLoaderRoute: typeof SalesIndexRouteImport
      parentRoute: typeof rootRoute
    }
    '/companies/': {
      id: '/companies/'
      path: '/companies'
      fullPath: '/companies'
      preLoaderRoute: typeof CompaniesIndexImport
      parentRoute: typeof rootRoute
    }
    '/procurement/': {
      id: '/procurement/'
      path: '/'
      fullPath: '/procurement/'
      preLoaderRoute: typeof ProcurementIndexImport
      parentRoute: typeof ProcurementImport
    }
    '/procurement/_layout/purchase-orders': {
      id: '/procurement/_layout/purchase-orders'
      path: '/purchase-orders'
      fullPath: '/procurement/purchase-orders'
      preLoaderRoute: typeof ProcurementLayoutPurchaseOrdersImport
      parentRoute: typeof ProcurementLayoutImport
    }
    '/procurement/_layout/supplier-report': {
      id: '/procurement/_layout/supplier-report'
      path: '/supplier-report'
      fullPath: '/procurement/supplier-report'
      preLoaderRoute: typeof ProcurementLayoutSupplierReportImport
      parentRoute: typeof ProcurementLayoutImport
    }
    '/contact/$contactId/': {
      id: '/contact/$contactId/'
      path: '/contact/$contactId'
      fullPath: '/contact/$contactId'
      preLoaderRoute: typeof ContactContactIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/companies/$companyId/edit': {
      id: '/companies/$companyId/edit'
      path: '/companies/$companyId/edit'
      fullPath: '/companies/$companyId/edit'
      preLoaderRoute: typeof CompaniesCompanyIdEditImport
      parentRoute: typeof rootRoute
    }
    '/companies/$companyId/edit/_layout': {
      id: '/companies/$companyId/edit/_layout'
      path: '/companies/$companyId/edit'
      fullPath: '/companies/$companyId/edit'
      preLoaderRoute: typeof CompaniesCompanyIdEditLayoutImport
      parentRoute: typeof CompaniesCompanyIdEditRoute
    }
    '/procurement/_layout_/purchase-orders/$purchaseOrderId': {
      id: '/procurement/_layout_/purchase-orders/$purchaseOrderId'
      path: '/purchase-orders/$purchaseOrderId'
      fullPath: '/procurement/purchase-orders/$purchaseOrderId'
      preLoaderRoute: typeof ProcurementLayoutPurchaseOrdersPurchaseOrderIdImport
      parentRoute: typeof ProcurementImport
    }
    '/product/$productId/edit': {
      id: '/product/$productId/edit'
      path: '/product/$productId/edit'
      fullPath: '/product/$productId/edit'
      preLoaderRoute: typeof ProductProductIdEditImport
      parentRoute: typeof rootRoute
    }
    '/product/$productId/edit/_layout': {
      id: '/product/$productId/edit/_layout'
      path: '/product/$productId/edit'
      fullPath: '/product/$productId/edit'
      preLoaderRoute: typeof ProductProductIdEditLayoutImport
      parentRoute: typeof ProductProductIdEditRoute
    }
    '/procurement/_layout/supplier-report/new-supplier': {
      id: '/procurement/_layout/supplier-report/new-supplier'
      path: '/new-supplier'
      fullPath: '/procurement/supplier-report/new-supplier'
      preLoaderRoute: typeof ProcurementLayoutSupplierReportNewSupplierLazyImport
      parentRoute: typeof ProcurementLayoutSupplierReportImport
    }
    '/companies/$companyId/edit/': {
      id: '/companies/$companyId/edit/'
      path: '/'
      fullPath: '/companies/$companyId/edit/'
      preLoaderRoute: typeof CompaniesCompanyIdEditIndexImport
      parentRoute: typeof CompaniesCompanyIdEditImport
    }
    '/product/$productId/edit/': {
      id: '/product/$productId/edit/'
      path: '/'
      fullPath: '/product/$productId/edit/'
      preLoaderRoute: typeof ProductProductIdEditIndexImport
      parentRoute: typeof ProductProductIdEditImport
    }
    '/companies/$companyId/edit/_layout/certification': {
      id: '/companies/$companyId/edit/_layout/certification'
      path: '/certification'
      fullPath: '/companies/$companyId/edit/certification'
      preLoaderRoute: typeof CompaniesCompanyIdEditLayoutCertificationRouteImport
      parentRoute: typeof CompaniesCompanyIdEditLayoutImport
    }
    '/companies/$companyId/edit/_layout/contacts': {
      id: '/companies/$companyId/edit/_layout/contacts'
      path: '/contacts'
      fullPath: '/companies/$companyId/edit/contacts'
      preLoaderRoute: typeof CompaniesCompanyIdEditLayoutContactsRouteImport
      parentRoute: typeof CompaniesCompanyIdEditLayoutImport
    }
    '/companies/$companyId/edit/_layout/evaluation': {
      id: '/companies/$companyId/edit/_layout/evaluation'
      path: '/evaluation'
      fullPath: '/companies/$companyId/edit/evaluation'
      preLoaderRoute: typeof CompaniesCompanyIdEditLayoutEvaluationRouteImport
      parentRoute: typeof CompaniesCompanyIdEditLayoutImport
    }
    '/companies/$companyId/edit/_layout/locations': {
      id: '/companies/$companyId/edit/_layout/locations'
      path: '/locations'
      fullPath: '/companies/$companyId/edit/locations'
      preLoaderRoute: typeof CompaniesCompanyIdEditLayoutLocationsRouteImport
      parentRoute: typeof CompaniesCompanyIdEditLayoutImport
    }
    '/companies/$companyId/edit/_layout/payment': {
      id: '/companies/$companyId/edit/_layout/payment'
      path: '/payment'
      fullPath: '/companies/$companyId/edit/payment'
      preLoaderRoute: typeof CompaniesCompanyIdEditLayoutPaymentRouteImport
      parentRoute: typeof CompaniesCompanyIdEditLayoutImport
    }
    '/companies/$companyId/edit/_layout/products': {
      id: '/companies/$companyId/edit/_layout/products'
      path: '/products'
      fullPath: '/companies/$companyId/edit/products'
      preLoaderRoute: typeof CompaniesCompanyIdEditLayoutProductsRouteImport
      parentRoute: typeof CompaniesCompanyIdEditLayoutImport
    }
    '/companies/$companyId/edit/_layout/registration': {
      id: '/companies/$companyId/edit/_layout/registration'
      path: '/registration'
      fullPath: '/companies/$companyId/edit/registration'
      preLoaderRoute: typeof CompaniesCompanyIdEditLayoutRegistrationRouteImport
      parentRoute: typeof CompaniesCompanyIdEditLayoutImport
    }
    '/product/$productId/edit/_layout/information': {
      id: '/product/$productId/edit/_layout/information'
      path: '/information'
      fullPath: '/product/$productId/edit/information'
      preLoaderRoute: typeof ProductProductIdEditLayoutInformationRouteImport
      parentRoute: typeof ProductProductIdEditLayoutImport
    }
    '/product/$productId/edit/_layout/instructions': {
      id: '/product/$productId/edit/_layout/instructions'
      path: '/instructions'
      fullPath: '/product/$productId/edit/instructions'
      preLoaderRoute: typeof ProductProductIdEditLayoutInstructionsRouteImport
      parentRoute: typeof ProductProductIdEditLayoutImport
    }
    '/product/$productId/edit/_layout/regulatory': {
      id: '/product/$productId/edit/_layout/regulatory'
      path: '/regulatory'
      fullPath: '/product/$productId/edit/regulatory'
      preLoaderRoute: typeof ProductProductIdEditLayoutRegulatoryRouteImport
      parentRoute: typeof ProductProductIdEditLayoutImport
    }
    '/companies/$companyId/edit/_layout/locations/$locationId': {
      id: '/companies/$companyId/edit/_layout/locations/$locationId'
      path: '/$locationId'
      fullPath: '/companies/$companyId/edit/locations/$locationId'
      preLoaderRoute: typeof CompaniesCompanyIdEditLayoutLocationsLocationIdRouteImport
      parentRoute: typeof CompaniesCompanyIdEditLayoutLocationsRouteImport
    }
  }
}

// Create and export the route tree

interface ProcurementLayoutSupplierReportRouteChildren {
  ProcurementLayoutSupplierReportNewSupplierLazyRoute: typeof ProcurementLayoutSupplierReportNewSupplierLazyRoute
}

const ProcurementLayoutSupplierReportRouteChildren: ProcurementLayoutSupplierReportRouteChildren =
  {
    ProcurementLayoutSupplierReportNewSupplierLazyRoute:
      ProcurementLayoutSupplierReportNewSupplierLazyRoute,
  }

const ProcurementLayoutSupplierReportRouteWithChildren =
  ProcurementLayoutSupplierReportRoute._addFileChildren(
    ProcurementLayoutSupplierReportRouteChildren,
  )

interface ProcurementLayoutRouteChildren {
  ProcurementLayoutPurchaseOrdersRoute: typeof ProcurementLayoutPurchaseOrdersRoute
  ProcurementLayoutSupplierReportRoute: typeof ProcurementLayoutSupplierReportRouteWithChildren
}

const ProcurementLayoutRouteChildren: ProcurementLayoutRouteChildren = {
  ProcurementLayoutPurchaseOrdersRoute: ProcurementLayoutPurchaseOrdersRoute,
  ProcurementLayoutSupplierReportRoute:
    ProcurementLayoutSupplierReportRouteWithChildren,
}

const ProcurementLayoutRouteWithChildren =
  ProcurementLayoutRoute._addFileChildren(ProcurementLayoutRouteChildren)

interface ProcurementRouteChildren {
  ProcurementLayoutRoute: typeof ProcurementLayoutRouteWithChildren
  ProcurementIndexRoute: typeof ProcurementIndexRoute
  ProcurementLayoutPurchaseOrdersPurchaseOrderIdRoute: typeof ProcurementLayoutPurchaseOrdersPurchaseOrderIdRoute
}

const ProcurementRouteChildren: ProcurementRouteChildren = {
  ProcurementLayoutRoute: ProcurementLayoutRouteWithChildren,
  ProcurementIndexRoute: ProcurementIndexRoute,
  ProcurementLayoutPurchaseOrdersPurchaseOrderIdRoute:
    ProcurementLayoutPurchaseOrdersPurchaseOrderIdRoute,
}

const ProcurementRouteWithChildren = ProcurementRoute._addFileChildren(
  ProcurementRouteChildren,
)

interface CompaniesCompanyIdEditLayoutLocationsRouteRouteChildren {
  CompaniesCompanyIdEditLayoutLocationsLocationIdRouteRoute: typeof CompaniesCompanyIdEditLayoutLocationsLocationIdRouteRoute
}

const CompaniesCompanyIdEditLayoutLocationsRouteRouteChildren: CompaniesCompanyIdEditLayoutLocationsRouteRouteChildren =
  {
    CompaniesCompanyIdEditLayoutLocationsLocationIdRouteRoute:
      CompaniesCompanyIdEditLayoutLocationsLocationIdRouteRoute,
  }

const CompaniesCompanyIdEditLayoutLocationsRouteRouteWithChildren =
  CompaniesCompanyIdEditLayoutLocationsRouteRoute._addFileChildren(
    CompaniesCompanyIdEditLayoutLocationsRouteRouteChildren,
  )

interface CompaniesCompanyIdEditLayoutRouteChildren {
  CompaniesCompanyIdEditLayoutCertificationRouteRoute: typeof CompaniesCompanyIdEditLayoutCertificationRouteRoute
  CompaniesCompanyIdEditLayoutContactsRouteRoute: typeof CompaniesCompanyIdEditLayoutContactsRouteRoute
  CompaniesCompanyIdEditLayoutEvaluationRouteRoute: typeof CompaniesCompanyIdEditLayoutEvaluationRouteRoute
  CompaniesCompanyIdEditLayoutLocationsRouteRoute: typeof CompaniesCompanyIdEditLayoutLocationsRouteRouteWithChildren
  CompaniesCompanyIdEditLayoutPaymentRouteRoute: typeof CompaniesCompanyIdEditLayoutPaymentRouteRoute
  CompaniesCompanyIdEditLayoutProductsRouteRoute: typeof CompaniesCompanyIdEditLayoutProductsRouteRoute
  CompaniesCompanyIdEditLayoutRegistrationRouteRoute: typeof CompaniesCompanyIdEditLayoutRegistrationRouteRoute
}

const CompaniesCompanyIdEditLayoutRouteChildren: CompaniesCompanyIdEditLayoutRouteChildren =
  {
    CompaniesCompanyIdEditLayoutCertificationRouteRoute:
      CompaniesCompanyIdEditLayoutCertificationRouteRoute,
    CompaniesCompanyIdEditLayoutContactsRouteRoute:
      CompaniesCompanyIdEditLayoutContactsRouteRoute,
    CompaniesCompanyIdEditLayoutEvaluationRouteRoute:
      CompaniesCompanyIdEditLayoutEvaluationRouteRoute,
    CompaniesCompanyIdEditLayoutLocationsRouteRoute:
      CompaniesCompanyIdEditLayoutLocationsRouteRouteWithChildren,
    CompaniesCompanyIdEditLayoutPaymentRouteRoute:
      CompaniesCompanyIdEditLayoutPaymentRouteRoute,
    CompaniesCompanyIdEditLayoutProductsRouteRoute:
      CompaniesCompanyIdEditLayoutProductsRouteRoute,
    CompaniesCompanyIdEditLayoutRegistrationRouteRoute:
      CompaniesCompanyIdEditLayoutRegistrationRouteRoute,
  }

const CompaniesCompanyIdEditLayoutRouteWithChildren =
  CompaniesCompanyIdEditLayoutRoute._addFileChildren(
    CompaniesCompanyIdEditLayoutRouteChildren,
  )

interface CompaniesCompanyIdEditRouteChildren {
  CompaniesCompanyIdEditLayoutRoute: typeof CompaniesCompanyIdEditLayoutRouteWithChildren
  CompaniesCompanyIdEditIndexRoute: typeof CompaniesCompanyIdEditIndexRoute
}

const CompaniesCompanyIdEditRouteChildren: CompaniesCompanyIdEditRouteChildren =
  {
    CompaniesCompanyIdEditLayoutRoute:
      CompaniesCompanyIdEditLayoutRouteWithChildren,
    CompaniesCompanyIdEditIndexRoute: CompaniesCompanyIdEditIndexRoute,
  }

const CompaniesCompanyIdEditRouteWithChildren =
  CompaniesCompanyIdEditRoute._addFileChildren(
    CompaniesCompanyIdEditRouteChildren,
  )

interface ProductProductIdEditLayoutRouteChildren {
  ProductProductIdEditLayoutInformationRouteRoute: typeof ProductProductIdEditLayoutInformationRouteRoute
  ProductProductIdEditLayoutInstructionsRouteRoute: typeof ProductProductIdEditLayoutInstructionsRouteRoute
  ProductProductIdEditLayoutRegulatoryRouteRoute: typeof ProductProductIdEditLayoutRegulatoryRouteRoute
}

const ProductProductIdEditLayoutRouteChildren: ProductProductIdEditLayoutRouteChildren =
  {
    ProductProductIdEditLayoutInformationRouteRoute:
      ProductProductIdEditLayoutInformationRouteRoute,
    ProductProductIdEditLayoutInstructionsRouteRoute:
      ProductProductIdEditLayoutInstructionsRouteRoute,
    ProductProductIdEditLayoutRegulatoryRouteRoute:
      ProductProductIdEditLayoutRegulatoryRouteRoute,
  }

const ProductProductIdEditLayoutRouteWithChildren =
  ProductProductIdEditLayoutRoute._addFileChildren(
    ProductProductIdEditLayoutRouteChildren,
  )

interface ProductProductIdEditRouteChildren {
  ProductProductIdEditLayoutRoute: typeof ProductProductIdEditLayoutRouteWithChildren
  ProductProductIdEditIndexRoute: typeof ProductProductIdEditIndexRoute
}

const ProductProductIdEditRouteChildren: ProductProductIdEditRouteChildren = {
  ProductProductIdEditLayoutRoute: ProductProductIdEditLayoutRouteWithChildren,
  ProductProductIdEditIndexRoute: ProductProductIdEditIndexRoute,
}

const ProductProductIdEditRouteWithChildren =
  ProductProductIdEditRoute._addFileChildren(ProductProductIdEditRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRouteRoute
  '/company': typeof CompanyRouteRoute
  '/finance': typeof FinanceRouteRoute
  '/regulatory': typeof RegulatoryRouteRoute
  '/inventory/products': typeof InventoryProductsRouteRoute
  '/inventory/transactions': typeof InventoryTransactionsRouteRoute
  '/sales/customers': typeof SalesCustomersRouteRoute
  '/sales/sales-orders': typeof SalesSalesOrdersRouteRoute
  '/auth/callback': typeof AuthCallbackRoute
  '/procurement': typeof ProcurementLayoutRouteWithChildren
  '/inventory': typeof InventoryIndexRouteRoute
  '/sales': typeof SalesIndexRouteRoute
  '/companies': typeof CompaniesIndexRoute
  '/procurement/': typeof ProcurementIndexRoute
  '/procurement/purchase-orders': typeof ProcurementLayoutPurchaseOrdersRoute
  '/procurement/supplier-report': typeof ProcurementLayoutSupplierReportRouteWithChildren
  '/contact/$contactId': typeof ContactContactIdIndexLazyRoute
  '/companies/$companyId/edit': typeof CompaniesCompanyIdEditLayoutRouteWithChildren
  '/procurement/purchase-orders/$purchaseOrderId': typeof ProcurementLayoutPurchaseOrdersPurchaseOrderIdRoute
  '/product/$productId/edit': typeof ProductProductIdEditLayoutRouteWithChildren
  '/procurement/supplier-report/new-supplier': typeof ProcurementLayoutSupplierReportNewSupplierLazyRoute
  '/companies/$companyId/edit/': typeof CompaniesCompanyIdEditIndexRoute
  '/product/$productId/edit/': typeof ProductProductIdEditIndexRoute
  '/companies/$companyId/edit/certification': typeof CompaniesCompanyIdEditLayoutCertificationRouteRoute
  '/companies/$companyId/edit/contacts': typeof CompaniesCompanyIdEditLayoutContactsRouteRoute
  '/companies/$companyId/edit/evaluation': typeof CompaniesCompanyIdEditLayoutEvaluationRouteRoute
  '/companies/$companyId/edit/locations': typeof CompaniesCompanyIdEditLayoutLocationsRouteRouteWithChildren
  '/companies/$companyId/edit/payment': typeof CompaniesCompanyIdEditLayoutPaymentRouteRoute
  '/companies/$companyId/edit/products': typeof CompaniesCompanyIdEditLayoutProductsRouteRoute
  '/companies/$companyId/edit/registration': typeof CompaniesCompanyIdEditLayoutRegistrationRouteRoute
  '/product/$productId/edit/information': typeof ProductProductIdEditLayoutInformationRouteRoute
  '/product/$productId/edit/instructions': typeof ProductProductIdEditLayoutInstructionsRouteRoute
  '/product/$productId/edit/regulatory': typeof ProductProductIdEditLayoutRegulatoryRouteRoute
  '/companies/$companyId/edit/locations/$locationId': typeof CompaniesCompanyIdEditLayoutLocationsLocationIdRouteRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRouteRoute
  '/company': typeof CompanyRouteRoute
  '/finance': typeof FinanceRouteRoute
  '/regulatory': typeof RegulatoryRouteRoute
  '/inventory/products': typeof InventoryProductsRouteRoute
  '/inventory/transactions': typeof InventoryTransactionsRouteRoute
  '/sales/customers': typeof SalesCustomersRouteRoute
  '/sales/sales-orders': typeof SalesSalesOrdersRouteRoute
  '/auth/callback': typeof AuthCallbackRoute
  '/procurement': typeof ProcurementIndexRoute
  '/inventory': typeof InventoryIndexRouteRoute
  '/sales': typeof SalesIndexRouteRoute
  '/companies': typeof CompaniesIndexRoute
  '/procurement/purchase-orders': typeof ProcurementLayoutPurchaseOrdersRoute
  '/procurement/supplier-report': typeof ProcurementLayoutSupplierReportRouteWithChildren
  '/contact/$contactId': typeof ContactContactIdIndexLazyRoute
  '/companies/$companyId/edit': typeof CompaniesCompanyIdEditIndexRoute
  '/procurement/purchase-orders/$purchaseOrderId': typeof ProcurementLayoutPurchaseOrdersPurchaseOrderIdRoute
  '/product/$productId/edit': typeof ProductProductIdEditIndexRoute
  '/procurement/supplier-report/new-supplier': typeof ProcurementLayoutSupplierReportNewSupplierLazyRoute
  '/companies/$companyId/edit/certification': typeof CompaniesCompanyIdEditLayoutCertificationRouteRoute
  '/companies/$companyId/edit/contacts': typeof CompaniesCompanyIdEditLayoutContactsRouteRoute
  '/companies/$companyId/edit/evaluation': typeof CompaniesCompanyIdEditLayoutEvaluationRouteRoute
  '/companies/$companyId/edit/locations': typeof CompaniesCompanyIdEditLayoutLocationsRouteRouteWithChildren
  '/companies/$companyId/edit/payment': typeof CompaniesCompanyIdEditLayoutPaymentRouteRoute
  '/companies/$companyId/edit/products': typeof CompaniesCompanyIdEditLayoutProductsRouteRoute
  '/companies/$companyId/edit/registration': typeof CompaniesCompanyIdEditLayoutRegistrationRouteRoute
  '/product/$productId/edit/information': typeof ProductProductIdEditLayoutInformationRouteRoute
  '/product/$productId/edit/instructions': typeof ProductProductIdEditLayoutInstructionsRouteRoute
  '/product/$productId/edit/regulatory': typeof ProductProductIdEditLayoutRegulatoryRouteRoute
  '/companies/$companyId/edit/locations/$locationId': typeof CompaniesCompanyIdEditLayoutLocationsLocationIdRouteRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRouteRoute
  '/company': typeof CompanyRouteRoute
  '/finance': typeof FinanceRouteRoute
  '/regulatory': typeof RegulatoryRouteRoute
  '/inventory/products': typeof InventoryProductsRouteRoute
  '/inventory/transactions': typeof InventoryTransactionsRouteRoute
  '/sales/customers': typeof SalesCustomersRouteRoute
  '/sales/sales-orders': typeof SalesSalesOrdersRouteRoute
  '/auth/callback': typeof AuthCallbackRoute
  '/procurement': typeof ProcurementRouteWithChildren
  '/procurement/_layout': typeof ProcurementLayoutRouteWithChildren
  '/inventory/': typeof InventoryIndexRouteRoute
  '/sales/': typeof SalesIndexRouteRoute
  '/companies/': typeof CompaniesIndexRoute
  '/procurement/': typeof ProcurementIndexRoute
  '/procurement/_layout/purchase-orders': typeof ProcurementLayoutPurchaseOrdersRoute
  '/procurement/_layout/supplier-report': typeof ProcurementLayoutSupplierReportRouteWithChildren
  '/contact/$contactId/': typeof ContactContactIdIndexLazyRoute
  '/companies/$companyId/edit': typeof CompaniesCompanyIdEditRouteWithChildren
  '/companies/$companyId/edit/_layout': typeof CompaniesCompanyIdEditLayoutRouteWithChildren
  '/procurement/_layout_/purchase-orders/$purchaseOrderId': typeof ProcurementLayoutPurchaseOrdersPurchaseOrderIdRoute
  '/product/$productId/edit': typeof ProductProductIdEditRouteWithChildren
  '/product/$productId/edit/_layout': typeof ProductProductIdEditLayoutRouteWithChildren
  '/procurement/_layout/supplier-report/new-supplier': typeof ProcurementLayoutSupplierReportNewSupplierLazyRoute
  '/companies/$companyId/edit/': typeof CompaniesCompanyIdEditIndexRoute
  '/product/$productId/edit/': typeof ProductProductIdEditIndexRoute
  '/companies/$companyId/edit/_layout/certification': typeof CompaniesCompanyIdEditLayoutCertificationRouteRoute
  '/companies/$companyId/edit/_layout/contacts': typeof CompaniesCompanyIdEditLayoutContactsRouteRoute
  '/companies/$companyId/edit/_layout/evaluation': typeof CompaniesCompanyIdEditLayoutEvaluationRouteRoute
  '/companies/$companyId/edit/_layout/locations': typeof CompaniesCompanyIdEditLayoutLocationsRouteRouteWithChildren
  '/companies/$companyId/edit/_layout/payment': typeof CompaniesCompanyIdEditLayoutPaymentRouteRoute
  '/companies/$companyId/edit/_layout/products': typeof CompaniesCompanyIdEditLayoutProductsRouteRoute
  '/companies/$companyId/edit/_layout/registration': typeof CompaniesCompanyIdEditLayoutRegistrationRouteRoute
  '/product/$productId/edit/_layout/information': typeof ProductProductIdEditLayoutInformationRouteRoute
  '/product/$productId/edit/_layout/instructions': typeof ProductProductIdEditLayoutInstructionsRouteRoute
  '/product/$productId/edit/_layout/regulatory': typeof ProductProductIdEditLayoutRegulatoryRouteRoute
  '/companies/$companyId/edit/_layout/locations/$locationId': typeof CompaniesCompanyIdEditLayoutLocationsLocationIdRouteRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/company'
    | '/finance'
    | '/regulatory'
    | '/inventory/products'
    | '/inventory/transactions'
    | '/sales/customers'
    | '/sales/sales-orders'
    | '/auth/callback'
    | '/procurement'
    | '/inventory'
    | '/sales'
    | '/companies'
    | '/procurement/'
    | '/procurement/purchase-orders'
    | '/procurement/supplier-report'
    | '/contact/$contactId'
    | '/companies/$companyId/edit'
    | '/procurement/purchase-orders/$purchaseOrderId'
    | '/product/$productId/edit'
    | '/procurement/supplier-report/new-supplier'
    | '/companies/$companyId/edit/'
    | '/product/$productId/edit/'
    | '/companies/$companyId/edit/certification'
    | '/companies/$companyId/edit/contacts'
    | '/companies/$companyId/edit/evaluation'
    | '/companies/$companyId/edit/locations'
    | '/companies/$companyId/edit/payment'
    | '/companies/$companyId/edit/products'
    | '/companies/$companyId/edit/registration'
    | '/product/$productId/edit/information'
    | '/product/$productId/edit/instructions'
    | '/product/$productId/edit/regulatory'
    | '/companies/$companyId/edit/locations/$locationId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/company'
    | '/finance'
    | '/regulatory'
    | '/inventory/products'
    | '/inventory/transactions'
    | '/sales/customers'
    | '/sales/sales-orders'
    | '/auth/callback'
    | '/procurement'
    | '/inventory'
    | '/sales'
    | '/companies'
    | '/procurement/purchase-orders'
    | '/procurement/supplier-report'
    | '/contact/$contactId'
    | '/companies/$companyId/edit'
    | '/procurement/purchase-orders/$purchaseOrderId'
    | '/product/$productId/edit'
    | '/procurement/supplier-report/new-supplier'
    | '/companies/$companyId/edit/certification'
    | '/companies/$companyId/edit/contacts'
    | '/companies/$companyId/edit/evaluation'
    | '/companies/$companyId/edit/locations'
    | '/companies/$companyId/edit/payment'
    | '/companies/$companyId/edit/products'
    | '/companies/$companyId/edit/registration'
    | '/product/$productId/edit/information'
    | '/product/$productId/edit/instructions'
    | '/product/$productId/edit/regulatory'
    | '/companies/$companyId/edit/locations/$locationId'
  id:
    | '__root__'
    | '/'
    | '/company'
    | '/finance'
    | '/regulatory'
    | '/inventory/products'
    | '/inventory/transactions'
    | '/sales/customers'
    | '/sales/sales-orders'
    | '/auth/callback'
    | '/procurement'
    | '/procurement/_layout'
    | '/inventory/'
    | '/sales/'
    | '/companies/'
    | '/procurement/'
    | '/procurement/_layout/purchase-orders'
    | '/procurement/_layout/supplier-report'
    | '/contact/$contactId/'
    | '/companies/$companyId/edit'
    | '/companies/$companyId/edit/_layout'
    | '/procurement/_layout_/purchase-orders/$purchaseOrderId'
    | '/product/$productId/edit'
    | '/product/$productId/edit/_layout'
    | '/procurement/_layout/supplier-report/new-supplier'
    | '/companies/$companyId/edit/'
    | '/product/$productId/edit/'
    | '/companies/$companyId/edit/_layout/certification'
    | '/companies/$companyId/edit/_layout/contacts'
    | '/companies/$companyId/edit/_layout/evaluation'
    | '/companies/$companyId/edit/_layout/locations'
    | '/companies/$companyId/edit/_layout/payment'
    | '/companies/$companyId/edit/_layout/products'
    | '/companies/$companyId/edit/_layout/registration'
    | '/product/$productId/edit/_layout/information'
    | '/product/$productId/edit/_layout/instructions'
    | '/product/$productId/edit/_layout/regulatory'
    | '/companies/$companyId/edit/_layout/locations/$locationId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRouteRoute: typeof IndexRouteRoute
  CompanyRouteRoute: typeof CompanyRouteRoute
  FinanceRouteRoute: typeof FinanceRouteRoute
  RegulatoryRouteRoute: typeof RegulatoryRouteRoute
  InventoryProductsRouteRoute: typeof InventoryProductsRouteRoute
  InventoryTransactionsRouteRoute: typeof InventoryTransactionsRouteRoute
  SalesCustomersRouteRoute: typeof SalesCustomersRouteRoute
  SalesSalesOrdersRouteRoute: typeof SalesSalesOrdersRouteRoute
  AuthCallbackRoute: typeof AuthCallbackRoute
  ProcurementRoute: typeof ProcurementRouteWithChildren
  InventoryIndexRouteRoute: typeof InventoryIndexRouteRoute
  SalesIndexRouteRoute: typeof SalesIndexRouteRoute
  CompaniesIndexRoute: typeof CompaniesIndexRoute
  ContactContactIdIndexLazyRoute: typeof ContactContactIdIndexLazyRoute
  CompaniesCompanyIdEditRoute: typeof CompaniesCompanyIdEditRouteWithChildren
  ProductProductIdEditRoute: typeof ProductProductIdEditRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRouteRoute: IndexRouteRoute,
  CompanyRouteRoute: CompanyRouteRoute,
  FinanceRouteRoute: FinanceRouteRoute,
  RegulatoryRouteRoute: RegulatoryRouteRoute,
  InventoryProductsRouteRoute: InventoryProductsRouteRoute,
  InventoryTransactionsRouteRoute: InventoryTransactionsRouteRoute,
  SalesCustomersRouteRoute: SalesCustomersRouteRoute,
  SalesSalesOrdersRouteRoute: SalesSalesOrdersRouteRoute,
  AuthCallbackRoute: AuthCallbackRoute,
  ProcurementRoute: ProcurementRouteWithChildren,
  InventoryIndexRouteRoute: InventoryIndexRouteRoute,
  SalesIndexRouteRoute: SalesIndexRouteRoute,
  CompaniesIndexRoute: CompaniesIndexRoute,
  ContactContactIdIndexLazyRoute: ContactContactIdIndexLazyRoute,
  CompaniesCompanyIdEditRoute: CompaniesCompanyIdEditRouteWithChildren,
  ProductProductIdEditRoute: ProductProductIdEditRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/company",
        "/finance",
        "/regulatory",
        "/inventory/products",
        "/inventory/transactions",
        "/sales/customers",
        "/sales/sales-orders",
        "/auth/callback",
        "/procurement",
        "/inventory/",
        "/sales/",
        "/companies/",
        "/contact/$contactId/",
        "/companies/$companyId/edit",
        "/product/$productId/edit"
      ]
    },
    "/": {
      "filePath": "index.route.tsx"
    },
    "/company": {
      "filePath": "company.route.tsx"
    },
    "/finance": {
      "filePath": "finance.route.tsx"
    },
    "/regulatory": {
      "filePath": "regulatory.route.tsx"
    },
    "/inventory/products": {
      "filePath": "inventory/products.route.tsx"
    },
    "/inventory/transactions": {
      "filePath": "inventory/transactions.route.tsx"
    },
    "/sales/customers": {
      "filePath": "sales/customers.route.tsx"
    },
    "/sales/sales-orders": {
      "filePath": "sales/sales-orders.route.tsx"
    },
    "/auth/callback": {
      "filePath": "auth/callback.tsx"
    },
    "/procurement": {
      "filePath": "procurement",
      "children": [
        "/procurement/_layout",
        "/procurement/",
        "/procurement/_layout_/purchase-orders/$purchaseOrderId"
      ]
    },
    "/procurement/_layout": {
      "filePath": "procurement/_layout.tsx",
      "parent": "/procurement",
      "children": [
        "/procurement/_layout/purchase-orders",
        "/procurement/_layout/supplier-report"
      ]
    },
    "/inventory/": {
      "filePath": "inventory/index.route.tsx"
    },
    "/sales/": {
      "filePath": "sales/index.route.tsx"
    },
    "/companies/": {
      "filePath": "companies/index.tsx"
    },
    "/procurement/": {
      "filePath": "procurement/index.tsx",
      "parent": "/procurement"
    },
    "/procurement/_layout/purchase-orders": {
      "filePath": "procurement/_layout.purchase-orders.tsx",
      "parent": "/procurement/_layout"
    },
    "/procurement/_layout/supplier-report": {
      "filePath": "procurement/_layout.supplier-report.tsx",
      "parent": "/procurement/_layout",
      "children": [
        "/procurement/_layout/supplier-report/new-supplier"
      ]
    },
    "/contact/$contactId/": {
      "filePath": "contact/$contactId/index.lazy.tsx"
    },
    "/companies/$companyId/edit": {
      "filePath": "companies/$companyId/edit",
      "children": [
        "/companies/$companyId/edit/_layout",
        "/companies/$companyId/edit/"
      ]
    },
    "/companies/$companyId/edit/_layout": {
      "filePath": "companies/$companyId/edit/_layout.tsx",
      "parent": "/companies/$companyId/edit",
      "children": [
        "/companies/$companyId/edit/_layout/certification",
        "/companies/$companyId/edit/_layout/contacts",
        "/companies/$companyId/edit/_layout/evaluation",
        "/companies/$companyId/edit/_layout/locations",
        "/companies/$companyId/edit/_layout/payment",
        "/companies/$companyId/edit/_layout/products",
        "/companies/$companyId/edit/_layout/registration"
      ]
    },
    "/procurement/_layout_/purchase-orders/$purchaseOrderId": {
      "filePath": "procurement/_layout_.purchase-orders.$purchaseOrderId.tsx",
      "parent": "/procurement"
    },
    "/product/$productId/edit": {
      "filePath": "product/$productId/edit",
      "children": [
        "/product/$productId/edit/_layout",
        "/product/$productId/edit/"
      ]
    },
    "/product/$productId/edit/_layout": {
      "filePath": "product/$productId/edit/_layout.tsx",
      "parent": "/product/$productId/edit",
      "children": [
        "/product/$productId/edit/_layout/information",
        "/product/$productId/edit/_layout/instructions",
        "/product/$productId/edit/_layout/regulatory"
      ]
    },
    "/procurement/_layout/supplier-report/new-supplier": {
      "filePath": "procurement/_layout.supplier-report.new-supplier.lazy.tsx",
      "parent": "/procurement/_layout/supplier-report"
    },
    "/companies/$companyId/edit/": {
      "filePath": "companies/$companyId/edit/index.tsx",
      "parent": "/companies/$companyId/edit"
    },
    "/product/$productId/edit/": {
      "filePath": "product/$productId/edit/index.tsx",
      "parent": "/product/$productId/edit"
    },
    "/companies/$companyId/edit/_layout/certification": {
      "filePath": "companies/$companyId/edit/_layout.certification.route.tsx",
      "parent": "/companies/$companyId/edit/_layout"
    },
    "/companies/$companyId/edit/_layout/contacts": {
      "filePath": "companies/$companyId/edit/_layout.contacts.route.tsx",
      "parent": "/companies/$companyId/edit/_layout"
    },
    "/companies/$companyId/edit/_layout/evaluation": {
      "filePath": "companies/$companyId/edit/_layout.evaluation.route.tsx",
      "parent": "/companies/$companyId/edit/_layout"
    },
    "/companies/$companyId/edit/_layout/locations": {
      "filePath": "companies/$companyId/edit/_layout.locations.route.tsx",
      "parent": "/companies/$companyId/edit/_layout",
      "children": [
        "/companies/$companyId/edit/_layout/locations/$locationId"
      ]
    },
    "/companies/$companyId/edit/_layout/payment": {
      "filePath": "companies/$companyId/edit/_layout.payment.route.tsx",
      "parent": "/companies/$companyId/edit/_layout"
    },
    "/companies/$companyId/edit/_layout/products": {
      "filePath": "companies/$companyId/edit/_layout.products.route.tsx",
      "parent": "/companies/$companyId/edit/_layout"
    },
    "/companies/$companyId/edit/_layout/registration": {
      "filePath": "companies/$companyId/edit/_layout.registration.route.tsx",
      "parent": "/companies/$companyId/edit/_layout"
    },
    "/product/$productId/edit/_layout/information": {
      "filePath": "product/$productId/edit/_layout.information.route.tsx",
      "parent": "/product/$productId/edit/_layout"
    },
    "/product/$productId/edit/_layout/instructions": {
      "filePath": "product/$productId/edit/_layout.instructions.route.tsx",
      "parent": "/product/$productId/edit/_layout"
    },
    "/product/$productId/edit/_layout/regulatory": {
      "filePath": "product/$productId/edit/_layout.regulatory.route.tsx",
      "parent": "/product/$productId/edit/_layout"
    },
    "/companies/$companyId/edit/_layout/locations/$locationId": {
      "filePath": "companies/$companyId/edit/_layout.locations.$locationId.route.tsx",
      "parent": "/companies/$companyId/edit/_layout/locations"
    }
  }
}
ROUTE_MANIFEST_END */
