import { useCallback, useEffect, useState } from "react"
import { useQuery } from "urql"

import type {
  PurchaseOrderCompanyShipToLocationListQuery,
  PurchaseOrderCompanyShipToLocationListQueryVariables,
} from "@/graphql/codegen/graphql"
import { CompanyStatus, PurchaseOrderCompanyShipToLocationListDocument } from "@/graphql/codegen/graphql.ts"
import type { CompanyListSuccess } from "@/graphql/codegen/graphql.ts"
import type { LocationAssociation } from "@/screens/Products/components/ProductShipLocationSelect"
import { deduplicateLocations } from "@/utils/locations"

export const LIMIT = 20
const OFFSET = 0

export const useGetPurchaseOrderShipToLocation = (): {
  shipToLocations: LocationAssociation[]
  shipToLocationListFetching: boolean
  loadMore: () => void
} => {
  const [offset, setOffset] = useState(OFFSET)
  const [paused, setPaused] = useState(false)
  const [shipToLocations, setShipToLocations] = useState<LocationAssociation[]>([])

  const [{ data: shipToCompanyList, fetching: shipToLocationListFetching }] = useQuery<
    PurchaseOrderCompanyShipToLocationListQuery,
    PurchaseOrderCompanyShipToLocationListQueryVariables
  >({
    query: PurchaseOrderCompanyShipToLocationListDocument,
    variables: {
      input: {
        filter: {
          types: ["AKROCHEM", "AKROCHEM_WAREHOUSE", "CONSIGNMENT_WAREHOUSE", "CUSTOMER"],
          status: CompanyStatus.Active,
        },
        limit: LIMIT,
        offset,
      },
    },
    pause: paused,
  })

  const loadMore = useCallback(() => {
    setPaused(false)
    setOffset((prevOffset) => prevOffset + LIMIT)
  }, [])

  useEffect(() => {
    const companyList = (shipToCompanyList?.company.list as CompanyListSuccess)?.companies || []

    if (!companyList.length && offset > 0) {
      setPaused(true)
      return
    }

    const newShippingLocations = companyList
      .flatMap((company) =>
        (company.locationsAssociations ?? []).map((locationAssociation) => ({
          ...locationAssociation.shipping,
          locationAssociationId: locationAssociation.companyLocationAssociationId,
        }))
      )
      .filter((location) => location.isActive)
      .filter((location): location is LocationAssociation => Boolean(location.locationAssociationId))

    setShipToLocations((prev) => {
      const combinedLocations = offset === 0 ? newShippingLocations : [...prev, ...newShippingLocations]
      const dedupedLocations = deduplicateLocations(combinedLocations) as LocationAssociation[]

      return dedupedLocations.sort((a, b) => a.name.localeCompare(b.name))
    })
  }, [shipToCompanyList, offset])

  return {
    shipToLocations,
    shipToLocationListFetching,
    loadMore,
  }
}
