import { Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { CalendarMonthOutlined } from "@mui-symbols-material/w300"
import dayjs from "dayjs"
import type { ReactElement } from "react"

interface AerosDatePickerProps {
  label: string
  required: boolean
  name: string
  value: string | null
  onChange: (date: string | null) => void
}

/**
 * A customized date picker component that wraps MUI's DatePicker with Aeros styling.
 * Displays a label above the picker and handles date values as ISO strings.
 *
 * @param props - The component props
 * @param props.label - Label text to display above the date picker
 * @param props.required - Whether the date picker is required
 * @param props.name - Name attribute for the input field
 * @param props.value - Selected date value as ISO string or null
 * @param props.onChange - Callback fired when date is changed
 */
export const AerosDatePicker = ({ label, required, name, value, onChange }: AerosDatePickerProps): ReactElement => {
  return (
    <>
      <Typography variant='caption' className='mb-1 text-gray-800'>
        {label} {required && <span className='text-secondary'>*</span>}
      </Typography>
      <DatePicker
        name={name}
        value={value ? dayjs(value) : null}
        onChange={(date) => onChange(date?.toISOString() || null)}
        slots={{
          openPickerIcon: CalendarMonthOutlined,
        }}
        slotProps={{
          textField: {
            variant: "outlined",
            size: "small",
            InputProps: {
              classes: {
                input:
                  "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2 text-sm",
              },
              sx: {
                height: 40,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: 1,
                  borderColor: "gray.200",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderWidth: 1,
                  borderColor: "gray.300",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderWidth: 1,
                  borderColor: "primary.200",
                },
              },
            },
            InputLabelProps: {
              shrink: false,
              sx: { display: "none" },
            },
          },
        }}
      />
    </>
  )
}
