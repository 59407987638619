import { Alert, Box, CircularProgress, Fade, Typography } from "@mui/material"
import { PersonAddOutlined, VisibilityOutlined, WarningOutlined } from "@mui-symbols-material/w300"
import { Link, useNavigate } from "@tanstack/react-router"
import React, { useMemo } from "react"
import type { ReactElement } from "react"
import { useQuery } from "urql"

import { Button } from "@/components/common/Button"
import type { ContactListFailure } from "@/graphql/codegen/graphql.ts"
import { CompanyLocationContactsDocument } from "@/graphql/codegen/graphql.ts"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

const ExpandedLocationRowContacts = ({
  companyId,
  locationId,
}: {
  companyId: string
  locationId: string
}): ReactElement => {
  const navigate = useNavigate()
  const [{ data, fetching, error }] = useQuery({
    query: CompanyLocationContactsDocument,
    variables: {
      input: {
        filter: {
          companyIds: [companyId],
          locationIds: [locationId],
        },
      },
    },
  })

  const notify = useNotificationsStore((state) => state.enqueueNotification)

  const hasError = useMemo(
    () =>
      Boolean(error?.message) ||
      Boolean(data?.contact?.list.__typename === "ContactListFailure" && data?.contact?.list.error.message),
    [error, data]
  )

  const contacts = useMemo(() => {
    if (data?.contact?.list.__typename === "ContactListSuccess") {
      return data.contact.list.contacts
    }
    return []
  }, [data])

  if (!fetching && hasError) {
    notify({
      message: "Failed to fetch contacts",
      content: error?.message || (data?.contact?.list as ContactListFailure).error.message,
      type: "error",
      silent: false,
    })

    return <Alert severity='error'>Failed to retrieve contacts</Alert>
  }

  const handleOpenContactsTab = () => {
    void navigate({
      to: `/companies/${companyId}/edit/contacts`,
    })
  }

  if (fetching) {
    return <CircularProgress />
  }

  if (!contacts.length) {
    return (
      <Typography variant='body2' className='font-normal leading-normal'>
        <span className='block text-gray-900'>
          <WarningOutlined color='error' /> No contacts added
        </span>
        <Box>
          Go to the&nbsp;
          <Link to={`/companies/${companyId}/edit/contacts`} className='text-primary-400 underline'>
            Contacts Tab
          </Link>
          &nbsp; to link a contact person for this location.
        </Box>
      </Typography>
    )
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Fade in={!fetching}>
        <Box key={contacts[0].contactId}>
          <Box>{contacts[0].title}</Box>
          <Box>
            {contacts[0].firstName}&nbsp;{contacts[0].lastName}
            {contacts[0].contactMethod &&
              contacts[0].contactMethod.map((method) => {
                if (!method) return null
                return (
                  <Box key={method.contactMethodId}>
                    {method.contactType}: {method.value}
                  </Box>
                )
              })}
          </Box>
          {contacts.length > 1 && (
            <Box className='flex flex-row gap-2'>
              <Button appearance='text' startIcon={<VisibilityOutlined />} size='small' onClick={handleOpenContactsTab}>
                All Contacts
              </Button>
              <Button appearance='text' startIcon={<PersonAddOutlined />} size='small'>
                Add New
              </Button>
            </Box>
          )}
        </Box>
      </Fade>
    </Box>
  )
}

export default ExpandedLocationRowContacts
