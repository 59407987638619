import type { AnyRouter } from "@tanstack/react-router"
import { useNavigate, useSearch } from "@tanstack/react-router"
import { useCallback } from "react"
import type { FC } from "react"

import type { PurchaseOrdersSearchParams } from "../../types/zod"

import { PurchaseOrdersFilter } from "./PurchaseOrderFilter"

import { SearchFilterDrawer } from "@/components/common/SearchFilterDrawer/SearchFilterDrawer"
import { OrderListFilterSchema } from "@/graphql/codegen/graphql"
import { getRefinedSearchParams } from "@/screens/Companies/utils"

type PurchaseOrderSearchFilterProps = {
  routePath: string
  showFilterDrawer: boolean
  toggleDrawer: () => void
}

const OrderFilterSchema = OrderListFilterSchema().extend({
  statuses: OrderListFilterSchema().shape.statuses.default([]),
  shipStatuses: OrderListFilterSchema().shape.shipStatuses.default([]),
  query: OrderListFilterSchema().shape.query.default(""),
})

export const PurchaseOrderFilterDrawer: FC<PurchaseOrderSearchFilterProps> = ({
  routePath,
  showFilterDrawer,
  toggleDrawer,
}) => {
  const navigate = useNavigate({ from: routePath })
  const currentSearch = useSearch<AnyRouter, undefined, true, PurchaseOrdersSearchParams>({ from: routePath })

  const handleClearFilters = useCallback(() => {
    const {
      search: { start, end, ...rest },
      replace,
    } = getRefinedSearchParams(currentSearch, OrderFilterSchema.parse({}))
    void navigate({ search: { ...rest, query: currentSearch?.query }, replace })
  }, [currentSearch, navigate])

  return (
    <SearchFilterDrawer toggleDrawer={toggleDrawer} open={showFilterDrawer} clear={handleClearFilters} width={380}>
      <PurchaseOrdersFilter routePath={routePath} />
    </SearchFilterDrawer>
  )
}
