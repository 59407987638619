import type { SelectChangeEvent } from "@mui/material"
import { Box, Checkbox, Chip, MenuItem, Select } from "@mui/material"
import { KeyboardArrowDownOutlined, MoreHorizOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { memo, useCallback, useEffect, useRef, useState } from "react"
import type { Control } from "react-hook-form"
import { useController } from "react-hook-form"

import { useGetLocationAssociation } from "./Product/Information/hooks/useGetLocationAssociation"

import type { Location } from "@/graphql/codegen/graphql.ts"
import { formatLocationAddress } from "@/utils/utils"

type ProductShipLocationSelectProps = {
  name: string
  control: Control
}

export type LocationAssociation = Location & { locationAssociationId: string }

const ProductShipLocationSelect: FunctionComponent<ProductShipLocationSelectProps> = ({ name, control }) => {
  const { field: companyIdField } = useController({
    name: "companyId",
    control,
  })
  const { locationsAssociationsList } = useGetLocationAssociation({
    companyId: companyIdField.value,
  })
  const shippingLocations: LocationAssociation[] = locationsAssociationsList
    .map(({ shipping, companyLocationAssociationId }) => {
      return {
        ...shipping,
        locationAssociationId: companyLocationAssociationId,
        address1: shipping?.address1 ?? "",
        city: shipping?.city ?? "",
        regionIsoCode: shipping?.regionIsoCode ?? "",
      }
    })
    .filter(({ address1, city, regionIsoCode }) => {
      return Boolean(address1 || city || regionIsoCode)
    })

  const { field } = useController({
    name,
    control,
  })

  const containerRef = useRef<HTMLDivElement>(null)
  const measureRef = useRef<HTMLDivElement>(null)
  const [visibleChips, setVisibleChips] = useState<string[]>([])
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)

  const updateVisibleChips = useCallback(() => {
    if (measureRef.current && containerRef.current) {
      const containerWidth = containerRef.current.clientWidth - 40 // 32 is the width of the MoreHorizOutlined icon
      let totalWidth = 0
      const visibleChipsArray: string[] = []

      Array.from(measureRef.current.children).forEach((chip, index) => {
        const chipWidth = (chip as HTMLElement).offsetWidth
        if (totalWidth + chipWidth <= containerWidth) {
          visibleChipsArray.push(field.value[index].locationAssociationId)
          totalWidth += chipWidth
        }
      })
      setVisibleChips(visibleChipsArray)
      setIsOverflowing(visibleChipsArray.length < field.value.length)
    }
  }, [field.value])

  useEffect(() => {
    updateVisibleChips()
  }, [field.value])

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value as string[]
    const selectedLocations = selectedIds
      .map((id) => {
        return shippingLocations.find(
          ({ locationAssociationId }) => locationAssociationId === id
        ) as LocationAssociation
      })
      .filter(Boolean)
    field.onChange(selectedLocations)
  }

  if (!field.value) return null

  return (
    <Box>
      <label className='block text-sm font-normal text-gray-700'>Product Ship From Location</label>
      <Select
        multiple
        fullWidth
        IconComponent={KeyboardArrowDownOutlined}
        renderValue={() => (
          <Box className='flex overflow-hidden align-middle' ref={containerRef}>
            <Box className='flex flex-nowrap gap-1'>
              {visibleChips.map((id) => {
                const location = shippingLocations.find(({ locationAssociationId }) => locationAssociationId === id)
                return location ? (
                  <Chip key={id} label={formatLocationAddress(location)} className='rounded-sm' />
                ) : null
              })}
            </Box>
            {isOverflowing && (
              <Box className='mx-1 flex size-8 items-center justify-center rounded bg-gray-300'>
                <MoreHorizOutlined className='ml-0 shrink-0' />
              </Box>
            )}
          </Box>
        )}
        className='mb-2 h-10 border-none hover:border-gray-400'
        value={field.value.map(({ locationAssociationId }: LocationAssociation) => locationAssociationId)}
        onChange={handleChange}
      >
        {shippingLocations.map(({ locationAssociationId, address1, city, regionIsoCode }) => (
          <MenuItem key={locationAssociationId} value={locationAssociationId}>
            <Checkbox
              checked={field.value.some(
                (location: LocationAssociation) => location.locationAssociationId === locationAssociationId
              )}
            />
            {`${address1} - ${city} ${regionIsoCode}`}
          </MenuItem>
        ))}
      </Select>
      <Box className='invisible absolute' ref={measureRef}>
        {field.value.map(({ locationAssociationId, address1, city, regionIsoCode }: LocationAssociation) => (
          <Chip key={locationAssociationId} label={`${address1} - ${city} ${regionIsoCode}`} />
        ))}
      </Box>
    </Box>
  )
}

export default memo(ProductShipLocationSelect)
