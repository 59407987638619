import Box from "@mui/material/Box"
import {
  BarChartOutlined,
  BusinessCenterOutlined,
  DashboardOutlined,
  DescriptionOutlined,
  InventoryOutlined,
  SellOutlined,
  ShoppingCartOutlined,
  SourceEnvironmentOutlined,
} from "@mui-symbols-material/w300"

import type { IconMapping, LabelMapping } from "@/types/routes.ts"

export const labelMapping: LabelMapping = {
  "/": "Dashboard",
  "/procurement": "Procurement",
  "/procurement/vendors": "Vendors",
  "/procurement/purchase-orders": "Purchase Orders",
  "/procurement/supplier-report": "Supplier Report",
  "/sales": "Sales",
  "/sales/customers": "Customers",
  "/sales/sales-orders": "Sales Orders",
  "/inventory": "Inventory Management",
  "/inventory/products": "Products",
  "/inventory/transactions": "Transactions",
  "/companies": "Companies",
  "/companies/new/$companyId": "Create New Company",
  "/regulatory": "Regulatory",
  "/finance": "Finance",
  "/company": "Our Company",
}

// Define the mapping for icons
export const iconMapping: IconMapping = {
  "/": <DashboardOutlined width={20} height={20} />,
  "/procurement": <ShoppingCartOutlined />,
  "/sales": <SellOutlined width={20} height={20} />,
  "/inventory": <InventoryOutlined width={20} height={20} />,
  "/companies": <SourceEnvironmentOutlined width={20} height={20} />,
  "/regulatory": <DescriptionOutlined width={20} height={20} />,
  "/finance": <BarChartOutlined width={20} height={20} />,
  "/company": <BusinessCenterOutlined width={20} height={20} />,
  DEFAULT: <Box width={20} height={20} />,
}
