import { notFound } from "@tanstack/react-router"
import type { StoreApi } from "zustand"

import { PurchaseOrderSection } from "./types"

import type {
  CompanyGetSuccess,
  CompanyLocationAssociation,
  Location,
  LocationGetSuccess,
  OrderGetSuccess,
  UnitOfMeasurement,
} from "@/graphql/codegen/graphql"
import {
  GetPurchaseOrderDocument,
  PurchaseOrderCompanyLocationAssociationsDocument,
  PurchaseOrderLocationDocument,
  ShipStatus,
} from "@/graphql/codegen/graphql"
import { queryClient } from "@/providers/GraphqlRouterProvider"
import { useUnitOfMeasurementsStore } from "@/stores/unitOfMeasurmentsStore"
import type { PurchaseOrderState } from "@/stores/usePurchaseOrderStore"
import { createPurchaseOrderStore } from "@/stores/usePurchaseOrderStore"

const getPurchaseOrder = async ({ params }: { params: { purchaseOrderId: string } }) => {
  const { data, error } = await queryClient.query(GetPurchaseOrderDocument, {
    input: { orderId: Number(params.purchaseOrderId) },
    requestPolicy: "network-only",
  })

  if (data?.order.get.__typename === "OrderGetFailure" || error) {
    console.error(error)
    throw notFound()
  }

  const purchaseOrder = (data?.order.get as OrderGetSuccess).order

  return { purchaseOrder }
}

const getShipFromLocation = async ({ id }: { id: string | undefined }) => {
  if (!id) {
    return { shipFromLocation: null }
  }

  const { data, error } = await queryClient.query(PurchaseOrderLocationDocument, {
    locationId: id,
  })

  if (data?.location.get.__typename === "LocationGetFailure" || error) {
    console.error(error)
    return { shipFromLocation: null }
  }

  const shipFromLocation = (data?.location.get as LocationGetSuccess).location

  return { shipFromLocation }
}

const getPurchaseOrderCompanyLocationAssociation = async ({ id }: { id: string | undefined }) => {
  if (!id) {
    return { purchaseOrderCompanyLocationAssociation: null }
  }

  const { data, error } = await queryClient.query(PurchaseOrderCompanyLocationAssociationsDocument, {
    input: { companyId: id },
  })

  if (data?.company.get.__typename === "CompanyGetFailure" || error) {
    console.error(error, data?.company.get.error.message)
    return { purchaseOrderCompanyLocationAssociation: null }
  }

  const purchaseOrderCompanyLocationAssociation = (data?.company.get as CompanyGetSuccess).company.locationsAssociations

  return { purchaseOrderCompanyLocationAssociation: purchaseOrderCompanyLocationAssociation ?? null }
}

type RouteDataPreload = {
  store: StoreApi<PurchaseOrderState>
  units: UnitOfMeasurement[]
  shipFromLocation: Location | null
  purchaseOrderCompanyLocationAssociation: CompanyLocationAssociation[] | null
}

export const routeDataPreload = async ({
  params,
}: {
  params: { purchaseOrderId: string }
}): Promise<RouteDataPreload> => {
  const { purchaseOrder } = await getPurchaseOrder({ params })
  const units = await useUnitOfMeasurementsStore.getState().getUnits()

  const store = createPurchaseOrderStore()

  // Initialize state
  store.setState((state) => ({
    ...state,
    currentPO: purchaseOrder,
    formValues: {
      ...state.formValues,
      [PurchaseOrderSection.HEADER]: {
        orderId: purchaseOrder.orderId,
        createdByUser: purchaseOrder.createdByUser!,
        sourceLocationAssociationId: purchaseOrder.sourceLocationAssociationId!,
        destinationLocationAssociationId: purchaseOrder.destinationLocationAssociationId!,
        requestedDeliveryDate: purchaseOrder.requestedDeliveryDate!,
        expectedDeliveryDate: purchaseOrder.expectedDeliveryDate,
        sentDate: purchaseOrder.sentDate!,
      },
      [PurchaseOrderSection.DELIVERY_TERMS]: {
        deliveryTerms: purchaseOrder.deliveryTerms ?? "",
        deliveryLocationId: purchaseOrder.deliveryLocationId ?? "",
        freightTerms: purchaseOrder.freightTerms ?? "",
        shipViaCompanyId: purchaseOrder.shipViaCompanyId ?? "",
        shipStatus: purchaseOrder.shipStatus || ShipStatus.Pending,
      },
      [PurchaseOrderSection.SHIPPING_LEGS]:
        purchaseOrder.shippingLegs?.map((leg) => ({
          ...leg,
          shippingLegId: leg.shippingLegId ?? "",
        })) ?? [],
      [PurchaseOrderSection.ORDER_LINES]:
        purchaseOrder.orderLines?.map((orderLine) => ({
          ...orderLine,
          numberOfPallets: orderLine.numberOfPallets ?? 0,
          pricePerUom: orderLine.pricePerUom ?? 0,
          quantityInUom: orderLine.quantityInUom ?? 0,
          productId: orderLine.productId ?? "",
          uomId: orderLine.uomId ?? "",
          priceUomId: orderLine.priceUomId ?? "",
        })) ?? [],
      [PurchaseOrderSection.NOTES]: purchaseOrder.note ?? "",
      [PurchaseOrderSection.COMMENTS]: {
        currentComment: {},
        comments: [],
      },
    },
  }))

  const { shipFromLocation } = await getShipFromLocation({
    id: purchaseOrder.sourceLocationAssociationDetail?.companyLocationAssociation?.orderLocationId ?? undefined,
  })

  const companyId = purchaseOrder.orderLines?.find((orderLine) => orderLine.productDetail?.product?.companyId)
    ?.productDetail.product?.companyId

  const { purchaseOrderCompanyLocationAssociation } = await getPurchaseOrderCompanyLocationAssociation({
    id: companyId ?? undefined,
  })

  return { store, units, shipFromLocation, purchaseOrderCompanyLocationAssociation }
}
