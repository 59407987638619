import { Box, Typography } from "@mui/material"
import type { Row } from "@tanstack/react-table"
import type { ReactElement } from "react"
import { getConvertedUoMValue } from "utils/conversionUtils"

import { UOM_LABELS } from "@/constants/unitOfMeasurements"
import type { OrderLine } from "@/graphql/codegen/graphql"
import { formatNumber } from "@/utils/formatNumber"

export const PriceUomColumnReadOnly = ({ row }: { row: Row<OrderLine> }): ReactElement => {
  const { pricePerUom, priceUomId, quantityInUom, uomId } = row.original
  const formattedPrice = pricePerUom ? `$${formatNumber(pricePerUom)} / ${UOM_LABELS[priceUomId]}` : "Not calculated"
  const convertedUoMValue = getConvertedUoMValue(quantityInUom, uomId)

  return (
    <Box>
      <Box className='flex gap-2'>
        <Typography className='font-bold'>{formattedPrice}</Typography>
        {convertedUoMValue && (
          <Typography variant='caption' className='pt-1'>
            {convertedUoMValue}
          </Typography>
        )}
      </Box>
      <Box className='flex gap-2'>
        <Typography className='font-bold'>{`${quantityInUom} ${UOM_LABELS[uomId]}`}</Typography>
        <Typography>{`(${convertedUoMValue})`}</Typography>
      </Box>
    </Box>
  )
}
