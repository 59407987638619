import { Typography } from "@mui/material"
import type { ReactNode } from "react"

interface SelectPlaceholderProps {
  field?: {
    value?: string | null
  }
  placeholderText?: string
}

/**
 * Renders a placeholder for a select component
 *
 * @param options - The options for the placeholder
 * @param options.field - The field object from react-hook-form
 * @param options.field.value - The value of the field
 * @param options.placeholderText - The text to display in the placeholder
 *
 * @example
 * ```tsx
 * <Select
 *   {...field}
 *   renderValue={renderSelectPlaceholder({ field })}
 * >
 * </Select>
 * ```
 *
 * @example
 * ```tsx
 * <Select
 *   {...field}
 *   renderValue={renderSelectPlaceholder({ field, placeholderText: "Select a value" })}
 * >
 * </Select>
 * ```
 */
const renderSelectPlaceholder = ({ field, placeholderText = "Select One", ...rest }: SelectPlaceholderProps = {}):
  | ((value: string | null) => ReactNode)
  | undefined =>
  field?.value === ""
    ? () => (
        <Typography color='textDisabled' {...rest}>
          {placeholderText}
        </Typography>
      )
    : undefined

export default renderSelectPlaceholder
export { renderSelectPlaceholder }
