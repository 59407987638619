import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import { CloseOutlined } from "@mui-symbols-material/w300"
import type { FunctionComponent } from "react"
import { useCallback } from "react"
import { useFormContext } from "react-hook-form"
import type { z } from "zod"

import { ShippingDetailsContent } from "./ShippingDetailsContent"
import type { ShippingDetailsSchema } from "./ShippingDetailsSchema"

type ShippingDetailsSchemaType = z.infer<typeof ShippingDetailsSchema>

import { Button } from "@/components/common/Button"

type AddShippingDetailsModalProps = {
  open: boolean
  onClose: (action: "cancel" | "success" | "backdropClick" | "escapeKeyDown") => void
  onSubmit: (data: ShippingDetailsSchemaType) => void
}

const AddShippingDetailsModal: FunctionComponent<AddShippingDetailsModalProps> = ({ open, onClose, onSubmit }) => {
  const methods = useFormContext<ShippingDetailsSchemaType>()

  const handleSubmit = useCallback(
    (value: ShippingDetailsSchemaType) => {
      onSubmit(value)
      onClose("success")
    },
    [onClose, onSubmit]
  )

  const isSaveButtonEnabled = methods.formState.isValid

  const handleResetClick = useCallback(() => {
    methods.reset()
    onClose("cancel")
  }, [methods, onClose])

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        methods.reset()
        onClose(reason)
      }}
      fullWidth
    >
      <DialogTitle className='pl-10'>
        <span className='font-normal text-primary'>Supplier Shipping Details</span>
      </DialogTitle>
      <IconButton aria-label='close' onClick={() => onClose("cancel")} className='absolute right-4 top-4 text-primary'>
        <CloseOutlined />
      </IconButton>
      <Divider />
      <DialogContent classes={{ root: "p-10" }}>
        <ShippingDetailsContent />
      </DialogContent>
      <DialogActions className={"justify-between px-10 pb-10"}>
        <Button appearance='outlined' onClick={handleResetClick}>
          Cancel
        </Button>
        <Button onClick={methods.handleSubmit(handleSubmit)} disabled={!isSaveButtonEnabled}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddShippingDetailsModal
