import { Link, Typography } from "@mui/material"
import { Link as RouterLink } from "@tanstack/react-router"
import type { ColumnDef } from "@tanstack/react-table"
import dayjs from "dayjs"

import { OrderStatusChip } from "../../PurchaseOrder/components/OrderStatusChip"
import { ShipStatusFlatChip } from "../../PurchaseOrder/components/ShipStatusFlatChip"
import { PurchaseOrderActions } from "../components/PurchaseOrderActions"

import StyledChip from "@/components/common/Chip/Chip"
import type { OrderStatus, ShipStatus } from "@/graphql/codegen/graphql"
import { ProductOrderPriceUomColumn } from "@/screens/Procurement/PurchaseOrder/ProductAdded/components/ProductOrderPriceUomColumn.tsx"

export const getProductOrderColumns = (): ColumnDef<any>[] => [
  {
    header: "Product ID",
    accessorKey: "productId",
    accessorFn: (row) => row.sourceOrderLocationDetail?.location?.name,
    cell: ({ row }) => <Typography component='span'>{row.original.productId}</Typography>,
  },
  {
    header: "Description",
    accessorKey: "companyProductDescription",
    cell: ({ row }) => <Typography component='span'>{row.original.companyProductDescription}</Typography>,
  },
  {
    header: "PO No.",
    accessorKey: "orderId",
    cell: ({ row }) => (
      <Link
        component={RouterLink}
        to={`/procurement/purchase-orders/${row.original.orderId || null}`}
        className='text-tertiary-base no-underline'
      >
        {row.original.orderId}
      </Link>
    ),
  },
  {
    header: "Status",
    accessorKey: "status",
    cell: ({ row }) => <OrderStatusChip status={row.original.status as OrderStatus} />,
  },
  {
    header: "Ship Status",
    accessorKey: "shipStatus",
    cell: ({ row }) => <ShipStatusFlatChip shipStatus={row.original.shipStatus as ShipStatus} />,
  },
  {
    header: "Requested",
    accessorKey: "requestedDeliveryDate",
    cell: ({ getValue }) => {
      const date = getValue<string>()
      return (
        <StyledChip
          label={
            <span>
              {date && <strong>By</strong>} {date ? dayjs(date).format("MM/DD/YYYY") : "--/--/----"}
            </span>
          }
          className='text-tertiary-base'
          color='primary'
        />
      )
    },
  },
  {
    header: "Expected",
    accessorKey: "expectedDeliveryDate",
    cell: ({ getValue }) => {
      const date = getValue<string>()
      return (
        <StyledChip
          label={
            <span>
              {date && <strong>By</strong>} {date ? dayjs(date).format("MM/DD/YYYY") : "--/--/----"}
            </span>
          }
          className='text-tertiary-base'
          color='primary'
        />
      )
    },
  },
  {
    header: "Price/UoM",
    accessorKey: "uom",
    cell: ({ row }) => <ProductOrderPriceUomColumn row={row} />,
    size: 200,
    enableSorting: false,
  },
  {
    id: "expand",
    header: "",
    cell: ({ row }) => <PurchaseOrderActions row={row} />,
    enableSorting: false,
  },
]
