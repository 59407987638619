import { Box, Typography } from "@mui/material"
import type { ReactElement, ReactNode } from "react"

type PageHeaderProps = {
  children: ReactNode
  title: string
}

export const PageHeader = ({ children, title }: PageHeaderProps): ReactElement => {
  return (
    <Box className='flex items-center justify-between p-6 pb-8'>
      <Typography variant='h5' color='primary' fontWeight='normal'>
        {title}
      </Typography>
      {children}
    </Box>
  )
}
