import { Box } from "@mui/material"
import { AddOutlined } from "@mui-symbols-material/w300"
import React, { useCallback, useState } from "react"
import { FormProvider } from "react-hook-form"
import { useShallow } from "zustand/react/shallow"

import { usePricingPackage } from "../hooks/usePricingPackage"

import { PricingPackageTable } from "./PricingPackageTable"

import { Button } from "@/components/common/Button"
import { formattedTierData } from "@/constants/pricingTiers"
import type {
  CreatePricingPackageFailure,
  CreatePricingPackageInput,
  PricingPackage,
  UpdatePricingPackageFailure,
  UpdatePricingPackageInput,
} from "@/graphql/codegen/graphql"
import AddPricingTiersModal from "@/screens/Products/components/PricingTiers/AddPricingTiersModal"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

interface PricingAndShippingTermsProps {
  companyId: string
  productId: number
  currentPricing?: PricingPackage
  isEmptyFuturePricing?: boolean
  reexecuteQueryWithReset: () => void
}

export const PricingAndShippingTerms: React.FC<PricingAndShippingTermsProps> = ({
  companyId,
  productId,
  currentPricing,
  isEmptyFuturePricing = false,
  reexecuteQueryWithReset,
}) => {
  const [open, setOpen] = useState(false)

  const notify = useNotificationsStore(useShallow(({ enqueueNotification }) => enqueueNotification))
  const { createPricingTier, methods, updatePricingTier, onPricingEditClick } = usePricingPackage()

  const handleSubmit = useCallback(
    async (data: CreatePricingPackageInput | UpdatePricingPackageInput) => {
      const pricingPackageId = methods.getValues("pricingPackageId")
      const { pricingPackage, pricingTiers } = formattedTierData({
        ...data,
        companyId,
        productId,
      })
      const { error, data: result } = await (pricingPackageId
        ? updatePricingTier({
            input: {
              pricingPackageToUpdate: { ...pricingPackage, pricingPackageId, productId: productId.toString() },
              pricingTiersToUpdate: pricingTiers,
            } as UpdatePricingPackageInput,
          })
        : createPricingTier({
            input: {
              pricingPackageToCreate: pricingPackage,
              pricingTiersToCreate: pricingTiers,
            } as CreatePricingPackageInput,
          }))

      const failure = pricingPackageId
        ? (result?.pricingPackage?.update as UpdatePricingPackageFailure)?.error
        : (result?.pricingPackage?.create as CreatePricingPackageFailure)?.error

      if (error || failure) {
        notify({
          type: "error",
          message: `Pricing and Shipping terms ${pricingPackageId ? "update" : "creation"} failed`,
        })
      } else {
        notify({
          type: "success",
          message: `Pricing and Shipping terms successfully ${pricingPackageId ? "updated" : "added"}`,
        })
        reexecuteQueryWithReset()
      }
      methods.reset()
      setOpen(false)
    },
    [companyId, productId, updatePricingTier, createPricingTier, methods, notify, reexecuteQueryWithReset]
  )

  return (
    <>
      {isEmptyFuturePricing ? (
        <Box className='flex justify-end'>
          <Button variant='primary' appearance='text' startIcon={<AddOutlined />} onClick={() => setOpen(true)}>
            Pricing and Shipping terms
          </Button>
        </Box>
      ) : currentPricing ? (
        <PricingPackageTable
          title='Current Pricing'
          pricingDetails={currentPricing}
          onEditClick={(uomName) => {
            onPricingEditClick(currentPricing, uomName)
            setOpen(true)
          }}
        />
      ) : (
        <></>
      )}
      <FormProvider {...methods}>
        <AddPricingTiersModal
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          onSubmit={handleSubmit}
        />
      </FormProvider>
    </>
  )
}
