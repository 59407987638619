import { Alert, AlertTitle } from "@mui/material"
import type { FC } from "react"

interface ErrorMessageProps {
  error: Error
  title?: string
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ error, title = "Error" }) => {
  return (
    <Alert severity='error'>
      <AlertTitle>{title}</AlertTitle>
      {error.message}
    </Alert>
  )
}
