import type { SnackbarCloseReason } from "@mui/material"
import React, { useCallback, useMemo } from "react"

import { NotificationTemplate } from "@/components/common/Notifications/NotificationTemplate.tsx"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

export const Notifications: React.FC = () => {
  const { notifications, dequeueNotification, markAsRead, getUnreadCount } = useNotificationsStore()

  // Memoize active notification - only show unread, non-silent ones
  const activeNotification = useMemo(
    () => notifications.find((notification) => !notification.silent && !notification.read),
    [notifications]
  )

  // Handle manual close (clicking X or away)
  const handleClose = useCallback(
    (_?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
      if (!activeNotification || reason === "clickaway") {
        return
      }

      markAsRead(activeNotification.id)
      dequeueNotification()
    },
    [activeNotification, markAsRead, dequeueNotification]
  )

  // Handle auto-hide timeout
  const handleExited = useCallback(() => {
    if (activeNotification) {
      markAsRead(activeNotification.id)
    }
  }, [activeNotification, markAsRead])

  // Don't render anything if no active notification
  if (!activeNotification) {
    return null
  }

  return (
    <NotificationTemplate
      {...activeNotification}
      open={true} // More explicit than Boolean(activeNotification)
      unreadCount={getUnreadCount()}
      handleSnackbarClose={handleClose}
      handleExited={handleExited}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    />
  )
}
