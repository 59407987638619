import { Paper } from "@mui/material"
import { cva } from "class-variance-authority"
import type { ReactElement, ReactNode } from "react"
import { twMerge } from "tailwind-merge"

type SectionContainerProps = {
  children?: ReactNode
  className?: string
  paddingVariant?: "none" | "default"
}

const sectionContainerStyles = cva("flex min-h-0 grow flex-col overflow-hidden bg-white", {
  variants: {
    paddingVariant: {
      none: "",
      default: "p-6",
    },
  },
})

export const SectionContainer = ({
  children,
  className,
  paddingVariant = "default",
}: SectionContainerProps): ReactElement => {
  return (
    <Paper elevation={0} className={twMerge(sectionContainerStyles({ paddingVariant }), className)}>
      {children}
    </Paper>
  )
}
