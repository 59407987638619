import type { AuthConfig, AuthUtilities } from "@urql/exchange-auth"
import { authExchange } from "@urql/exchange-auth"

import { useAuthStore } from "@/stores/authStore"
import { exchangeTokenWithAPI, refreshTokenWithAPI } from "@/utils/auth/exchangeToken"
import { isTokenExpired } from "@/utils/auth/isTokenExpired"

let isRefreshing = false
let refreshPromise: Promise<void> | null = null

export const auth = authExchange(async (utilities: AuthUtilities): Promise<AuthConfig> => {
  return {
    addAuthToOperation(operation) {
      const { accessToken } = useAuthStore.getState().tokens

      if (!accessToken) {
        useAuthStore.getState().signIn()
        return {
          ...operation,
          context: {
            ...operation.context,
            skipOperation: true,
          },
        }
      }

      return utilities.appendHeaders(operation, {
        Authorization: `Bearer ${accessToken}`,
      })
    },

    didAuthError(error) {
      return error.graphQLErrors.some((e) => e.extensions?.code === "UNAUTHORIZED")
    },

    willAuthError() {
      const { accessToken } = useAuthStore.getState().tokens
      return !accessToken || isTokenExpired(accessToken)
    },

    async refreshAuth(): Promise<void> {
      if (isRefreshing) {
        await refreshPromise
        return
      }

      isRefreshing = true
      refreshPromise = (async (): Promise<void> => {
        const store = useAuthStore.getState()
        const { refreshToken, idToken } = store.tokens

        try {
          let tokens
          if (refreshToken) {
            tokens = await refreshTokenWithAPI({
              accessToken: store.tokens.accessToken!,
              refreshToken,
            })
          } else if (idToken) {
            tokens = await exchangeTokenWithAPI(idToken)
          } else {
            store.signIn()
            return
          }

          store.setTokens(tokens)
        } catch (error) {
          console.error("Token refresh failed:", error)
          store.clearAuth()
          store.signIn()
        } finally {
          isRefreshing = false
          refreshPromise = null
        }
      })()

      return refreshPromise
    },
  }
})
