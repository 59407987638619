import type { OperationContext, OperationResult } from "urql"
import { useMutation } from "urql"

import type {
  Mutation,
  PricingPackageMutationCreateArgs,
  PricingPackageMutationUpdateArgs,
} from "@/graphql/codegen/graphql"
import { CreatePricingTierMutationDocument, UpdatePricingTierMutationDocument } from "@/graphql/codegen/graphql"

export const useCreatePricingTier = (): {
  createPricingTier: (
    variables: PricingPackageMutationCreateArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "pricingPackage">, PricingPackageMutationCreateArgs>>
  updatePricingTier: (
    variables: PricingPackageMutationUpdateArgs,
    context?: Partial<OperationContext>
  ) => Promise<OperationResult<Pick<Mutation, "pricingPackage">, PricingPackageMutationUpdateArgs>>
} => {
  const [, createPricingTier] = useMutation<Pick<Mutation, "pricingPackage">, PricingPackageMutationCreateArgs>(
    CreatePricingTierMutationDocument
  )

  const [, updatePricingTier] = useMutation<Pick<Mutation, "pricingPackage">, PricingPackageMutationUpdateArgs>(
    UpdatePricingTierMutationDocument
  )

  return {
    createPricingTier,
    updatePricingTier,
  }
}
