import { Box, IconButton, Paper, Tab, Tabs, Typography } from "@mui/material"
import { ChevronLeftOutlined, KeyboardArrowRightOutlined } from "@mui-symbols-material/w300"
import type { AnyRoute } from "@tanstack/react-router"
import { Link, Outlet, createFileRoute, useLocation, useParams, useRouter } from "@tanstack/react-router"
import { useMemo, useState } from "react"
import { FormProvider } from "react-hook-form"

import { Button } from "@/components/common/Button"
import { useEditProduct } from "@/screens/Companies/products/hooks/useEditProduct.tsx"
import { usePurchaseOrderDialog } from "@/screens/Procurement/hooks/usePurchaseOrderDialog"
import EditProductModal from "@/screens/Products/components/EditProductModal"
import { ProductStartPurchaseOrder } from "@/screens/Products/components/Product/ProductStartPurchaseOrder"
import ProductHeader from "@/screens/Products/components/ProductHeader"
import { useGetProduct } from "@/screens/Products/hooks/useGetProduct"

const createTabList = (productId: string) => [
  { label: "Information", path: `/product/${productId}/edit/information` },
  { label: "Regulatory", path: `/product/${productId}/edit/regulatory` },
  { label: "Instructions", path: `/product/${productId}/edit/instructions` },
]

const ProductEditLayout = () => {
  const router = useRouter()
  const location = useLocation()
  const currentPath = location.pathname
  const [editProductModal, setEditProductModal] = useState(false)
  const { productId: queryProductId } = useParams({
    from: "/product/$productId/edit",
  })

  const { productDetails } = useGetProduct(Number(queryProductId))

  const tabs = useMemo(() => createTabList(queryProductId), [queryProductId])

  const productManagerName = useMemo(
    () =>
      productDetails?.productManager?.firstName && productDetails?.productManager?.lastName
        ? `${productDetails.productManager.firstName} ${productDetails.productManager.lastName}`
        : undefined,
    [productDetails]
  )

  const classificationName = useMemo(() => {
    return productDetails?.classification?.description ?? ""
  }, [productDetails?.classification])
  const { methods, handleSubmit } = useEditProduct({ productId: Number(queryProductId) })
  const { open, fetching, handleOpen, handleClose, handleSubmit: handlePurchaseOrderSubmit } = usePurchaseOrderDialog()

  return (
    <>
      {productDetails && (
        <ProductStartPurchaseOrder
          open={open}
          fetching={fetching}
          onClose={handleClose}
          onSubmit={handlePurchaseOrderSubmit}
          product={productDetails}
        />
      )}
      <Box className='size-full px-6 pb-6'>
        <div className='flex h-full flex-col gap-4'>
          <Paper elevation={0} className='flex min-h-0 grow flex-col space-y-7 overflow-hidden bg-white p-6'>
            <Box className='flex justify-between'>
              <Box className='flex items-center gap-4'>
                <IconButton
                  aria-label='back to contacts'
                  className='text-primary-500 rounded-none bg-gray-200'
                  onClick={() => router.history.back()}
                >
                  <ChevronLeftOutlined />
                </IconButton>
                <Typography variant='h5' color='primary' fontWeight='normal'>
                  Product Detail
                </Typography>
              </Box>
              <Button
                appearance='outlined'
                color='primary'
                endIcon={<KeyboardArrowRightOutlined />}
                onClick={handleOpen}
              >
                Start PO
              </Button>
            </Box>
            <Box className='space-y-2.5'>
              <ProductHeader
                id={queryProductId}
                isActive={productDetails?.isActive || false}
                productDescription={productDetails?.companyProductDescription || ""}
                vendorDescription={productDetails?.vendorDescription || ""}
                vendorSku={productDetails?.vendorSku || ""}
                productManager={productManagerName}
                classification={classificationName}
                companyName={productDetails?.company?.name || ""}
                onEditClick={() => setEditProductModal(true)}
              />
            </Box>
            <Box className='flex w-full flex-1 flex-col overflow-y-auto'>
              <Tabs value={currentPath} variant='scrollable' scrollButtons='auto'>
                {tabs.map((tab, index) => (
                  <Tab key={index} label={tab.label} value={tab.path} component={Link} to={tab.path} />
                ))}
              </Tabs>
              <Box className='flex min-h-0 grow flex-col py-4'>
                <Outlet />
              </Box>
            </Box>
          </Paper>
        </div>
        <FormProvider {...methods}>
          <EditProductModal
            open={editProductModal}
            onClose={() => {
              setEditProductModal(false)
            }}
            onSubmit={handleSubmit}
          />
        </FormProvider>
      </Box>
    </>
  )
}

export const Route: AnyRoute = createFileRoute("/product/$productId/edit/_layout")({
  component: ProductEditLayout,
})
