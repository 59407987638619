import { KILOGRAM_UOM_ID, POUND_UOM_ID } from "@/constants/unitOfMeasurements"
import type { PricingPackage } from "@/graphql/codegen/graphql"

export const getVendorPrice = (pricingPackage: PricingPackage, quantityInUom: number, uomId: string): number => {
  // Check if pricing package, quantity, and UOM are available
  // If pricing package unit of measurement does not match the selected UOM, return 0
  if (
    !pricingPackage ||
    !quantityInUom ||
    !uomId ||
    (pricingPackage.unitOfMeasurementId !== POUND_UOM_ID && pricingPackage.unitOfMeasurementId !== KILOGRAM_UOM_ID)
  )
    return 0

  let vendorPricing = 0
  const { pricingTiers = [] } = pricingPackage
  pricingTiers?.some((tier, index) => {
    if (quantityInUom <= tier.numberOfUnits || index === pricingTiers.length - 1) {
      vendorPricing = tier.pricePerUnit
      return true
    }
  })
  return vendorPricing
}
