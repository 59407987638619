import { z } from "zod"

import type { ProductSelectionItem, PurchaseOrderFormData } from "../Supplier/types/types"

import type { Company, FreightTerms, PricingPackage, ShipStatus } from "@/graphql/codegen/graphql"

export interface PurchaseOrderDialogProps {
  open: boolean
  fetching: boolean
  company: Company
  products: ProductSelectionItem[]
  onClose: () => void
  onSubmit: (data: PurchaseOrderFormData, selectedProducts: ProductSelectionItem[]) => void
}

export const PurchaseOrderDialogSchema = z.object({
  shipTo: z.string().min(1, { message: "This field is required" }),
})

export type PurchaseOrderDialogFormValues = z.infer<typeof PurchaseOrderDialogSchema>

export enum PurchaseOrderSection {
  HEADER = "HEADER",
  DELIVERY_TERMS = "DELIVERY_TERMS",
  SHIPPING_LEGS = "SHIPPING_LEGS",
  NOTES = "NOTES",
  COMMENTS = "COMMENTS",
  ORDER_LINES = "ORDER_LINES",
  SUGGESTED_PRODUCTS = "SUGGESTED_PRODUCTS",
  ORDER_LINES_DETAILS = "ORDER_LINES_DETAILS",
}

export interface SelectOption<T = string> {
  label: string
  value: T
}

export interface DeliveryTermsFormValues {
  deliveryLocationId?: string
  deliveryTerms?: string
  freightTerms?: FreightTerms | ""
  shipViaCompanyId?: string
  shipStatus?: ShipStatus | ""
}

export enum PurchaseOrderAction {
  SAVE_DRAFT = "SAVE_DRAFT",
  SAVE_AND_EXIT = "SAVE_AND_EXIT",
  CANCEL = "CANCEL",
  SEND = "SEND",
}

export interface ClassifiedPricingPackages {
  currentPricing: PricingPackage | undefined
  futurePricing: PricingPackage[]
}
