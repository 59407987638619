import { Box, CircularProgress, Modal, Typography } from "@mui/material"
import React, { useCallback, useRef, useState } from "react"

import { FuturePricingPackage } from "./FuturePricingPackage"
import { PricingAndShippingTerms } from "./PricingAndShippingTerms"

import { SuccessTemplate } from "@/components/common/Modals/SuccessTemplate"
import { InfoTable } from "@/components/InfoTable/InfoTable"
import { PricingPackageSortField, SortingOrder } from "@/graphql/codegen/graphql"
import { useGetPricingPackage } from "@/screens/Products/hooks/useGetPricingPackage"

interface ExpandedContentProps {
  productId: number
  companyId?: string
  sourceId: string
}

export const ExpandedContent: React.FC<ExpandedContentProps> = ({ productId, companyId = "", sourceId }) => {
  const [successModal, setShowSuccessModal] = useState(false)
  const [openFutureModal, setOpenFutureModal] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  // If sourceId is not present, it means that the pricing package is not available
  const noPricingPackage = !sourceId
  // Passing all mandatory query params along with effectiveNow:true to fetch current pricing packages
  const { pricingList, pricingListFetching, reexecuteQueryWithReset } = useGetPricingPackage({
    filterSpecs: {
      companyId,
      productId,
      sourceCompanyLocationAssociationId: sourceId,
    },
    sortSpecs: {
      field: PricingPackageSortField.EffectiveDate,
      order: SortingOrder.Asc,
    },
  })

  const { currentPricing, futurePricing } = pricingList

  const handleCancel = useCallback(() => {
    setShowSuccessModal(false)
  }, [setShowSuccessModal])

  const handleConfirmCancel = useCallback(() => {
    setShowSuccessModal(false)
    setOpenFutureModal(true)
  }, [setShowSuccessModal, setOpenFutureModal])

  const handleFutureSubmit = useCallback((showAddMoreModal: boolean) => {
    setShowSuccessModal(showAddMoreModal)
  }, [])

  if (pricingListFetching) {
    return (
      <Box className='flex size-full items-center justify-center'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box ref={contentRef}>
      <Box className='w-full items-center border-b border-gray-200 p-4 pr-6 last:border-0'>
        {noPricingPackage ? (
          <InfoTable
            title='Current Pricing'
            chipData={[
              { label: "Effective", value: "-" },
              { label: "Valid Until", value: "-" },
            ]}
            emptyRowMessage='Price to obtain'
            extraInfo={[
              { label: "Freight", value: "-" },
              { label: "Incoterms", value: "-" },
            ]}
          />
        ) : (
          <>
            <PricingAndShippingTerms
              companyId={companyId}
              productId={productId}
              isEmptyFuturePricing={!currentPricing && futurePricing.length === 0}
              currentPricing={currentPricing}
              reexecuteQueryWithReset={reexecuteQueryWithReset}
            />
            <FuturePricingPackage
              companyId={companyId}
              productId={productId}
              pricingList={futurePricing}
              isEmptyFuturePricing={!currentPricing && futurePricing.length === 0}
              reexecuteQueryWithReset={reexecuteQueryWithReset}
              onSubmit={handleFutureSubmit}
              openFutureModal={openFutureModal}
              onFuturePricingSubmit={setOpenFutureModal}
            />
          </>
        )}
        <Modal open={successModal} onClose={handleCancel}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              boxShadow: 24,
              width: 576,
              borderRadius: 2,
            }}
          >
            <SuccessTemplate
              title='Future Pricing Successfully Added'
              message={
                <>
                  You're product future price has successfully been added.
                  <Typography variant='body2' className='text-base font-bold'>
                    Would you like to additional Future Pricing now?
                  </Typography>
                </>
              }
              goBackText='Skip & add later'
              submitText='Add Additional Future Pricing'
              onClose={setShowSuccessModal}
              confirmCancel={handleCancel}
              onConfirm={handleConfirmCancel}
            />
          </Box>
        </Modal>
      </Box>
    </Box>
  )
}
