import { Box, FormControl, FormControlLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material"
import {
  AddOutlined,
  CheckCircleFilledRounded,
  HelpOutlined,
  KeyboardArrowDownOutlined,
} from "@mui-symbols-material/w300"
import { useParams } from "@tanstack/react-router"
import type { FC } from "react"
import React, { useMemo } from "react"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"

import { useGetContactGeneralInformation } from "../../hooks/useGetContactCompany"

import { Button } from "@/components/common/Button"
import { PhoneNumberInput } from "@/components/common/Phone/PhoneInput"
import { type Company, type ContactCreateInput, ContactType, type Location } from "@/graphql/codegen/graphql"
import { formatLocationAddress } from "@/utils/utils"
const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:outline-none h-6 px-4 py-2"

// Wrapper for PhoneNumberInput
const PhoneNumberInputWrapper = React.forwardRef((props: any, ref) => (
  <PhoneNumberInput
    {...props}
    ref={ref}
    inputProps={{
      classes: {
        input: "border-r-none",
      },
    }}
  />
))

export const AddContactMethods: FC = () => {
  const { control, getValues } = useFormContext<ContactCreateInput>()
  const { fields, append, update } = useFieldArray<ContactCreateInput>({
    control,
    name: "phoneNumber",
  })

  const formData = useMemo(() => getValues(), [getValues])

  const { companyId: queryCompanyId } = useParams({ from: "/companies/$companyId/edit" })
  const {
    company,
    location,
    fetching: contactGeneralInformationFetching,
  } = useGetContactGeneralInformation({
    companyId: queryCompanyId,
    locationId: formData.physicalLocationId ?? undefined,
  })

  const handleAddPhone = () => {
    append({ number: "", type: "Text", isPreferred: false })
  }

  const handlePreferredChange = (index: number) => {
    fields.forEach((_, idx) => {
      const currentField = getValues(`phoneNumber.${idx}` as const)
      update(idx, { ...currentField, isPreferred: idx === index })
    })
  }

  return (
    <form className='space-y-6'>
      <GeneralInformation
        formData={formData}
        company={company}
        location={location}
        loading={contactGeneralInformationFetching}
      />
      <Typography variant='body1' fontWeight={700} className='leading-6 tracking-[0.15px] text-gray-700'>
        2. Contact methods
      </Typography>
      <Box className='space-y-10'>
        {fields.map((item, index) => (
          <Controller
            key={item.id}
            name={`phoneNumber.${index}.number` as keyof ContactCreateInput}
            control={control}
            render={({ field }) => (
              <Box className='relative'>
                <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Phone</label>
                <TextField
                  {...field}
                  placeholder='+1 (123) 456-7890'
                  fullWidth
                  variant='outlined'
                  InputProps={{
                    inputComponent: PhoneNumberInputWrapper, // Use the wrapper here
                    endAdornment: (
                      <Box className='flex items-center gap-4'>
                        <HelpOutlined />
                        <Controller
                          name={`phoneNumber.${index}.type` as keyof ContactCreateInput}
                          control={control}
                          render={({ field }) => (
                            <FormControl variant='outlined' size='small'>
                              <Select
                                sx={{
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                  minWidth: "60px",
                                  "& .MuiSelect-select": {
                                    padding: "2px 4px",
                                  },
                                }}
                                {...field}
                                displayEmpty
                                IconComponent={KeyboardArrowDownOutlined}
                                MenuProps={{
                                  PaperProps: {
                                    style: { maxHeight: 48 * 4.5 + 8, width: 60 },
                                  },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                {Object.keys(ContactType).map((type) => (
                                  <MenuItem key={type} value={type}>
                                    {type}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Box>
                    ),
                    classes: {
                      input: InputFieldClasses,
                    },
                  }}
                />
                <Box className='mt-4 flex justify-between'>
                  <Controller
                    name={`phoneNumber.${index}.isPreferred` as const}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch {...field} checked={item.isPreferred} onChange={() => handlePreferredChange(index)} />
                        }
                        label='Preferred'
                      />
                    )}
                  />
                  {index === fields.length - 1 && (
                    <Button variant='primary' appearance='text' startIcon={<AddOutlined />} onClick={handleAddPhone}>
                      Phone
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          />
        ))}
        <EmailField control={control} />
      </Box>
    </form>
  )
}

const GeneralInfoText: FC<{ children?: React.ReactNode }> = ({ children }) => (
  <p className='py-0 text-sm text-gray-900'>{children ?? <>&nbsp;</>}</p>
)

interface GeneralInformationProps {
  formData: ContactCreateInput
  company?: Company
  location?: Location
  loading?: boolean
}

const GeneralInformation: FC<GeneralInformationProps> = ({ formData, company, location, loading }) => {
  return (
    <Box className='space-y-4'>
      <Box className='mb-2' display='flex' alignItems='center'>
        <Typography
          variant='body1'
          fontWeight={700}
          className='leading-6 tracking-[0.15px] text-gray-700'
          display='flex'
          alignItems='center'
        >
          1. General Information
          <CheckCircleFilledRounded className='ml-1 text-success-500' />
        </Typography>
      </Box>
      <Box className='px-10'>
        {loading ? (
          <>
            <GeneralInfoText>
              <em>Loading...</em>
            </GeneralInfoText>
            {/* DOM placeholders to prevent "jumping" when transitioning between loading states */}
            <GeneralInfoText />
            <GeneralInfoText />
            <GeneralInfoText />
            <GeneralInfoText />
            <GeneralInfoText />
          </>
        ) : (
          <>
            <GeneralInfoText>First Name: {formData.firstName}</GeneralInfoText>
            <GeneralInfoText>Last Name: {formData.lastName}</GeneralInfoText>
            <GeneralInfoText>Company: {company?.name ?? "-"}</GeneralInfoText>
            {formData.companyDepartment && (
              <GeneralInfoText>Company Department: {formData.companyDepartment}</GeneralInfoText>
            )}
            {formData.title && <GeneralInfoText>Title: {formData.title}</GeneralInfoText>}
            {location && (
              <GeneralInfoText>Office Location: {location ? formatLocationAddress(location) : "-"}</GeneralInfoText>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

const EmailField: FC<{ control: any }> = ({ control }) => (
  <Controller
    name='email'
    control={control}
    render={({ field }) => (
      <Box className='relative'>
        <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Email</label>
        <TextField
          {...field}
          InputProps={{
            endAdornment: <HelpOutlined />,
            classes: {
              input: InputFieldClasses,
            },
          }}
          placeholder='name@domain.com'
          fullWidth
          variant='outlined'
        />
      </Box>
    )}
  />
)

export default AddContactMethods
