import { useAuthStore } from "@/stores/authStore"
import { validateAndSendIdToken } from "@/utils/auth/auth"
import { exchangeTokenWithAPI, refreshTokenWithAPI } from "@/utils/auth/exchangeToken"
import { isTokenExpired } from "@/utils/auth/isTokenExpired"

export const checkAuthStatus = async (): Promise<boolean> => {
  const store = useAuthStore.getState()
  const { idToken, accessToken, refreshToken } = store.tokens

  if (!idToken) {
    store.clearAuth()
    return false
  }

  if (!accessToken || isTokenExpired(accessToken)) {
    if (!refreshToken) {
      try {
        const tokens = await exchangeTokenWithAPI(idToken)
        store.setTokens(tokens)
        return true
      } catch {
        store.clearAuth()
        return false
      }
    }

    try {
      const tokens = await refreshTokenWithAPI({
        accessToken: accessToken!,
        refreshToken,
      })
      store.setTokens(tokens)
      return true
    } catch {
      store.clearAuth()
      return false
    }
  }

  return true
}

export const handleCallback = async (idToken: string): Promise<boolean> => {
  const store = useAuthStore.getState()
  try {
    const result = await validateAndSendIdToken(idToken)
    if ("error" in result) {
      throw new Error("Invalid token")
    }
    store.setTokens({ idToken, ...result })
    return true
  } catch {
    store.clearAuth()
    return false
  }
}
