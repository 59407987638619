import { z } from "zod"

const FORMAT_ERROR =
  "CAS number must be in the format XXXXXX-YY-Z, where X is 2–7 digits, Y is 2 digits, and Z is a single check digit"
const CHECK_DIGIT_ERROR = "Invalid CAS number: Check digit does not match the calculated value"

/**
 * Validates CAS Registry Numbers according to the official format and check digit calculation.
 * Format: XXXXXX-YY-Z where:
 * - X is 2-7 digits (first part)
 * - Y is exactly 2 digits (second part)
 * - Z is a single check digit
 */
export const casNumberSchema = z.string().superRefine((value, ctx) => {
  if (!value) return
  // Basic format check first
  if (!/^\d+-\d+-\d$/.test(value)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: FORMAT_ERROR,
    })
    return false
  }

  const [firstPart, secondPart, checkDigit] = value.split("-")

  // Validate part lengths
  if (
    !firstPart ||
    !secondPart ||
    !checkDigit ||
    firstPart.length < 2 ||
    firstPart.length > 7 ||
    secondPart.length !== 2
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: FORMAT_ERROR,
    })
    return false
  }

  // Only proceed to check digit validation if format is correct
  const combined = `${firstPart}${secondPart}`
  const checkSum = combined
    .split("")
    .reverse()
    .reduce((sum, digit, index) => sum + parseInt(digit) * (index + 1), 0)

  if (checkSum % 10 !== parseInt(checkDigit)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: CHECK_DIGIT_ERROR,
    })
    return false
  }

  return true
})

/**
 * Calculates the check digit for a CAS number
 * @param firstPart - First part of CAS number (2-7 digits)
 * @param secondPart - Second part of CAS number (2 digits)
 * @returns The calculated check digit (0-9)
 */
export const calculateCasCheckDigit = (firstPart: string, secondPart: string): number => {
  const combined = `${firstPart}${secondPart}`
  const checkSum = combined
    .split("")
    .reverse()
    .reduce((sum, digit, index) => sum + parseInt(digit) * (index + 1), 0)

  return checkSum % 10
}

/**
 * Validates a complete CAS number
 * @param casNumber - Full CAS number in format XXXXXX-YY-Z
 * @returns Result object indicating if the CAS number is valid and any error message
 */
export const validateCasNumber = (casNumber: string): { isValid: boolean; error?: string } => {
  const result = casNumberSchema.safeParse(casNumber)

  if (!result.success) {
    return {
      isValid: false,
      error: result.error.issues[0].message,
    }
  }

  return { isValid: true }
}
