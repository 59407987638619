import { Typography } from "@mui/material"
import type { ColumnDef } from "@tanstack/react-table"
import dayjs from "dayjs"

import { OrderStatusChip } from "../../PurchaseOrder/components/OrderStatusChip"
import { ShipStatusFlatChip } from "../../PurchaseOrder/components/ShipStatusFlatChip"
import { PurchaseOrderActions } from "../components/PurchaseOrderActions"

import StyledChip from "@/components/common/Chip/Chip"
import type { Order, OrderStatus, ShipStatus } from "@/graphql/codegen/graphql"
import { formatNumber } from "@/utils/formatNumber"
import { getTotalSummaryPurchaseOrder } from "@/utils/getTotalSummaryPurchaseOrder"

export const getTableColumns = (): ColumnDef<Order>[] => [
  {
    header: "Supplier Name",
    accessorKey: "companyName",
    accessorFn: (row) => row.sourceOrderLocationDetail?.location?.name,
    cell: ({ row }) => (
      <Typography component='span'>
        {row.original.sourceLocationAssociationDetail?.companyLocationAssociation?.companyDetail?.company?.name || "-"}
      </Typography>
    ),
  },
  {
    header: "PO No.",
    accessorKey: "orderId",
  },
  {
    header: "Status",
    accessorKey: "status",
    cell: ({ row }) => <OrderStatusChip status={row.original.status as OrderStatus} />,
  },
  {
    header: "Ship Status",
    accessorKey: "shipStatus",
    cell: ({ row }) => <ShipStatusFlatChip shipStatus={row.original.shipStatus as ShipStatus} />,
  },
  {
    header: "Requested",
    accessorKey: "requestedDeliveryDate",
    cell: ({ getValue }) => {
      const date = getValue<string>()
      return (
        <StyledChip
          label={
            <span>
              {date && <strong>By</strong>} {date ? dayjs(date).format("MM/DD/YYYY") : "--/--/----"}
            </span>
          }
          className='text-tertiary-base'
          color='primary'
        />
      )
    },
  },
  {
    header: "Expected",
    accessorKey: "expectedDeliveryDate",
    cell: ({ getValue }) => {
      const date = getValue<string>()
      return (
        <StyledChip
          label={
            <span>
              {date && <strong>By</strong>} {date ? dayjs(date).format("MM/DD/YYYY") : "--/--/----"}
            </span>
          }
          className='text-tertiary-base'
          color='primary'
        />
      )
    },
  },
  {
    header: "Order Total",
    accessorFn: (row: Order) => {
      const { totalAmount } = getTotalSummaryPurchaseOrder(row?.orderLines || [])
      return formatNumber(totalAmount)
    },
    enableSorting: false,
  },
  {
    id: "expand",
    header: "",
    cell: ({ row }) => <PurchaseOrderActions row={row} />,
    enableSorting: false,
  },
]
