import { useMemo, useState } from "react"

import type { ProductSortField } from "@/graphql/codegen/graphql"
import { OrderSortField, SortingOrder } from "@/graphql/codegen/graphql"
import type {
  SortableProductPurchaseOrderAccessorKey,
  SortablePurchaseOrderAccessorKey,
} from "@/screens/Procurement/PurchaseOrders/types"
import {
  orderSortFieldToAccessoryKey,
  productOrderSortFieldToAccessoryKey,
} from "@/screens/Procurement/PurchaseOrders/types"

type UsePurchaseOrderTableProps = {
  sortBy?: OrderSortField | ProductSortField
  sortOrder?: SortingOrder
}

type UsePurchaseOrderTableReturn = {
  sorting: { id: SortablePurchaseOrderAccessorKey | SortableProductPurchaseOrderAccessorKey; desc: boolean }[]
  expanded: Record<string, boolean>
  selected: Record<string, boolean>
  setExpanded: (expanded: Record<string, boolean>) => void
  setSelected: (selected: Record<string, boolean>) => void
}

export const usePurchaseOrderTable = ({
  sortBy = OrderSortField.UpdatedOn,
  sortOrder = SortingOrder.Asc,
}: UsePurchaseOrderTableProps): UsePurchaseOrderTableReturn => {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({})
  const [selected, setSelected] = useState<Record<string, boolean>>({})

  const sorting = useMemo(() => {
    const tableSortField = (orderSortFieldToAccessoryKey.get(sortBy as OrderSortField) ||
      productOrderSortFieldToAccessoryKey.get(sortBy as ProductSortField)) as
      | SortablePurchaseOrderAccessorKey
      | SortableProductPurchaseOrderAccessorKey

    return [{ id: tableSortField, desc: sortOrder === SortingOrder.Desc }]
  }, [sortBy, sortOrder])

  return {
    sorting,
    expanded,
    selected,
    setExpanded,
    setSelected,
  }
}
