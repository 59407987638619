import type { Client, CombinedError } from "urql"
import { create } from "zustand"

import { SUPPLIER_QUERY_TYPES } from "../utils/supplierReportConstants"

import {
  type Company,
  type CompanySortField,
  type Location,
  type SortingOrder,
  SupplierListDocument,
} from "@/graphql/codegen/graphql.ts"

type CompanySearchFilters = {
  query?: string
  category?: string
  field?: CompanySortField
  order?: SortingOrder
  [key: string]: unknown
}

type CompanyWithLocation = Company & { currentLocation?: Location }

export type SupplierSearchState = {
  data: CompanyWithLocation[]
  fetching: boolean
  error: CombinedError | Error | undefined
  offset: number
  limit: number
  hasMore: boolean
  lastExecutedFilters: CompanySearchFilters | undefined
  setFetching: (fetching: boolean) => void
  setError: (error: CombinedError | Error | undefined) => void
  resetSearch: () => void
  setData: (data: Company[]) => void
  appendData: (data: Company[]) => void
  executeSearch: (client: Client, filters: CompanySearchFilters) => Promise<void>
  loadMore: (client: Client, filters: CompanySearchFilters) => Promise<void>
}

// O(n) time complexity where n is number of companies
// O(n) space complexity for storing unique keys
const deduplicateCompanies = (companies: CompanyWithLocation[]): CompanyWithLocation[] => {
  // Using object literal for faster key lookups compared to Map
  const uniqueCompanies: Record<string, CompanyWithLocation> = {}

  // Single pass through array with direct object property access
  for (let i = 0; i < companies.length; i++) {
    const company = companies[i]
    const key = company.companyId + "-" + (company.currentLocation?.locationId || "default")
    uniqueCompanies[key] = company
  }

  return Object.values(uniqueCompanies)
}

const processCompanyData = (companies: Company[]): CompanyWithLocation[] => {
  return companies.flatMap((company) => {
    if (company.locationsAssociations && company.locationsAssociations.length > 0) {
      return company.locationsAssociations.map((location) => ({
        ...company,
        currentLocation: location,
      }))
    }
    return [company]
  })
}

const INITIAL_STATE = {
  data: [],
  fetching: false,
  error: undefined,
  offset: 0,
  limit: 20,
  hasMore: true,
  lastExecutedFilters: undefined,
}

export const useSupplierReportSearchStore = create<SupplierSearchState>((set, get) => ({
  ...INITIAL_STATE,

  setFetching: (fetching) => set({ fetching }),

  setError: (error) => set({ error }),

  resetSearch: () => set({ ...INITIAL_STATE }),

  setData: (data) =>
    set(() => ({
      data: deduplicateCompanies(processCompanyData(data)), // Added deduplication here
      error: undefined,
    })),

  appendData: (data) =>
    set((state) => ({
      data: deduplicateCompanies([...state.data, ...processCompanyData(data)]),
      error: undefined,
    })),

  executeSearch: async (client: Client, filters: CompanySearchFilters) => {
    const state = get()
    if (state.fetching) return

    const { query = "", ...restFilters } = filters || {}
    const validQuery = typeof query === "string" && query.length >= 3 ? query : ""
    const currentFilters = { ...restFilters, query: validQuery }

    // Removed the filter comparison to allow scrolling search without filter change
    const shouldExecuteSearch = validQuery !== undefined || Object.keys(restFilters).length > 0
    if (!shouldExecuteSearch) {
      set({ data: [], hasMore: false, lastExecutedFilters: undefined })
      return
    }

    set({ fetching: true })

    try {
      const { category, field, order, ...otherFilters } = currentFilters
      const queryInput = {
        filter: { ...otherFilters, types: SUPPLIER_QUERY_TYPES },
        offset: 0,
        limit: state.limit,
        sort: { field, order },
      }

      const result = await client.query(SupplierListDocument, { input: queryInput }).toPromise()

      if (result.data?.company?.list.__typename === "CompanyListSuccess") {
        const companies = result.data.company.list.companies as Company[]
        const processedData = deduplicateCompanies(processCompanyData(companies)) // Deduplicate initial data
        set({
          data: processedData,
          offset: 0,
          hasMore: companies.length === state.limit,
          lastExecutedFilters: currentFilters,
        })
      }

      if (result.error) set({ error: result.error })
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },

  loadMore: async (client: Client, filters: CompanySearchFilters) => {
    const state = get()
    if (state.fetching || !state.hasMore) return

    const { query = "", ...restFilters } = filters || {}
    const validQuery = typeof query === "string" && query.length >= 3 ? query : undefined
    const currentFilters = { ...restFilters, ...(validQuery ? { query: validQuery } : {}) }

    // Removed the filter comparison to allow scrolling search without filter change
    const newOffset = state.offset + state.limit
    set({ fetching: true, offset: newOffset })

    try {
      const { category, field, order, ...otherFilters } = currentFilters
      const queryInput = {
        filter: { ...otherFilters, types: SUPPLIER_QUERY_TYPES },
        offset: newOffset,
        limit: state.limit,
        sort: { field, order },
      }

      const result = await client.query(SupplierListDocument, { input: queryInput }).toPromise()

      if (result.data?.company?.list.__typename === "CompanyListSuccess") {
        const companies = result.data.company.list.companies as Company[]
        const processedData = deduplicateCompanies([...state.data, ...processCompanyData(companies)]) // Deduplicate combined data
        set({
          data: processedData,
          offset: newOffset,
          hasMore: companies.length === state.limit,
          lastExecutedFilters: currentFilters,
        })
      }

      if (result.error) set({ error: result.error })
    } catch (error) {
      set({ error: error as CombinedError | Error })
    } finally {
      set({ fetching: false })
    }
  },
}))
