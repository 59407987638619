import { Box, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { KeyboardArrowDownOutlined } from "@mui-symbols-material/w300"
import { type FC, useRef } from "react"
import { Controller, useFormContext } from "react-hook-form"

import ProductShipLocationSelect from "./ProductShipLocationSelect"

import type { ProductCreateInput } from "@/graphql/codegen/graphql"
import { useAccountManagers } from "@/hooks/useAccountManagers"
import { useScrollLoadMore } from "@/hooks/useScrollLoadMore.ts"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuStyleProps = {
  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  width: 250,
}

const InputFieldClasses = "p-0 border-0 ring-0 outline-0 focus:border-0 focus:ring-0 focus:outline-none h-6 px-4 py-2"

export const AddProductInformation: FC = () => {
  const { control } = useFormContext<ProductCreateInput>()
  const { visibleManagers, fetching, loadMore } = useAccountManagers()
  const productManagerRef = useRef<HTMLDivElement>(null)

  const onScroll = useScrollLoadMore({
    fetching,
    loadMore,
    scrollContainerRef: productManagerRef,
  })

  return (
    <form>
      <Typography variant='body1' fontWeight={700} className='leading-6 tracking-[0.15px] text-gray-700'>
        1. Product information
      </Typography>
      <Box className='space-y-10'>
        <Controller
          name='vendorSku'
          control={control}
          render={({ field }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>Vendor Product SKU</label>
              <TextField
                {...field}
                InputProps={{
                  classes: {
                    input: InputFieldClasses,
                  },
                }}
                placeholder='Add SKU'
                fullWidth
                variant='outlined'
              />
            </Box>
          )}
        />
        <Controller
          name='vendorDescription'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>
                Vendor Description<span className='text-red-600'>*</span>
              </label>
              <TextField
                {...field}
                InputProps={{
                  classes: {
                    input: InputFieldClasses,
                  },
                }}
                error={!!error}
                placeholder='Add Vendor Product Description'
                fullWidth
                variant='outlined'
              />
              {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
            </Box>
          )}
        />
        <Controller
          name='companyProductDescription'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>
                Akrochem Description<span className='text-red-600'>*</span>
              </label>
              <TextField
                {...field}
                InputProps={{
                  classes: {
                    input: InputFieldClasses,
                  },
                }}
                error={!!error}
                fullWidth
                placeholder='Add Akrochem Product Description'
                variant='outlined'
              />
              {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
            </Box>
          )}
        />
        <Controller
          name='productManager'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box className='relative'>
              <label className='-top-6 mb-[7px] mt-6 block text-sm font-thin text-gray-700'>
                Product Manager<span className='text-red-600'>*</span>
              </label>
              <Select
                {...field}
                displayEmpty
                input={<OutlinedInput />}
                SelectDisplayProps={{
                  className:
                    "content-center h-6 px-4 py-2 focus:border-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 text-sm leading-5",
                }}
                classes={{
                  icon: "text-gray-600",
                }}
                placeholder='Add Vendor Product Manager'
                IconComponent={KeyboardArrowDownOutlined}
                onScrollCapture={onScroll}
                MenuProps={{
                  PaperProps: {
                    ref: productManagerRef,
                    style: MenuStyleProps,
                  },
                }}
                inputProps={{ "aria-label": "Without label" }}
                variant='outlined'
                fullWidth
                onChange={(event) => {
                  const { firstName, lastName, accountManagerId } =
                    visibleManagers.find(({ accountManagerId }) => accountManagerId === event.target.value) ?? {}
                  field.onChange({ id: accountManagerId, name: `${firstName} ${lastName}` })
                }}
                value={field.value?.id}
              >
                {visibleManagers.map(({ accountManagerId, firstName, lastName }) => (
                  <MenuItem key={accountManagerId + firstName} value={accountManagerId}>
                    {`${firstName} ${lastName}`}
                  </MenuItem>
                ))}
              </Select>
              {error?.message && <span className='text-sm text-red-600'>{error?.message}</span>}
            </Box>
          )}
        />
        <Controller
          name='locationsAssociations'
          control={control}
          render={() => <ProductShipLocationSelect name='locationsAssociations' control={control} />}
        />
      </Box>
    </form>
  )
}
