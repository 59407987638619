import { useCallback, useState } from "react"
import { useMutation } from "urql"

import { CreateLocationMutationDocument, UpdateLocationMutationDocument } from "@/graphql/codegen/graphql"
import type {
  CreateLocationMutationMutation,
  CreateLocationMutationMutationVariables,
  Location,
  UpdateLocationMutationMutation,
  UpdateLocationMutationMutationVariables,
} from "@/graphql/codegen/graphql"
import type { LocationMenuDialogs } from "@/screens/Companies/edit/components/locations/types/dialogs"
import { LocationConfirmationDialogs } from "@/screens/Companies/edit/components/locations/types/dialogs"
import type {
  AddAddressQueryProps,
  EditAddressQueryProps,
  EditContactQueryProps,
  EditNotesQueryProps,
} from "@/screens/Companies/types/dialogProps"
import { useNotificationsStore } from "@/stores/useNotificationsStore"

export const useLocationTableActions = ({
  companyId,
  reexecuteQueryWithReset,
}: {
  companyId: string
  reexecuteQueryWithReset: () => void
}): {
  openedDialog: LocationMenuDialogs | LocationConfirmationDialogs | null
  setOpenedDialog: (dialog: LocationMenuDialogs | LocationConfirmationDialogs | null) => void
  prevOpenedDialog: LocationMenuDialogs | LocationConfirmationDialogs | null
  activeEditLocation: Location | undefined
  setActiveEditLocation: (location: Location) => void
  handleOnUpdateSubmit: (data: EditContactQueryProps | EditAddressQueryProps | EditNotesQueryProps) => Promise<void>
  handleOnCreateSubmit: (data: AddAddressQueryProps) => Promise<void>
} => {
  const [openedDialog, setOpenedDialog] = useState<LocationMenuDialogs | LocationConfirmationDialogs | null>(null)
  const [prevOpenedDialog, setPrevOpenedDialog] = useState<LocationMenuDialogs | LocationConfirmationDialogs | null>(
    null
  )
  const [activeEditLocation, setActiveEditLocation] = useState<Location | undefined>()
  const triggerNotification = useNotificationsStore((store) => store.enqueueNotification)

  const [, createLocation] = useMutation<CreateLocationMutationMutation, CreateLocationMutationMutationVariables>(
    CreateLocationMutationDocument
  )

  const [, updateLocation] = useMutation<UpdateLocationMutationMutation, UpdateLocationMutationMutationVariables>(
    UpdateLocationMutationDocument
  )

  const handleSetOpenedDialog = useCallback(
    (dialog: LocationMenuDialogs | LocationConfirmationDialogs | null) => {
      setPrevOpenedDialog(openedDialog)
      setOpenedDialog(dialog)
    },
    [openedDialog]
  )

  const handleOnUpdateSubmit = useCallback(
    async (data: EditContactQueryProps | EditAddressQueryProps | EditNotesQueryProps) => {
      const { __typename, ...activeLocationWithoutTypename } = activeEditLocation as Location
      const res = await updateLocation({
        input: {
          companyId,
          locationId: activeLocationWithoutTypename.locationId,
          ...data,
        },
      })
      if (res.error || res.data?.location.update.__typename === "LocationUpdateFailure") {
        triggerNotification({
          message: "Failed to update location",
          type: "error",
        })
        return
      }

      triggerNotification({
        message: "Location updated successfully",
        type: "success",
      })

      reexecuteQueryWithReset()
      setOpenedDialog(null)
    },
    [updateLocation, companyId, activeEditLocation, handleSetOpenedDialog]
  )

  const handleOnCreateSubmit = useCallback(
    async (data: AddAddressQueryProps) => {
      const res = await createLocation({ input: { ...data, isActive: true, companyId } })

      if (res.error || res.data?.location.create.__typename === "LocationCreateFailure") {
        triggerNotification({
          message: "Failed to create location",
          type: "error",
        })
        return
      }
      reexecuteQueryWithReset()
      setOpenedDialog(LocationConfirmationDialogs.SUCCESS)
    },
    [createLocation, companyId, reexecuteQueryWithReset, handleSetOpenedDialog]
  )

  return {
    openedDialog,
    setOpenedDialog: handleSetOpenedDialog,
    prevOpenedDialog,
    activeEditLocation,
    setActiveEditLocation,
    handleOnUpdateSubmit,
    handleOnCreateSubmit,
  }
}
