import { Box, LinearProgress, Typography } from "@mui/material"
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from "@mui-symbols-material/w300"
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import type { ReactElement, ReactNode } from "react"
import React, { Fragment, useCallback } from "react"
import { twMerge } from "tailwind-merge"

import type { AerosTableProps } from "../types/table"

import { AerosDataNotFoundRow } from "./AerosDataNotFoundRow"
import { AerosTable } from "./AerosTable"
import { AerosTableBody } from "./AerosTableBody"
import { AerosTableCell } from "./AerosTableCell"
import { AerosTableContainer } from "./AerosTableContainer"
import { AerosTableHead } from "./AerosTableHead"
import { AerosTableHeadRow } from "./AerosTableHeadRow"
import { AerosTableRow } from "./AerosTableRow"

export const AerosBaseTable = <TData,>({
  error,
  features,
  slotProps,
  noDataMessage = "",
}: AerosTableProps<TData>): ReactElement => {
  const {
    data = [],
    columns = [],
    state,
    getCoreRowModel: customCoreRowModel,
    ...tanstackOptions
  } = features?.tanstackOptions || {}

  const table = useReactTable<TData>({
    data,
    columns,
    state,
    getCoreRowModel: customCoreRowModel ?? getCoreRowModel<TData>(),
    ...tanstackOptions,
  })

  // Added callback function to check if the content is empty or not
  const renderRowDetail = useCallback((content: ReactNode) => {
    // Checking if the content returns empty react element or actual content
    if (!content) return null
    return (
      <AerosTableRow
        className={twMerge("!border-t-0 border-b-0", "hover:bg-transparent", slotProps?.bodyRow?.className)}
      >
        <AerosTableCell
          colSpan={columns.length}
          className={twMerge("!border-t-0", "p-0", slotProps?.rowDetailCell?.className)}
        >
          <Box className='px-4 py-2'>{content}</Box>
        </AerosTableCell>
      </AerosTableRow>
    )
  }, [])

  const handleRowClick = useCallback(
    (e: React.MouseEvent, row: any) => {
      // If the click originated from a clickable element within a cell or overlay, don't trigger row click
      if (
        (e.target as HTMLElement).closest('[data-clickable="true"]') ||
        (e.target as HTMLElement).closest(".MuiBackdrop-root")
      ) {
        return
      }
      slotProps?.bodyRow?.onClick?.(row)
    },
    [slotProps?.bodyRow]
  )

  return (
    <AerosTableContainer {...slotProps?.container}>
      {features?.tanstackOptions?.state?.isLoading ? (
        <LinearProgress className='sticky top-0 z-10' />
      ) : (
        <Box className='invisible -mt-1 h-1' />
      )}
      <AerosTable stickyHeader aria-label='sticky table'>
        <AerosTableHead>
          <AerosTableHeadRow {...slotProps?.headerRow}>
            {table.getFlatHeaders().map((header) => (
              <AerosTableCell
                key={header.id}
                align={header.id === "select" ? "center" : (slotProps?.headerCell?.align ?? "left")}
                sx={{
                  width: header.getSize(),
                  minWidth: header.getSize(),
                  maxWidth: header.getSize(),
                }}
                className={twMerge(
                  "bg-gray-100 text-gray-700",
                  slotProps?.headerCell?.className,
                  header.column.getCanSort() ? "cursor-pointer select-none" : ""
                )}
                onClick={header.column.getToggleSortingHandler()}
                {...slotProps?.headerCell}
              >
                <Box className='flex items-center'>
                  {header.column.getCanSort() && (
                    <Box className='mr-2'>
                      {header.column.getIsSorted() === "asc" ? (
                        <ArrowUpwardOutlined fontSize='small' />
                      ) : header.column.getIsSorted() === "desc" ? (
                        <ArrowDownwardOutlined fontSize='small' />
                      ) : (
                        <span className='size-4' /> // Placeholder for unsorted state
                      )}
                    </Box>
                  )}
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </Box>
              </AerosTableCell>
            ))}
          </AerosTableHeadRow>
        </AerosTableHead>
        <AerosTableBody>
          {table.getRowCount() === 0 && noDataMessage && (
            <AerosDataNotFoundRow colSpan={columns.length}>{noDataMessage}</AerosDataNotFoundRow>
          )}
          {table.getRowCount() > 0 &&
            table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <AerosTableRow
                  {...slotProps?.bodyRow}
                  onClick={(e) => handleRowClick(e, row)}
                  selected={features?.tanstackOptions?.state?.rowSelection && row.getIsSelected()}
                  sx={{ cursor: features?.tanstackOptions?.state?.rowSelection ? "pointer" : "default" }}
                  className={twMerge(
                    !features?.tanstackOptions?.renderRowDetail && "!border-b-0",
                    slotProps?.bodyRow?.className
                  )}
                >
                  {row.getVisibleCells().map((cell) => {
                    const align = cell.column.id === "select" ? "center" : (slotProps?.bodyCell?.align ?? "left")
                    const onClick = cell.column.id === "select" ? row.getToggleSelectedHandler() : undefined
                    const sizeStyles = {
                      width: cell.column.getSize(),
                      minWidth: cell.column.getSize(),
                      maxWidth: cell.column.getSize(),
                    }
                    const className = twMerge(
                      slotProps?.bodyCell?.className,
                      row.getIsExpanded() ? slotProps?.bodyCellExpanded?.className : "",
                      features?.tanstackOptions?.renderRowDetail && "!border-b-0"
                    )

                    const isClickable = cell.column.id === "expand" || cell.column.id === "select" || !!onClick

                    return (
                      <AerosTableCell
                        key={cell.id}
                        align={align}
                        onClick={onClick}
                        data-clickable={isClickable}
                        sx={sizeStyles}
                        className={className}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </AerosTableCell>
                    )
                  })}
                </AerosTableRow>
                {features?.tanstackOptions?.renderRowDetail &&
                  renderRowDetail(features.tanstackOptions.renderRowDetail({ row }))}
                {features?.tanstackOptions?.renderExpandedContent && row.getIsExpanded() && (
                  <AerosTableRow {...slotProps?.expandedRow}>
                    <AerosTableCell
                      colSpan={columns.length}
                      className='p-0 hover:bg-transparent'
                      {...slotProps?.expandedCell}
                    >
                      {features.tanstackOptions.renderExpandedContent(row)}
                    </AerosTableCell>
                  </AerosTableRow>
                )}
              </Fragment>
            ))}
        </AerosTableBody>
      </AerosTable>
      {error && <Typography color='error'>{error.message}</Typography>}
    </AerosTableContainer>
  )
}
