import type { ColumnDef } from "@tanstack/react-table"
import { useState } from "react"

import { companyProductListColumns } from "../productColumns"

import type { Product } from "@/graphql/codegen/graphql"
import { SortingOrder } from "@/graphql/codegen/graphql"

interface UseProductTableProps {
  sortBy?: string
  sortOrder?: SortingOrder
}

interface UseProductTableReturn {
  columns: ColumnDef<Product>[]
  expanded: Record<string, boolean>
  selected: Record<string, boolean>
  sorting: { id: string; desc: boolean }[]
  setExpanded: (expanded: Record<string, boolean>) => void
  setSelected: (selected: Record<string, boolean>) => void
  setSorting: (sorting: { id: string; desc: boolean }[]) => void
}

export const useProductTable = ({ sortBy, sortOrder }: UseProductTableProps): UseProductTableReturn => {
  const [expanded, setExpanded] = useState({})
  const [selected, setSelected] = useState({})
  const [sorting, setSorting] = useState([
    {
      id: sortBy || "name",
      desc: sortOrder === SortingOrder.Desc,
    },
  ])

  return {
    columns: companyProductListColumns,
    expanded,
    selected,
    sorting,
    setExpanded,
    setSelected,
    setSorting,
  }
}
