import { Box, CircularProgress } from "@mui/material"
import { createFileRoute, redirect } from "@tanstack/react-router"

import { useAuthStore } from "@/stores/authStore"
import theme from "@/styles/mui-theme"
import { getIdTokenFromUrl } from "@/utils/auth/auth"
import { exchangeTokenWithAPI } from "@/utils/auth/exchangeToken"

export const Route = createFileRoute("/auth/callback")({
  beforeLoad: async () => {
    const idToken = getIdTokenFromUrl()
    if (!idToken) {
      useAuthStore.getState().signIn()
      return null
    }

    const store = useAuthStore.getState()
    try {
      store.setStatus("loading")

      // Exchange for access and refresh tokens
      const tokens = await exchangeTokenWithAPI(idToken)

      // Set all tokens at once to prevent multiple state updates
      store.setTokens({
        idToken,
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
      })

      // Set authenticated status
      store.setStatus("authenticated")

      // Get the redirect URL from the state parameter in the URL hash
      const hash = window.location.hash.substring(1)
      const params = new URLSearchParams(hash)
      const redirectUrl = params.get("state") || "/"

      return redirect({ to: redirectUrl, replace: true })
    } catch (error) {
      console.error("Auth callback failed:", error)
      store.clearAuth() // This sets status to unauthenticated
      store.signIn()
      return null
    }
  },
  component: AuthCallback,
})

function AuthCallback() {
  const { status } = useAuthStore()

  if (status === "loading") {
    return (
      <Box className='flex h-screen items-center justify-center'>
        <CircularProgress
          size={48}
          className='transition-opacity duration-300 ease-in-out'
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      </Box>
    )
  }

  return null
}
